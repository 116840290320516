const secureImages = (image_link) => {
    let split_link = image_link.split('/');
    let http = split_link[0];
    let url = split_link[2];
    let total_link = http + '//' + url;
    if (total_link === 'http://image.myplex.com') {
        image_link =  'https://img1.vodafoneplay.in/' + split_link[3] + '/' + split_link[4];
    } else if (total_link === 'http://img1.beta.myplex.in') {
        image_link = 'https://img1.vodafoneplay.in/' + split_link[3] + '/' + split_link[4];
    } else if (total_link === 'http://img2.beta.myplex.in') {
        image_link = 'https://image.vodafoneplay.in/' + split_link[3] + '/' + split_link[4];
    } else if (total_link === 'http://static1.beta.myplex.in') {
        image_link =  'https://static.vodafoneplay.in/' + split_link[3] + '/' + split_link[4];
    }
    else if (total_link === 'http://d25tmfuvd960zw.cloudfront.net') {
        image_link =  'https://d25tmfuvd960zw.cloudfront.net/' + split_link[3] + '/' + split_link[4];
    }
    // console.log(image_link);
    return image_link;

    // eslint-disable-next-line no-unreachable
}

export default secureImages;