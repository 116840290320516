// const Loggedin = 'Logged In';
// const Logout = 'Logged Out';
//const Searchedkeyword = 'Searched Keyword';
//const SearchedTag = 'Searched Tag';

const LoginFn = (data) => {
  window.lens && window.lens.publisher.trackLogin({
      'mobile_number' :'',
      'email_id' : data.email,
      'status' : data.status,
      'reason_for_failure' : data.reason_for_failure
  });
};

const LogOutFn = (data) => {
     window.lens && window.lens.publisher.trackLogout({
          'user_id' : data ? parseInt(data) : null
      });
};
const SearchedKeywordFn = (keyword) => {
  window.lens && window.lens.publisher.trackSearched({
      'keyword' : keyword,
      'search_model' : 'global search',
      'source' : '',
      'content_clicked' : '',
      'content_id' : 0,
      'content_name' : '',
      'content_type' : '',
      'content_language' : '',
      'content_genre' : '',
      'series_name' : ''
  });
};

const IdentifyUserFn = (userId) => {
  window.lens && window.lens.user.identify(userId)
};
const TrackHomeViewFn = () => {
  //    window.lens && window.lens.publisher.trackHomeView();
};

// const SearchedTagFn = (eventname,data) =>{
//     window.lens && window.lens.publisher.trackSearch(eventname, data);

// }

const trackViewedVideoFn = () => {
  window.lens && window.lens.publisher.trackViewedVideo({
      'channel': 'Auto',
      'section': 'Test Drives',
      'authors': 'à´¸à´¨àµà´¤àµà´·àµ',
      'subSection': 'aasdS',
      'tag': 'Auto-Expo, Check',
      'lastModifiedAt': '2018-05-09T07:19:00.000Z',
      'videoId':"453453453",
      'videoType':'youtube | brightcove'
  });
};
//event names
//const viewedStarted='Content Started';
//const viewedPaused='Video Paused';
// const viewedPlayed='Viewed Played';
// const viewedEnded=' Viewed ended';
// const viewedPing='Viewed Ping';
// const viewedAdStarted='Viewed Ad Started';
// const viewedAdSkipped='Viewed Ad Skipped';
// const viewedAdClicked=' Viewed Ad Clicked';
// const viewedAdCompleted='Viewed Ad Ended';
// const viewedSeeked='Viewed Seeked'
//const  ADDED_TO_WATCHLIST='Added To WatchList';
//events trigger functions
const contentStarted = (
  data,
  contentPosition,
  carouselPosition,
  tabName,
  source,
  sourceName,
  startPoint,
  studioname
) => {
  // alert('content started')
   var duration = 0;
  let timeArray = data.content.duration.split(":");
  if (timeArray.length > 0) {
    if (
      Number(timeArray[0]) === 0 &&
      Number(timeArray[1]) === 0 &&
      Number(timeArray[2]) === 0
    ) {
    } else {
      if (Number(timeArray[0]) === 0) {
        if (Number(timeArray[1]) === 0) {
          duration = Number(timeArray[2]);
        } else {
          duration = Number(timeArray[1]) * 60 + Number(timeArray[2]);
        }
      } else {
        if (Number(timeArray[1]) === 0) {
          duration = Number(timeArray[0]) * 60 * 60 + Number(timeArray[2]);
        } else {
          duration =
            Number(timeArray[0]) * 60 * 60 +
            Number(timeArray[1]) * 60 +
            Number(timeArray[2]);
        }
      }
    }
  }
  //  console.log("data",data)
  let contentGenre = data.content.genre
    .map((gen, i) => {
      return gen.name;
    })
    .toString();
     window.lens && window.lens.publisher.trackVideoStarted({
      'content_name' : data.generalInfo.title,
      'series_name' : '',
      'content_genre' : contentGenre,
      'content_type' :data.generalInfo.type,
      'content_language' : data.content.language.length > 0?data.content.language[0]:'',
      'content_duration' : duration,
      'duration_viewed' : "",
      'start_point' : startPoint,
      'global_id' : Number(data.globalServiceId),
      'content_id' : Number(data.generalInfo._id),
      'consumption_type' : 'streaming',
      'module_name' : '',
      'module_tracking_id' : "",
      'content_position' : contentPosition ? contentPosition : null,
      'carousel_position' : Number(carouselPosition),
      'tab' : tabName,
      'source' : source,
      'source_details' : sourceName,
      'start_up_time' : "0",
      'content_model' : data.generalInfo.isSellable === true ? 'Paid':'Free',
      'user_id' : localStorage.getItem("userIdLogin") ? parseInt(localStorage.getItem("userIdLogin")) : null,
      'content_URL' :data.videos.values && data.videos.values.length ? data.videos.values[0].link :'',
      studio_name : studioname
  })
};
const videoPaused = (
  data,
  contentPosition,
  carouselPosition,
  tabName,
  source,
  sourceName,
  completed,
  pc,
  videopauseTime,
  studioname
) => {
  let contentGenre = data.content.genre
    .map((gen, i) => {
      return gen.name;
    })
    .toString();
  //alert('video paused')
  var duration = 0;
  let timeArray = data.content.duration.split(":");
  if (timeArray.length > 0) {
    if (
      Number(timeArray[0]) === 0 &&
      Number(timeArray[1]) === 0 &&
      Number(timeArray[2]) === 0
    ) {
    } else {
      if (Number(timeArray[0]) === 0) {
        if (Number(timeArray[1]) === 0) {
          duration = Number(timeArray[2]);
        } else {
          duration = Number(timeArray[1]) * 60 + Number(timeArray[2]);
        }
      } else {
        if (Number(timeArray[1]) === 0) {
          duration = Number(timeArray[0]) * 60 * 60 + Number(timeArray[2]);
        } else {
          duration =
            Number(timeArray[0]) * 60 * 60 +
            Number(timeArray[1]) * 60 +
            Number(timeArray[2]);
        }
      }
    }
  }
  window.lens && window.lens.publisher.trackVideoPaused({
    content_name: data.generalInfo.title,
    series_name: "",
    content_genre: contentGenre,
    content_type: data.generalInfo.type,
    content_language:
      data.content.language.length > 0 ? data.content.language[0] : "",
    content_duration: duration,
    duration_viewed: videopauseTime,
    paused_at: videopauseTime,
    global_id: Number(data.globalServiceId),
    content_id: Number(data.generalInfo._id),
    cast: "",
    percentage_completed: pc,
    play_button: "pause",
    consumption_type: "streaming",
    completed: completed,
    module_name: "",
    module_tracking_id: "",
    content_position:  contentPosition ? contentPosition : null,
    carousel_position: Number(carouselPosition),
    tab: tabName,
    source: source,
    source_details: sourceName,
    content_model: data.generalInfo.isSellable === true ? "Paid" : "Free",
    user_id: localStorage.getItem("userIdLogin")
      ? parseInt(localStorage.getItem("userIdLogin"))
      : null,
    content_URL:
      data.videos.values && data.videos.values.length
        ? data.videos.values[0].link
        : "",
    studio_name: studioname,
  });
};
const adStarted = (
  data,
  contentPosition,
  carouselPosition,
  tabName,
  source,
  sourceName,ad_id
) => {
  let contentGenre = data.content.genre
    .map((gen, i) => {
      return gen.name;
    })
    .toString();
  window.lens && window.lens.publisher.trackVideoAdStarted({
    content_type: data.generalInfo.type,
    content_id: Number(data.generalInfo._id),
    content_name: data.generalInfo.title,
    series_name: "",
    content_language:
      data.content.language.length > 0 ? data.content.language[0] : "",
    content_genre: contentGenre,
    source: source,
    source_details: sourceName,
    content_position: contentPosition ? contentPosition : null,
    carousel_position: Number(carouselPosition),
    tab: tabName,
    content_model: data.generalInfo.isSellable === true ? "Paid" : "Free",
    ad_type: "",
    skippable_ad: "",
    skip_status: "",
    duration_played: "",
    total_ad_duration: "",
    ad_status: "started",
    ad_id: ad_id,
  });
};
const adPaused = (
  data,
  contentPosition,
  carouselPosition,
  tabName,
  source,
  sourceName,ad_id
) => {
  let contentGenre = data.content.genre
    .map((gen, i) => {
      return gen.name;
    })
    .toString();
  window.lens && window.lens.publisher.trackVideoAdStarted({
    content_type: data.generalInfo.type,
    content_id: Number(data.generalInfo._id),
    content_name: data.generalInfo.title,
    series_name: "",
    content_language:
      data.content.language.length > 0 ? data.content.language[0] : "",
    content_genre: contentGenre,
    source: source,
    source_details: sourceName,
    content_position: contentPosition ? contentPosition : null,
    carousel_position: Number(carouselPosition),
    tab: tabName,
    content_model: data.generalInfo.isSellable === true ? "Paid" : "Free",
    ad_type: "",
    skippable_ad: "",
    skip_status: "",
    duration_played: "",
    total_ad_duration: "",
    ad_status: "Paused",
    ad_id: ad_id,
  });
};
const addToFavirote = (data, status) => {
  let favStatus;

  if (status) {
    favStatus = "yes";
  } else {
    favStatus = "no";
  }
  let sd = "";
  if(data && data.generalInfo && data.generalInfo.type == "tvepisode"){

    sd = `${data.globalServiceName} | ${data.content.siblingOrder}`;
  }
  window.lens && window.lens.publisher.trackFavourite({
    content_id: Number(data.generalInfo._id),
    content_name: data.generalInfo.title,
    "series_name": "",
    content_model: data.generalInfo.isSellable === true ? "Paid" : "Free",
    content_type: data.generalInfo.type,
    status: favStatus,
    source_detail: sd,
    content_language:
     data.content.language.length > 0 ? data.content.language[0] : "",
  });
};
const tabViewed = (pagename) => {
  window.lens && window.lens.publisher.trackPromoVideoShowed({
    tab: pagename,
  });
};
const contentDetailsViewed = (
  data,
  contentPosition,
  carouselPosition,
  tabName,
  source,
  sourceName,
  studioname
) => {
  let contentGenre = data.content.genre
    .map((gen, i) => {
      return gen.name;
    })
    .toString();
    window.lens && window.lens && window.lens && window.lens.publisher.trackVideoDetailsViewed({
    content_name: data.generalInfo.title,
    series_name: "",
    content_genre: contentGenre,
    content_type: data.generalInfo.type,
    content_language:
      data.content.language.length > 0 ? data.content.language[0] : "",
    global_id: Number(data.globalServiceId),
    content_id: Number(data.generalInfo._id),
    module_name: "",
    module_tracking_id: "",
    content_position: contentPosition ? contentPosition : null,
    carousel_position: Number(carouselPosition),
    tab: tabName,
    source: source,
    source_details: sourceName,
    content_model: data.generalInfo.isSellable === true ? "Paid" : "Free",
    user_id: localStorage.getItem("userIdLogin")
      ? parseInt(localStorage.getItem("userIdLogin"))
      : null,
    content_URL:
      data.videos.values && data.videos.values.length
        ? data.videos.values[0].link
        : "",
    studio_name: studioname,
  });
};
const contentPlayed = (
  data,
  contentPosition,
  carouselPosition,
  tabName,
  source,
  sourceName ,
  pc,
  completed,
  studioname
) => {
    let contentGenre = data.content.genre
    .map((gen, i) => {
      return gen.name;
    })
    .toString();
  var duration = 0;
  let timeArray = data.content.duration.split(":");
  if (timeArray.length > 0) {
    if (
      Number(timeArray[0]) === 0 &&
      Number(timeArray[1]) === 0 &&
      Number(timeArray[2]) === 0
    ) {
    } else {
      if (Number(timeArray[0]) === 0) {
        if (Number(timeArray[1]) === 0) {
          duration = Number(timeArray[2]);
        } else {
          duration = Number(timeArray[1]) * 60 + Number(timeArray[2]);
        }
      } else {
        if (Number(timeArray[1]) === 0) {
          duration = Number(timeArray[0]) * 60 * 60 + Number(timeArray[2]);
        } else {
          duration =
            Number(timeArray[0]) * 60 * 60 +
            Number(timeArray[1]) * 60 +
            Number(timeArray[2]);
        }
      }
    }
  }
  
  window.lens && window.lens.publisher.trackVideoPlayed({
    content_name: data.generalInfo.title,
    series_name: "",
    content_genre: contentGenre,
    content_type: data.generalInfo.type,
    content_language:
      data.content.language.length > 0 ? data.content.language[0] : "",
    content_duration: duration,
    duration_viewed: "",
   
    global_id: Number(data.globalServiceId),
    content_id: Number(data.generalInfo._id),
    consumption_type: "streaming",
    module_name: "",
    module_tracking_id: "",
    content_position: contentPosition ? contentPosition : null,
    carousel_position: Number(carouselPosition),
    tab: tabName,
    source: source,
    source_details: sourceName,
    //start_up_time: "",
    content_model: data.generalInfo.isSellable === true ? "Paid" : "Free",
    user_id: localStorage.getItem("userIdLogin")
      ? parseInt(localStorage.getItem("userIdLogin"))
      : null,
    content_URL:
      data.videos.values && data.videos.values.length
        ? data.videos.values[0].link
        : "",
    studio_name: studioname,
    cast : "",
   percentage_completed: pc,
     play_button: "Resume",
     completed : completed,
  });
};
const videoEnded = (
  data,
  contentPosition,
  carouselPosition,
  tabName,
  source,
  sourceName,
  studioname
) => {
  let contentGenre = data.content.genre
    .map((gen, i) => {
      return gen.name;
    })
    .toString();
  var duration = 0;
  let timeArray = data.content.duration.split(":");
  if (timeArray.length > 0) {
    if (
      Number(timeArray[0]) === 0 &&
      Number(timeArray[1]) === 0 &&
      Number(timeArray[2]) === 0
    ) {
    } else {
      if (Number(timeArray[0]) === 0) {
        if (Number(timeArray[1]) === 0) {
          duration = Number(timeArray[2]);
        } else {
          duration = Number(timeArray[1]) * 60 + Number(timeArray[2]);
        }
      } else {
        if (Number(timeArray[1]) === 0) {
          duration = Number(timeArray[0]) * 60 * 60 + Number(timeArray[2]);
        } else {
          duration =
            Number(timeArray[0]) * 60 * 60 +
            Number(timeArray[1]) * 60 +
            Number(timeArray[2]);
        }
      }
    }
  }
  window.lens && window.lens.publisher.trackVideoEnded({
    content_name: data.generalInfo.title,
    series_name: "",
    content_genre: contentGenre,
    content_type: data.generalInfo.type,
    content_language:
      data.content.language.length > 0 ? data.content.language[0] : "",
    content_duration: duration,
    global_id: Number(data.globalServiceId),
    content_id: Number(data.generalInfo._id),
    cast: "",
    play_button: "",
    consumption_type: "",
    module_name: "",
    module_tracking_id: "",
    content_position: contentPosition ? contentPosition : null,
    carousel_position: Number(carouselPosition),
    tab: tabName,
    source: source,
    source_details: sourceName,
    content_model: data.generalInfo.isSellable === true ? "Paid" : "Free",
    user_id: localStorage.getItem("userIdLogin")
      ? parseInt(localStorage.getItem("userIdLogin"))
      : null,
    content_URL:
      data.videos.values && data.videos.values.length
        ? data.videos.values[0].link
        : "",
    studio_name: studioname,
  });
};
const adSkipped = (
  data,
  contentPosition,
  carouselPosition,
  tabName,
  source,
  sourceName,ad_id
) => {
  let contentGenre = data.content.genre
    .map((gen, i) => {
      return gen.name;
    })
    .toString();

  window.lens && window.lens.publisher.trackVideoAdSkipped({
    content_type: data.generalInfo.type,
    content_id: Number(data.generalInfo._id),
    content_name: data.generalInfo.title,
    series_name: "",
    content_language:
      data.content.language.length > 0 ? data.content.language[0] : "",
    content_genre: contentGenre,
    source: source,
    source_details: sourceName,
    content_position: contentPosition ? contentPosition : null,
    carousel_position: Number(carouselPosition),
    tab: tabName,
    content_model: data.generalInfo.isSellable === true ? "Paid" : "Free",
    ad_type: "",
    skippable_ad: "",
    skip_status: "",
    duration_played: "",
    total_ad_duration: "",
    ad_status: "skipped",
    ad_id: ad_id,
  });
};
const contentPing = (
  data,
  contentPosition,
  carouselPosition,
  tabName,
  source,
  sourceName,
  complete,
  studioname,
  pc,
  ping_duration,
  duration_viewed,
  play,

) => {
  let contentGenre = data.content.genre
    .map((gen, i) => {
      return gen.name;
    })
    .toString();
  var duration = 0;
  let timeArray = data.content.duration.split(":");
  if (timeArray.length > 0) {
    if (
      Number(timeArray[0]) === 0 &&
      Number(timeArray[1]) === 0 &&
      Number(timeArray[2]) === 0
    ) {
    } else {
      if (Number(timeArray[0]) === 0) {
        if (Number(timeArray[1]) === 0) {
          duration = Number(timeArray[2]);
        } else {
          duration = Number(timeArray[1]) * 60 + Number(timeArray[2]);
        }
      } else {
        if (Number(timeArray[1]) === 0) {
          duration = Number(timeArray[0]) * 60 * 60 + Number(timeArray[2]);
        } else {
          duration =
            Number(timeArray[0]) * 60 * 60 +
            Number(timeArray[1]) * 60 +
            Number(timeArray[2]);
        }
      }
    }
  }
  window.lens && window.lens.publisher.trackVideoPing({
    content_name: data.generalInfo.title,
    series_name: "",
    content_genre: contentGenre,
    content_type: data.generalInfo.type,
    content_language:
      data.content.language.length > 0 ? data.content.language[0] : "",
    content_duration: duration,
    duration_viewed: parseInt(duration_viewed),
    ping_duration: ping_duration,
    global_id: Number(data.globalServiceId),
    content_id: Number(data.generalInfo._id),
    cast: "",
    percentage_completed: pc,
    play_button: play,
    consumption_type: "",
    completed: complete,
    module_name: "",
    module_tracking_id: "",
    content_position: contentPosition ? contentPosition : null,
    carousel_position: Number(carouselPosition),
    tab: tabName,
    source: source,
    source_details: sourceName,
    content_model: data.generalInfo.isSellable === true ? "Paid" : "Free",
    user_id: localStorage.getItem("userIdLogin")
      ? parseInt(localStorage.getItem("userIdLogin"))
      : null,
    content_URL:
      data.videos.values && data.videos.values.length
        ? data.videos.values[0].link
        : "",
    studio_name: studioname,
  });
};
const adClicked = (
  data,
  contentPosition,
  carouselPosition,
  tabName,
  source,
  sourceName,ad_id
) => {
  let contentGenre = data.content.genre
    .map((gen, i) => {
      return gen.name;
    })
    .toString();
  window.lens && window.lens.publisher.trackVideoAdClicked({
    content_type: data.generalInfo.type,
    content_id: Number(data.generalInfo._id),
    content_name: data.generalInfo.title,
    series_name: "",
    content_language:
      data.content.language.length > 0 ? data.content.language[0] : "",
    content_genre: contentGenre,
    source: source,
    source_details: sourceName,
    content_position: contentPosition ? contentPosition : null,
    carousel_position: Number(carouselPosition),
    tab: tabName,
    content_model: data.generalInfo.isSellable === true ? "Paid" : "Free",
    ad_type: "",
    skippable_ad: "",
    skip_status: "",
    duration_played: "",
    total_ad_duration: "",
    ad_status: "Clicked",
    ad_id: ad_id,
  });
};
const adEnded = (
  data,
  contentPosition,
  carouselPosition,
  tabName,
  source,
  sourceName,ad_id
) => {
  let contentGenre = data.content.genre
    .map((gen, i) => {
      return gen.name;
    })
    .toString();
  window.lens && window.lens.publisher.trackVideoAdEnded({
    content_type: data.generalInfo.type,
    content_id: Number(data.generalInfo._id),
    content_name: data.generalInfo.title,
    series_name: "",
    content_language:
      data.content.language.length > 0 ? data.content.language[0] : "",
    content_genre: contentGenre,
    source: source,
    source_details: sourceName,
    content_position: contentPosition ? contentPosition : null,
    carousel_position: parseInt(carouselPosition),
    tab: tabName,
    content_model: data.generalInfo.isSellable === true ? "Paid" : "Free",
    ad_type: "",
    skippable_ad: "",
    skip_status: "",
    duration_played: "",
    total_ad_duration: "",
    ad_status: "ended",
    ad_id: ad_id,
  });
};
const videoSeeked = (
  data,
  contentPosition,
  carouselPosition,
  tabName,
  source,
  source_detail,
  seekedStart,
  seekedEnd,
  pc,
  completed,
  studioname
) => {
  let contentGenre = data.content.genre
    .map((gen, i) => {
      return gen.name;
    })
    .toString();
  var duration = 0;
  let timeArray = data.content.duration.split(":");
  if (timeArray.length > 0) {
    if (
      Number(timeArray[0]) === 0 &&
      Number(timeArray[1]) === 0 &&
      Number(timeArray[2]) === 0
    ) {
    } else {
      if (Number(timeArray[0]) === 0) {
        if (Number(timeArray[1]) === 0) {
          duration = Number(timeArray[2]);
        } else {
          duration = Number(timeArray[1]) * 60 + Number(timeArray[2]);
        }
      } else {
        if (Number(timeArray[1]) === 0) {
          duration = Number(timeArray[0]) * 60 * 60 + Number(timeArray[2]);
        } else {
          duration =
            Number(timeArray[0]) * 60 * 60 +
            Number(timeArray[1]) * 60 +
            Number(timeArray[2]);
        }
      }
    }
  }
  window.lens && window.lens.publisher.trackVideoSeeked({
    content_name: data.generalInfo.title,
    series_name: "",
    content_genre: contentGenre,
    content_type: data.generalInfo.type,
    content_language:
      data.content.language.length > 0 ? data.content.language[0] : "",
    content_duration: duration,
    seek_start: seekedStart,
    seek_end: seekedEnd,
    global_id: Number(data.globalServiceId),
    content_id: Number(data.generalInfo._id),
    cast: "",
    percentage_completed: pc,
    play_button: "Resume",
    consumption_type: "",
    completed: completed,
    module_name: "",
    module_tracking_id: "",
    content_position: contentPosition ? contentPosition : null,
    carousel_position: Number(carouselPosition),
    tab: tabName,
    source: source,
    source_details: source_detail,
    content_model: data.generalInfo.isSellable === true ? "Paid" : "Free",
    user_id: localStorage.getItem("userIdLogin")
      ? parseInt(localStorage.getItem("userIdLogin"))
      : null,
    content_URL:
      data.videos.values && data.videos.values.length
        ? data.videos.values[0].link
        : "",
    studio_name: studioname
  });
};

const paymentStatus = (id, status) => {
  // window.lens && window.lens.publisher.trackPaymentStatus({
  //   content_id: id,
  //   status: status,
  //   payment_details: "",
  //   reason_for_failure: "",
  //   error_code: "",
  //   package_name: "",
  //   package_duration: "",
  //   package_price: "",
  //   coupons: "",
  //   coupon_details: "",
  //   price: "",
  //   currency: "",
  //   module_name: "",
  //   module_tracking_id: "",
  //   source: "",
  //   source_details: "",
  //   content_name: "",
  //   series_name: "",
  
  // });
};

const popUpShowed = (data) => {
  window.lens && window.lens.publisher.trackPopUpShowed({
    popup_id: 0,
    popup_name: data["pop-up name"],
    popup_message: data["pop-up message"],
  });
};
const popUpResponse = (data) => {
  window.lens && window.lens.publisher.trackPopUpResponse({
    popup_id: 0,
    popup_name: data["pop-up name"],
    popup_message: data["pop-up message"],
    response: data["response"],
    response_value: data["value"],
  });
};
const signUp = (data) => {
  window.lens && window.lens.publisher.trackSignUp(data);
};
const promoVideoShowed = (
  data,
  contentPosition,
  carouselPosition,
  tabName,
  source,
  sourceName
) => {
  window.lens && window.lens.publisher.trackPromoVideoShowed({
    promo_id: Number(data.generalInfo._id),
    action: sessionStorage.getItem("vStatus")
      ? sessionStorage.getItem("vStatus")
      : "",
    duration_viewed: sessionStorage.getItem("vCurTime")
      ? sessionStorage.getItem("vCurTime")
      : "",
    iteration: "",
  });
  sessionStorage.removeItem("vCurTime");
  sessionStorage.removeItem("vDur");
  sessionStorage.removeItem("vStatus");
};
const shareEvent = (data) => {
  window.lens && window.lens.publisher.trackShare({
    content_id: Number(data._id),
    content_name: data.generalInfo.title,
    "series_name": "",
    content_model: data.generalInfo.isSellable === true ? "Paid" : "Free",
    content_type: data.generalInfo.type,
    content_language:
      data.content.language.length > 0 ? data.content.language[0] : "",
  });
};
const addToPlayList = (data, status) => {
  let playlistStatus;

  if (status) {
    playlistStatus = "added";
  } else {
    playlistStatus = "removed";
  }

  let contentGenre = data.content.genre
    .map((gen, i) => {
      return gen.name;
    })
    .toString();
    let sd = "";
    if(data.generalInfo.type == "tvepisode"){

      sd = `${data.globalServiceName} | ${data.content.siblingOrder}`;
    }
  window.lens && window.lens.publisher.trackAddedToWatchList({
    action: playlistStatus,
    content_id: Number(data.generalInfo._id),
    content_name: data.generalInfo.title,
    content_language:
      data.content.language.length > 0 ? data.content.language[0] : "",
    content_genre: contentGenre,
    source: "",
    source_detail: sd,
    series_name: "",
  });
};
const removePlayList = (data, status) => {
  let playlistStatus;

  if (status) {
    playlistStatus = "added";
  } else {
    playlistStatus = "removed";
  }
  let sd = "";
    if(data && data.generalInfo && data.generalInfo.type == "tvepisode"){

      sd = `${data.globalServiceName} | ${data.content.siblingOrder}`;
    }
  
  window.lens && window.lens.publisher.trackAddedToWatchList({
    action: playlistStatus,
    content_id: data.id,
    content_name: data.title,
    content_language:"",
    content_genre: "",
    source: "",
    source_detail: "",
    series_name: "",
  });
};
//  const postMessage = (eventName, eventData,videoMetaData,attachPingData) => {
//     // var videoProps = {
//     //     meta_video_id: `<video id>`,
//     //     meta_account_id: `<video account id>`,
//     //     meta_video_name: `<video name>`,
//     //     meta_video_duration: `<video duration in milliseconds>`,
//     //     meta_link: `<video url>`,
//     //     meta_published_at: `<video published at>`,
//     //     meta_updated_at: `<video updated at>`,
//     //     meta_tags: `<media tags ? JSON.stringify(array of media tags) : JSON.stringify([])>`,
//     //     meta_thumbnail: `<video thumbnail>`,
//     // };

//     var props = videoMetaData;
//     //var eventData = eventData;
//     // if (attachPingData) {
//     //     eventData.playRatio = 'playratio'
//     //     eventData.engagedTime = 'engaged time';
//     // }
//     var payload = {
//         event: eventName,
//         data: eventData,
//         meta: props,
//     };
//     window.postMessage(JSON.stringify(payload), '*')
//     //debugger
// };

const lensEvents = {
  logginfn: (data) => LoginFn(data),
  logoutfn: (data) => LogOutFn(data),
  searchedkeywordfn: (data) => SearchedKeywordFn(data),
  identifyuserfn: (data) => IdentifyUserFn(data),
  trackhomeview: () => TrackHomeViewFn(),
  trackvideoviewedfn: () => trackViewedVideoFn(),
  //searchedtagfn :() => SearchedTagFn(SearchedTag),

  //postvideoevents: (eventName,eventData,videoMetaData,attachPingData) => postMessage(eventName,eventData,videoMetaData,attachPingData),
  viewedStarted: (
    data,
    contentPosition,
    carouselPosition,
    tabName,
    source,
    sourceName,
    startTime,
    currentTime,
    studioname
  ) =>
    contentStarted(
      data,
      contentPosition,
      carouselPosition,
      tabName,
      source,
      sourceName,
      startTime,
      currentTime,
      studioname
    ),
  contentPlayed: (
    data,
    contentPosition,
    carouselPosition,
    tabName,
    source,
    sourceName,
    cast1,
    playbutton,
    pc,
    completed,
    studioname
  ) =>
    contentPlayed(
      data,
      contentPosition,
      carouselPosition,
      tabName,
      source,
      sourceName,
      cast1,
      playbutton,
      pc,
      completed,
      studioname
    ),
  viewedPaused: (
    data,
    contentPosition,
    carouselPosition,
    tabName,
    source,
    sourceName,
    completed,
    pc,
    videopauseTime,
    studioname
  ) =>
    videoPaused(
      data,
      contentPosition,
      carouselPosition,
      tabName,
      source,
      sourceName,
      completed,
      pc,
      videopauseTime,
      studioname
    ),
  videoEnded: (
    data,
    contentPosition,
    carouselPosition,
    tabName,
    source,
    sourceName
  ) =>
    videoEnded(
      data,
      contentPosition,
      carouselPosition,
      tabName,
      source,
      sourceName
    ),
  contentPing: (
    data,
    contentPosition,
    carouselPosition,
    tabName,
    source,
    sourceName,
    complete,
    studioname,
    pc,
    ping_duration,
    duration_viewed,
    play,
  ) =>
    contentPing(
      data,
      contentPosition,
      carouselPosition,
      tabName,
      source,
      sourceName,
      complete,
      studioname,
      pc,
      ping_duration,
      duration_viewed,
      play,
      
    ),
  adStart: (
    data,
    contentPosition,
    carouselPosition,
    tabName,
    source,
    sourceName,ad_id
  ) =>
    adStarted(
      data,
      contentPosition,
      carouselPosition,
      tabName,
      source,
      sourceName,ad_id
    ),
  adSkipped: (
    data,
    contentPosition,
    carouselPosition,
    tabName,
    source,
    sourceName,ad_id
  ) =>
    adSkipped(
      data,
      contentPosition,
      carouselPosition,
      tabName,
      source,
      sourceName,ad_id
    ),
  adClicked: (
    data,
    contentPosition,
    carouselPosition,
    tabName,
    source,
    sourceName,ad_id
  ) =>
    adClicked(
      data,
      contentPosition,
      carouselPosition,
      tabName,
      source,
      sourceName,ad_id
    ),
  adPaused: (
    data,
    contentPosition,
    carouselPosition,
    tabName,
    source,
    sourceName,ad_id
  ) =>
    adPaused(
      data,
      contentPosition,
      carouselPosition,
      tabName,
      source,
      sourceName,ad_id
    ),
  adEnded: (
    data,
    contentPosition,
    carouselPosition,
    tabName,
    source,
    sourceName,
    ad_id
  ) =>
    adEnded(
      data,
      contentPosition,
      carouselPosition,
      tabName,
      source,
      sourceName,
      ad_id
    ),
  videoSeeked: (
    data,
    contentPosition,
    carouselPosition,
    tabName,
    source,
    source_detail,
    seekedStart,
    seekedEnd,
    pc,
    completed,
    studioname
  ) =>
    videoSeeked(
      data,
      contentPosition,
      carouselPosition,
      tabName,
      source,
      source_detail,
      seekedStart,
      seekedEnd,
      pc,
      completed,
      studioname
    ),
  addToFavirote: (data, status) => addToFavirote(data, status),
  tabViewed: (pagename) => tabViewed(pagename),
  contentDetailsViewed: (
    data,
    contentPosition,
    carouselPosition,
    tabName,
    source,
    sourceName,
    currenttime,
    studioname
  ) =>
    contentDetailsViewed(
      data,
      contentPosition,
      carouselPosition,
      tabName,
      source,
      sourceName,
      currenttime,
      studioname
    ),
  paymentStatus: (id, status) => paymentStatus(id, status),
  popUpShowed: (data) => popUpShowed(data),
  popUpResponse: (data) => popUpResponse(data),
  signUp: (data, country) => signUp(data, country),
  promoVideoShowed: (
    data,
    contentPosition,
    carouselPosition,
    tabName,
    source,
    sourceName
  ) =>
    promoVideoShowed(
      data,
      contentPosition,
      carouselPosition,
      tabName,
      source,
      sourceName
    ),
  shareEvent: (data) => shareEvent(data),
  addToPlayList: (data, status) => addToPlayList(data, status),
  removePlayList:(data,status)=>removePlayList(data,status)
};

export default lensEvents;
