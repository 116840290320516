import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import closeicon from "../../assets/images/close-circle-outline.png";
import success_illustration from "../../assets/images/success_illustration.png";
import "./popupmodal.css";
import myplexAPI from "../../../manorama/api/myplexAPI";
function DemoGraphicCongratsPopup(props) {
    const [show, setShow] = useState(false);
    const [popupTitle, setPopupTitle] = useState('');
    const [popupText, setPopupText] = useState('');
    const [popupButtonText, setPopupButtonText] = useState('');
    const [popupSubText, setPopupSubText] = useState('');
    useEffect(() => {
        setPopupTitle(props.pwaDemographicConfig.popup_success_title);
        setPopupText(props.pwaDemographicConfig.profileUpdate_popup_sucess_message);
        setPopupButtonText(props.pwaDemographicConfig.profileUpdate_popup_sucess_click_text);
        setPopupSubText(props.pwaDemographicConfig.profileUpdate_popup_sucess_title)
        setShow(props.pwaDemographicSuccessPopup);
    }, [props]);
    const close = () => {
        props.setDemoGraphicCongratsPopup(false);
    }
    const successButtonClick = () => {
        if(window && window.location.pathname.includes("/detail/")){
            window.location.reload();
        }else{
            myplexAPI.getMyPackages().then(response => {
                // console.log("res", response)
                if(response.data.code === 200 && response.data.status === 'SUCCESS'){
                  sessionStorage.setItem('userPackages', JSON.stringify(response.data));
                  sessionStorage.setItem('userfeatures', JSON.stringify(response.data.features));
                  if (response && response.data && response.data.results && response.data.results.length > 0) {
                    sessionStorage.setItem('userPacks', JSON.stringify(response.data.results));
                  }else{
                    sessionStorage.setItem('userPacks', JSON.stringify(""));
                  }
                }
            });
        }        
        props.setDemoGraphicCongratsPopup(false);
        var url = localStorage.getItem("redirectUrl") || "/";
        if(document.getElementById("demographic") !== undefined && document.getElementById("demographic") !== null){
            document.getElementById("demographic").remove();
        }
        //props.history.push(url);
        props.closePopUp(false);
    }
    return (
        <>
            {props && props.pwaDemographicConfig && Object.keys(props.pwaDemographicConfig).length > 0 && show &&
            <Modal
                className="popup_registration"
                show={show}
                //onHide={show}
            >                
                <div className="success_illustration">
                    <ModalHeader>
                        <ModalTitle><p>{popupTitle}</p></ModalTitle>
                        {/* <img src={closeicon} alt="closeicon" className="closeicon" onClick={()=>close()}/> */}
                    </ModalHeader>
                    <ModalBody>
                        <img src={success_illustration} alt="success_illustration" />
                        {/* <h1>Congrats!</h1> */}
                        <p>{popupSubText}</p>
                        <p>{popupText}</p>                        
                        <button type="subbuttonmit" className="submit_btn" onClick={()=>successButtonClick()} >{popupButtonText}</button>
                    </ModalBody>
                </div>
            </Modal>
             }

        </>
    );
}
export default DemoGraphicCongratsPopup;
