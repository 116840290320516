// Reels.js
import { useState, useEffect , useRef} from "react";
import Videodata from "../../shorts/videodata";
import myplexAPI from "../api/myplexAPI";
import placeholder_horizontal from "../assets/images/movie_thumbnail_placeholder_main2.png";
import ParseImgURL from "../utils/parseImgURL";
import { isMobile } from "react-device-detect";
import { redirectPlayStore } from "../utils/portalhelper";
function Reels() {
  const [videos, setVideos] = useState([]);
  const [videosLoaded, setvideosLoaded] = useState(false);
	const [startIndex, setStartIndex] = useState(1);
	const promotionId = useRef("");
  const getVideos = () => {
		let time = new Date().getTime();
		//console.log("promotionId ",promotionId.current);
		myplexAPI
			.getCarouselData(
				"shortVideosPwa1",
				startIndex,
				"10",
				"1701172181",
				time,
				promotionId.current
			)
			.then((response) => {
				if (response.data.status === "SUCCESS") {
					console.log("response - ",response);
					promotionId.current = "";
					var newVideos = [];
					if (response.data.results.length > 0) {
						newVideos = response.data.results;
						setVideos((oldVideos) => [...oldVideos, ...newVideos]);
						setvideosLoaded(true);
						setStartIndex(startIndex + 1);
					}
				} else {

				}
			});
	};
	useEffect(() => {
		var pathName = window.location.pathname.split("/");
		if (pathName[2] && pathName[2] !== undefined && pathName[2] !== null) {
			promotionId.current = pathName[2];
		}
		// if(isMobile) {
		// 	redirectPlayStore(promotionId.current,"reels");
		// }
		getVideos();

    
	}, []);

  return (
    <main>
      <div className="slider-container_reels">
        {videos.length > 0 ? (
          <>
            {videos.map((video, id) => (
              <Videodata
              key={id}
              index={id}
              author={video.generalInfo.title}
              videoURL={video.previews !== undefined && Object.keys(video.previews).length > 0 && video.previews.values.length > 0 ? video.previews.values[0].link : ''}
              //videoURL={video}
              authorLink='Manorama'
              lastVideoIndex={videos.length - 1}
              getVideos={getVideos}
              poster={ParseImgURL(video.images !== undefined && Object.keys(video.images).length > 0 && video.images.values.length > 0 ? video.images.values : [], "mdpi", "thumbnail")}
              description={video.generalInfo.description}
              item={video}
              />
            ))}
          </>
        ) : (
          <>
            <h1>Nothing to show here</h1>
          </>
        )}
      </div>
    </main>
  );
}

export default Reels;
