
import myplexAPI from "../api/myplexAPI";
const publishingHouseIds = () => {
  /**
   *
   * checking the session storage for key value publishing house ids
   *
   */

    myplexAPI.publishingHouseIds();
};

export default publishingHouseIds;
