import React, { Component } from "react";
import ParseImgURL from "../../utils/parseImgURL";
import bannerimage from "../../assets/images/banner_placeholder.jpg";
import myplexAPI from "../../api/myplexAPI";
import {Link} from "react-router-dom";
// import {getURLName} from "../../utils/urlMenu";
// import {slugify} from "../../utils/stringSlugify";

import {getURLName} from "../../../commonfiles/urlMenu";
import {slugify} from "../../../commonfiles/stringSlugify";

import Img from "../Uicomponents/Img";
import {carouselPatnerLogos} from "../../utils/partnerLogos";
import LazyLoad from "react-lazyload";
import { withRouter } from "react-router";
import sessionFun  from "../../../commonfiles/SessionStorage"



class SingleBanner extends Component {
    list = '';
    constructor(props){
        super(props);
        this.state = {
            singleBanner: []
        };
        this.auth = localStorage.getItem("Logged-in");

    }
    componentDidMount() {
        this._getSingleBanner();
    }

    errImg = (e) => {
        e.target.src = bannerimage
    };

    singleBannerClick = (URL,type,title,images,description) => {
        // console.log(URL)
        // console.log(type)
        // console.log(title)
        // console.log(images)
        // console.log(description)
        let src = images.values[0].link;
        // console.log(src)
    
        localStorage.setItem('actorImage',src)
        localStorage.setItem('actorType',type)
        localStorage.setItem('personDecription',description)

    
        if(this.auth){
    
          if(type !== 'person' && type !== 'actor'){
            this.props.history.push(URL);
    
          }else{
            this.props.history.push(`/Actorprofile/${title}`)
          }
        }else{
            localStorage.setItem('redirectUrl',URL);
            this.props.history.push(URL);
            //    this.props.history.push("/login");
    
        }
    
      }

    singleBanner = () =>{
        return ParseImgURL(this.props.caroselinfo.images, "xxhdpi", "icon");
    };
    bannerImages = () =>
        this.state.singleBanner.map((banner, i) => {
            // console.log(banner)
            let logo = null;
            if (banner.publishingHouse !== undefined) {
                let partnerLogos = carouselPatnerLogos(
                    banner.publishingHouse.publishingHouseName
                );
                if (
                    partnerLogos.link !== undefined &&
                    this.props.layoutType !== "horizontalListSmallItem" &&
                    this.props.layoutType !== "horizontalListMediumItem"
                ) {
                    logo = (
                        <LazyLoad once>
                            <img
                                src={partnerLogos.link}
                                alt={partnerLogos.name}
                                className="single_banner_logo"
                            />
                        </LazyLoad>
                    );
                }
            }
            sessionFun(this.props.carousel_count,i+1,this.state.pageName,'singlBanner',this.props.caroselinfo.title,banner.generalInfo._id);
        //     var firebaseValue =
        //     this.props.carousel_count +
        //     "-" +
        //     (i + 1) +
        //     "-" +
        //     this.state.pageName+"-"+this.props.caroselinfo.title ;
        //   sessionStorage.setItem(banner.generalInfo._id, firebaseValue);
            // console.log(logo)
            if(banner.content.actionType==="Subscribe"){
                // var redirecturl = `/subscribe/${banner.content.actionURL}`;
                return (
              
                    <div className="carousel_img" key={i}>
                    <Link
                        to={{pathname: `/subscribe/${banner.content.actionURL}`}}
                        key={i}>
                        <Img caroselimg={banner} key={i}
                            banner={true} layouttype={this.props.caroselinfo}
                            caroselname={this.props.caroselinfo.name} observer={true}/>
                        {logo}
                    </Link>
                    </div>
                );
      
              }else {

                return (
              
                    <div className="carousel_img" onClick = {() => this.singleBannerClick(
                        `/${getURLName(banner, this.state.pageName)}/detail/${banner.generalInfo._id}/${slugify(banner.generalInfo.title)}`,
                        banner.generalInfo.type,banner.generalInfo.title,banner.images,banner.generalInfo.description)} key={i}>
                    {/* <Link
                        to={{pathname: `/${getURLName(banner, this.state.pageName)}/detail/${banner.generalInfo._id}/${slugify(banner.generalInfo.title)}`}}
                        key={i}> */}
                        <Img caroselimg={banner} key={i}
                            banner={true} layouttype={this.props.caroselinfo}
                            caroselname={this.props.caroselinfo.name} observer={true}/>
                        {logo}
                    {/* </Link> */}
                    </div>
                );

              }
            
        });

    _getSingleBanner = () => {
        this.list = localStorage.getItem('selectedLanguageList');
        myplexAPI.getCarouselData(this.props.caroselinfo.name, 1, 10,this.props.caroselinfo.modified_on)
            .then(response => {
                this.setState({
                    singleBanner: response.data.results
                })
            })
    };
    render(){
        return(
           <div className="singleBanner">
             <div className="desktop_singlebanner">
               {this.state.singleBanner.length > 0 && this.bannerImages()}
             </div>
           </div>)
    }
}
export default withRouter(SingleBanner);
