import React from "react";
import ParseImgURL from "./parseImgURL";
import placeholder_horizontal from "../assets/images/movie_thumbnail_placeholder.png";
import placeholder_vertical from "../assets/images/videos___shows_placeholder.png";
import bannerimage from "../assets/images/banner_placeholder.jpg";
import mobile_bannerimage from "../assets/images/mobile_placeholder.jpg";
import shortvideo_thumbnail_placeholder from "../assets/images/movie_thumbnail_placeholder_main2.png";
import "intersection-observer"; // optional polyfill
import { isMobile } from "react-device-detect";
const similarContentImg = props => {
    var id = props.caroselimg.generalInfo._id;
    var imagepath;
    var imageclass;
    var profile;
    var type;
    var altimage;

    if (!props.banner) {
        if (props.layouttype === "horizontalListBigItem") {
            imageclass = "horizontalimage";
            profile = "xhdpi";
            type = "portraitcoverposter";
            altimage = placeholder_horizontal;
        } else {
            if(props.caroselimg.generalInfo.type && props.caroselimg.generalInfo.type === 'shortvideo'){
                imageclass = "horizontalimage";
                profile = "mdpi";
                type = "thumbnail";
                altimage = shortvideo_thumbnail_placeholder;

            }else{
                imageclass = "verticalimage";
                profile = "mdpi";
                type = "coverposter";
                altimage = placeholder_vertical;
            }
        }
        imagepath = process.env.REACT_APP_THUMBOR_IMAGES+ParseImgURL(props.caroselimg.images.values, profile, type);
    } else {
        imageclass = "bannerimage";
        if (isMobile) {
            if (props.layouttype.layoutType === "portraitBanner") {
                imagepath = ParseImgURL(
                    props.caroselimg.images.values,
                    "xhdpi",
                    "portraitbanner"
                );
            } else if (props.layouttype.layoutType === "squareBanner") {
                imagepath = ParseImgURL(
                    props.caroselimg.images.values,
                    "xxhdpi",
                    "squarebanner"
                );
            } else {
                imagepath = ParseImgURL(
                    props.caroselimg.images.values,
                    "xhdpi",
                    "coverposter"
                );
            }
            altimage = mobile_bannerimage;
        } else {
            imagepath = ParseImgURL(
                props.caroselimg.images.values,
                "xhdpi",
                "fullwidthbanner"
            );
            altimage = bannerimage;
        }
    }
    var downloadingImg = new Image();
    downloadingImg.src = imagepath;
    downloadingImg.onload = function() {
        if (props.banner) {
            var elems = document.getElementsByClassName(
                `_img_${id}_${props.caroselname}`
            );

            for (var i = 0; i < elems.length; i += 1) {
                elems[i].src = downloadingImg.src;
            }
        } else {
            var image = document.getElementById(
                `_img_${id}_${props.caroselname || ""}`
            );
            if (image !== null) {
                image.src = downloadingImg.src;
            }
        }
    };
    downloadingImg.onerror = function(err) {
        // console.log(err);
    };

    return (
        <div>
            <img
                className={`${imageclass} _img_${id}_${props.caroselname}`}
                id={`_img_${id}_${props.caroselname || ""}`}
                src={altimage}
                alt={props.caroselimg.title}
            />
        </div>
    );
};

export default similarContentImg;
