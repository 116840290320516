import React, { Component } from "react";
import myplexAPI from "../../api/myplexAPI";
import InputBox from "../Uicomponents/checkBox";

class FilterOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLangaugeFilter: false,
      showGenereFilter: false,
      genereInfo: [],
      languageInfo: []
    };
  }
  handleLangaugeFilter = e => {
    this.setState({
      showLangaugeFilter: !this.state.showLangaugeFilter,
      showGenereFilter: false
    });
  };
  handleGenereFilter = e => {
    this.setState({
      showLangaugeFilter: false,
      showGenereFilter: !this.state.showGenereFilter
    });
  };
  componentDidMount() {
    this._getfilteroptions();
  }
  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this._getfilteroptions();
    }
  }
  _getfilteroptions() {
    myplexAPI.getFilterTypes(this.props.type).then(response => {
      myplexAPI
        .getFilterValues(response.data.result.filters[0].url)
        .then(genres => {
          this.setState({
            genereInfo: genres.data.results
          });
        });
      myplexAPI
        .getFilterValues(response.data.result.filters[1].url)
        .then(languages => {
          this.setState({
            languageInfo: languages.data.results
          });
        });
    });
  }
  render() {
    let checked = false;
    const genereInfo = this.state.genereInfo.map((genere, i) => {
      // eslint-disable-next-line
      {
        this.props.Generechecklist.indexOf(genere.title) > -1
          ? (checked = true)
          : (checked = false);
      }

      return (
        <InputBox
          title={genere.title}
          boxname="chkgenere"
          key={i}
          checked={checked}
        />
      );
    });
    const languageInfo = this.state.languageInfo.map((language, i) => {
      // eslint-disable-next-line
      {
        this.props.Languagechecklist.indexOf(language.title) > -1
          ? (checked = true)
          : (checked = false);
      }
      return (
        <InputBox
          title={language.title}
          boxname="chklanguage"
          key={i}
          checked={checked}
        />
      );
    });
    return (
      <div className="form_container clearfix pos-relative inside-page-filters">
        <div className="heading-video heading-video--group">
          <div className="heading-video__title">
            <h2>{decodeURIComponent(this.props.name)}</h2>
          </div>
          <form name="movie_search" method="post" className="movie_search">
            <div>
              <div className="list-filter">
                <ul className="list-inline clearfix">
                  <li className={this.state.showGenereFilter ? "open" : null}>
                    <button
                      type="button"
                      className="genre_filter_btn filter-trigger"
                      onClick={this.handleGenereFilter}
                    >
                      <span data-name="Genres" className="filter-name-inside">
                        Genres
                      </span>
                      <i className="filter-arrow" />
                      <span className="under-filter-text">
                        {this.props.Generechecklist.toString()}
                      </span>
                    </button>
                    {this.state.showGenereFilter && (
                      <div className="dropdown-menu-full">
                        <ul className="list-inline clearfix">{genereInfo}</ul>
                        <div
                          disabled="disabled"
                          className="text-center dropdown-footer"
                        >
                          <button
                            type="button"
                            className="btn btn-default filter-cancel-popup"
                            onClick={() => {
                              this.props.resetFunction("genere");
                              this.handleGenereFilter();
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            data-fname="genre"
                            className="btn btn-red filter-apply"
                            onClick={() => {
                              this.props.filterFunction("genere");
                              this.handleGenereFilter();
                            }}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    )}
                  </li>

                  <li className={this.state.showLangaugeFilter ? "open" : null}>
                    <button
                      type="button"
                      className="genre_filter_btn filter-trigger"
                      onClick={this.handleLangaugeFilter}
                    >
                      <span
                        data-name="Languages"
                        className="filter-name-inside"
                      >
                        Languages
                      </span>
                      <i className="filter-arrow" />
                      <span className="under-filter-text">
                        {this.props.Languagechecklist.toString()}
                      </span>
                    </button>
                    {this.state.showLangaugeFilter && (
                      <div className="dropdown-menu-full">
                        <ul className="list-inline clearfix">{languageInfo}</ul>
                        <div
                          disabled="disabled"
                          className="text-center dropdown-footer"
                        >
                          <button
                            type="button"
                            className="btn btn-default filter-cancel-popup"
                            onClick={() => {
                              this.props.resetFunction("language");
                              this.handleLangaugeFilter();
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            data-fname="language"
                            className="btn btn-red filter-apply"
                            onClick={() => {
                              this.props.filterFunction("language");
                              this.handleLangaugeFilter();
                            }}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default FilterOptions;
