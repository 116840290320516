import React, { Component } from "react";
import myplexAPI from "../../api/myplexAPI";
import {withRouter} from "react-router-dom";
import db from "../../utils/db";

class AppLanguage extends Component {
  state = {
    language_dropdown: false,
    languages: [],
    languageCheck: false,
    LanguagesList: [],

  };
  componentDidMount() {
    this.showLanguagedropdown();
    this.getContentLanguagesList();
  }

  getContentLanguagesList = () => {
    myplexAPI.getAppLanguages().then(res => {
      // console.log(res.data.languages.terms)
      this.setState({
        LanguagesList: res.data.languages.terms
      })
    })

  };
  showLanguagedropdown = () => {
    if (this.state.LanguagesList.length === 0) {
      this.getContentLanguagesList();
    }
    this.setState({
      language_dropdown: !this.state.language_dropdown,
    });
  };


  showLanguagesData = () => this.state.LanguagesList.length > 0 && (
    this.state.LanguagesList.map((lang, key) => {
      let checked = false;
      if (localStorage.getItem("selectedAppLanguageList") === null || localStorage.getItem("selectedAppLanguageList") === '') {
        if (lang.term === 'english') {
          checked = true;
        } else {
          checked = false;
        }
      }
      else {
        if (localStorage.getItem("selectedAppLanguageList") !== null &&
          localStorage.getItem("selectedAppLanguageList").indexOf(lang.term) > -1) {
          checked = true;
        }
      }
      return <div className="language_selection" key={key}>
        <div className="radio">
          <input id={`${lang.term}`} name="languageList" type='radio' value={`${lang.term}`} defaultChecked={checked}  />
          <label className="radio-label" htmlFor={`${lang.term}`} ><div className="applanguage_title">{lang.humanReadable}</div></label>
        </div>
      </div>

    })
  );
  checkAllLanguages = () => {
    this.setState({ languageCheck: !this.state.languageCheck });
  };

  onChangeSelectedLanguages = () => {
    var selectedLanguageList = [];
    var checkboxes = document.querySelectorAll(`input[name=languageList]:checked`);
    for (var i = 0; i < checkboxes.length; i++) {
      selectedLanguageList.push(checkboxes[i].value);
    }
    localStorage.setItem("selectedAppLanguageList", selectedLanguageList);
  };

  closeLanguage = () => {
    this.props.history.go("-1")
  };

  saveLanguages = () => {
    this.onChangeSelectedLanguages();
    db.table("carousels").clear();
    window.location.assign('/')
    // this.props.history.push('/')

  };

  render() {
    return (
      <React.Fragment>
        <div className="footer_details">
          <div className="footer_details_title">

            <h1>Display Language</h1>

          </div>
          {this.showLanguagesData()}
          <div className="lang_btn">
            <button type="button" onClick={() => this.closeLanguage()}>Cancel</button><button type="button" className="btn_save" onClick={() => this.saveLanguages()}>Save</button>
          </div>
        </div>
      </React.Fragment>

    );
  }

}

export default withRouter(AppLanguage);


