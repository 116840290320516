import { isMobile, osName } from "react-device-detect";
export const queryStringParse = (string) => {
  let parsed = {};
  if (string !== "") {
    string = string.substring(string.indexOf("?") + 1);
    let p1 = string.split("&");
    p1.map(function (value) {
      //console.log("value ",value);
      let params = '';
      if(value.indexOf('coupon=') > -1){
        params = value.split("coupon=");
        params[0] = value.split("=")[0];
      }else{
        params = value.split("=");
      }
      //console.log("params : : : ",value.split("coupon="));
      return (parsed[params[0]] = params[1]);
    });
  }
  return parsed;
};

export const parseDate = (string = "") => {
  let d;
  if (string !== "") {
    // d = new Date(string.replace("T", " ").slice(0, -1).replace(/-/g, "/"));
    var parts = string.match(/\d+/g);
    d = new Date(
      parts[0],
      parts[1] - 1,
      parts[2],
      parts[3],
      parts[4],
      parts[5]
    );
  } else {
    d = new Date();
  }

  return d;
};

export const genres = (arr) => {
  var g = arr.map(function (a) {
    return a["name"];
  });
  return g.toString();
};

export const languages = (arr) => (arr.toString());

export const redirectPlayStore = (ID,pageType = null) => {
  //console.log("IDDDDD - ",ID);
	var id = ID;
	var playstoreUrl = '';
	//console.log(osName)
	if (isMobile && osName === 'iOS') {		
		// playstoreUrl = 'https://apps.apple.com/in/app/manoramamax/id1468454034';
    // if(pageType){
    //   window.location.replace("manoramamax://manoramamax.com/"+pageType+"/" + id);
    // }else{
    //   window.location.replace("manoramamax://manoramamax.com/detail/" + id);
    // }
		// setTimeout(function () {
		// 	window.location.replace(playstoreUrl);
		// }, 2000);
	} else if (isMobile && osName === 'Android') {
    if(pageType){
      window.location.replace("manoramamax://manoramamax.com/"+pageType+"/" + id);
    }else{
      window.location.replace("manoramamax://manoramamax.com/detail/" + id);
    }
		
		// setTimeout(function () {
		// 	window.location.replace('https://play.google.com/store/apps/details?id=com.mmtv.manoramamax.android');
		// }, 2000);
	}
};

export const isPremimumUser = () => {
  var userPackagesResponse = '';
  var isNotPremimumUser = true;
  if(sessionStorage.getItem('userfeatures') !== undefined && sessionStorage.getItem('userfeatures') !== null){
    var userPackagesResponse = JSON.parse(sessionStorage.getItem('userfeatures'));
    if(userPackagesResponse && userPackagesResponse.ads !== undefined && userPackagesResponse.ads === false){
      isNotPremimumUser = false;
    }
  }
  return isNotPremimumUser;
};

export const isDemographicPlan = (results,packageName) => {
  let pack = '';
  //console.log("results ",results);
  pack = results &&  results.length > 0 && results.find(
    (requestobj) => requestobj.packName === packageName,
  );
  return pack;
}


