import { isMobile } from "react-device-detect";

if (document.domain.includes("vodafoneplay")) {
  window.FLOW_KEY = "$818790249350242";
} else if (document.domain.includes("ideamoviesandtv")) {
  window.FLOW_KEY = "$985297059385959";
} else if (document.domain.includes("localhost")) {
  window.FLOW_KEY = "$512206430871778";
} else if (document.domain.includes("192.168.10.10")) {
  window.FLOW_KEY = "$512206430871778";
} else if (document.domain.includes("127.0.0.1")) {
  window.FLOW_KEY = "$230995013922566";
} else {
  window.FLOW_KEY = "";
}

if (isMobile) {
  window.nav_menu = process.env.REACT_APP_MOBILE_MENU;
} else {
  window.nav_menu = process.env.REACT_APP_MENU; 
}

window.ENCRYPTION_KEY = "Nzro6xid5NLofGO7";

window.BUGSNAG_CLIENTKEY = "8544cf661ee6deb787552d63f3b2c2b4";

window.BANNERTIME = 6000;
try {
  // eslint-disable-next-line no-unused-vars
  var nVer = navigator.appVersion;
  var nAgt = navigator.userAgent;
  var browserName = navigator.appName;
  var fullVersion = "" + parseFloat(navigator.appVersion);
  var majorVersion = parseInt(navigator.appVersion, 10);
  var nameOffset, verOffset, ix;

  // In Opera, the true version is after "Opera" or after "Version"
  if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf("Version")) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset + 5);
  }
  // In Chrome, the true version is after "Chrome"
  else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In Firefox, the true version is after "Firefox"
  else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 8);
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if (
    (nameOffset = nAgt.lastIndexOf(" ") + 1) <
    (verOffset = nAgt.lastIndexOf("/"))
  ) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(";")) !== -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(" ")) !== -1)
    fullVersion = fullVersion.substring(0, ix);

  majorVersion = parseInt("" + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = "" + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }
  var OSName = "Unknown OS";
  if (navigator.appVersion.indexOf("Win") !== -1) OSName = "Windows";
  if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "MacOS";
  if (navigator.appVersion.indexOf("X11") !== -1) OSName = "UNIX";
  if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "Linux";

  window.browserName = browserName;
  window.fullVersion = fullVersion;
  window.OSName = OSName;
} catch (e) {
  window.browserName = "";
  window.fullVersion = "";
  window.OSName = "";
  console.log("browser error", e);
}
const app = process.env.REACT_APP_CONFIGARATION;
let appLink;
if (app === "vodafone") {
  appLink = "https://vfplay.onelink.me/2852289195/8297bce5";
} else {
  appLink = "https://ideamovieclub.onelink.me/1097180955/af61dfdd";
}
const config = {
  db_expiredTimeInMinutes: 120,
  indexedDB: true,
  metaDomain: true,
  appLink: appLink,
  carouselPartnerLogo: true,
  bannerPartnerLogo: true
};

export default config;
