import React from "react";

const Checkbox = props => {
  //console.log(props.checked);
  return (
    <li>
      <div className="checkbox clip-check">
        <input
          type="checkbox"
          name={props.boxname}
          id={props.title}
          value={props.title}
          defaultChecked={props.checked}
        />
        <label htmlFor={props.title}>{props.title}</label>
      </div>
    </li>
  );
};

export default Checkbox;
