import React, { Component } from "react";
import Slider from "react-slick";


import Img from "../Uicomponents/Img";

import "react-owl-carousel2/src/owl.carousel.css";
import StarRatings from "react-star-ratings";

import "react-owl-carousel2/src/owl.theme.default.css";
import { Link } from "react-router-dom";
import myplexAPI, { URL_CAROUSELCONTENT } from "../../api/myplexAPI";
import "./Carousel.css";
import ParseImgURL from "../../utils/parseImgURL";
//import watchlist_remove_icon from "../../assets/images/watchlist_remove_icon.png";
// import secureImages from "../../utils/secureImages";
import secureImages from "../../../commonfiles/secureImages";

import indexedDB from "../../utils/indexedDB";
// import { slugify } from "../../utils/stringSlugify";
import { slugify } from "../../../commonfiles/stringSlugify";

//import { carouselPatnerLogos } from "../../utils/partnerLogos";
//import LazyLoad from "react-lazyload";
// import { getURLName } from "../../utils/urlMenu";
import { getURLName } from "../../../commonfiles/urlMenu";

import { withRouter } from "react-router";
 import placeholder_vertical from "../../assets/images/videos___shows_placeholder.png";
import { isMobile } from "react-device-detect";
//import viewall_arrow from "../../assets/images/view_all_arrow.png";
import view_all_arrow_mm from "../../assets/images/view_all_arrow_mm.png";
//import carousel_freetag from "../../assets/images/freetag_thumbnail.png";
// import ParseImgURL from "../../utils/parseImgURL";
import sessionFun from "../../../commonfiles/SessionStorage"

 class CenterMode extends Component {
  list = "";
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      caroselimages: [],
      pageName: "home",
      trackingId: "",
    };
    this.carousel_info_name = this.props.caroselinfo.name;
    this._isMounted = false;
    this.auth = localStorage.getItem("Logged-in");
  }
  componentDidMount() {
    this._isMounted = true;

    const page_name = window.location.pathname.split("/")[1];
    if (page_name !== "") {
      this.setState({
        pageName: page_name,
      });
     
    }
    this.loadCarouselsData();
  }
  componentDidUpdate(prevsprops) {
    if (this.props.caroselinfo.name !== prevsprops.caroselinfo.name) {
      this.loadCarouselsData();
    }
  }
  loadCarouselsData() {
         this._getCarouselsData()
  }
  _getCarouselsData = () => {
    const that = this;
    let url = "";
    const carousel_name = this.props.caroselinfo.name;
    url = URL_CAROUSELCONTENT(carousel_name, 1, 10,this.props.caroselinfo.modified_on);
      indexedDB(url).then(function (response) {
        if (response !== false && that._isMounted) {
          that.setState({
            caroselimages: response,
          });
        } else {
          that._fetchCarousels( carousel_name);
        }
      });
    
  };  
  _fetchCarousels = ( carousel_name) => {
    const that = this;
    
    this.list = localStorage.getItem("selectedLanguageList");
    myplexAPI
      .getCarouselData(carousel_name, 1, 10, this.props.caroselinfo.modified_on)
      .then((response) => {
        // console.log(carousel_name, response);
        that._responseData(response);
      });
    }
      
    _responseData = (response, flag = 0) => {
      // console.log(response.data.status)
      if (
        response.data &&
        response.data.status &&
        response.data.status === "SUCCESS" &&
        this._isMounted
      ) {
        this.setState({
          caroselimages: response.data.results,
        });
        if (flag === 0) {
          myplexAPI.storeIndexedDb(
            response.request.responseURL,
            response.data.results
          );
        }
      }
    }
    decodeHTMLEntities = (text) => {
      var entities = {
        amp: "&",
        apos: "'",
        lt: "<",
        gt: ">",
        quot: '"',
        nbsp: "\xa0",
      };
      var entityPattern = /&([a-z]+);/gi;
  
      return text.replace(entityPattern, function (match, entity) {
        entity = entity.toLowerCase();
        if (entities.hasOwnProperty(entity)) {
          return entities[entity];
        }
  
        // return original string if there is no matching entity (no replace)
        return match;
      });
    }
    carouselClick = (URL, type, title, images, description,i,id) => {
      var src;
      if (isMobile) {
        src = ParseImgURL(images.values, "mdpi", "coverposter"); //images.values[0].link;
      } else {
        src = ParseImgURL(images.values, "xxhdpi", "coverposter");
      }
      localStorage.setItem("actorImage", src);
      localStorage.setItem("actorType", type);
      localStorage.setItem("personDecription", description);
      localStorage.setItem("source details", this.props.caroselinfo.title);
    localStorage.setItem("source", "carousel");
    sessionFun(this.props.carousel_count,i+1,this.state.pageName,'singlBanner',this.props.caroselinfo.title,id);
      if (this.auth) {
        if (type !== "person" && type !== "actor") {
          this.props.history.push(URL);
        } else {
          this.props.history.push(`/Actorprofile/${title}`);
        }
      } else {
        if (type !== "person" && type !== "actor") {
          localStorage.setItem("redirectUrl", URL);
          this.props.history.push(URL);
  
          // this.props.history.push("/login");
        } else {
          this.props.history.push(`/Actorprofile/${title}`);
        }
      }
    }
    viewall = () => {
      localStorage.setItem("texture", JSON.stringify(this.props.texture));
    }
    caroselimages = () => {
      return this.props.layoutType !== "nestedCarousel"
        ? this.state.caroselimages.map((caroselimg, i) => {

          // sessionFun(this.props.carousel_count,i+1,this.state.pageName,'carosuel',this.props.caroselinfo.title,caroselimg.generalInfo._id);
          
          return (
           
            <div
              className="center_caousel_item"
              onClick={() =>
                this.carouselClick(
                  `/${getURLName(caroselimg, this.state.pageName)}/detail/${caroselimg.generalInfo._id
                  }/${slugify(caroselimg.generalInfo.title)}`,
                  caroselimg.generalInfo.type,
                  caroselimg.generalInfo.title,
                  caroselimg.images,
                  caroselimg.generalInfo.description,i,caroselimg.generalInfo._id
                )
              }
              key={i}
            >
              {/* {!caroselimg.generalInfo.isSellable &&
                caroselimg.generalInfo.contentRights &&
                caroselimg.generalInfo.contentRights[0] === "avod" && (
                  <div className="carousel_freetag">
                    <img src={carousel_freetag} alt="carousel_freetag" />
                  </div>
                )} */}
                <h3>
              <Img
                caroselimg={caroselimg}
                key={i}
                banner={false}
                layouttype={this.props.layoutType}
                caroselname={this.props.caroselinfo.name}
                observer={true}
              /></h3>
              {this.props.texture !== null && this.props.texture.length > 0 && (
                <div className="carousel_titles">
                  {this.props.texture.find(
                    (obj) => obj.metadata === "title"
                  ) !== null &&
                    this.props.texture.find((obj) => obj.metadata === "title") !==
                    undefined ? (
                    <h1 className="movie_title">
                      {caroselimg.generalInfo.title}
                    </h1>
                  ) : (
                    null
                    // <h1></h1>
                  )}
  
                  <div className="movie_details-div">
                    <ul>
                      {this.props.texture.find(
                        (obj) => obj.metadata === "language"
                      ) !== null &&
                        this.props.texture.find(
                          (obj) => obj.metadata === "language"
                        ) !== undefined &&
                        caroselimg.content.language.length > 0 ? (
                        <li>{caroselimg.content.language[0]}</li>
                      ) : (
                        ""
                      )}
                      {this.props.texture.find(
                        (obj) => obj.metadata === "genre"
                      ) !== null &&
                        this.props.texture.find(
                          (obj) => obj.metadata === "genre"
                        ) !== undefined &&
                        caroselimg.content.genre.length > 0 ? (
                        <li>{caroselimg.content.genre[0].name}</li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                  {this.props.texture.find(
                    (obj) => obj.metadata === "rating"
                  ) !== null &&
                    this.props.texture.find(
                      (obj) => obj.metadata === "rating"
                    ) !== undefined ? (
                    <div className="app_rating">
                      {parseInt(caroselimg.content.contentRating) > 0 ? (
                        <StarRatings
                          rating={parseInt(caroselimg.content.contentRating)}
                          starRatedColor="#ffcc33"
                          // changeRating={this.changeRating}
                          numberOfStars={5}
                          name="star-container"
                          className="rated"
                        />
                      ) : (
                        <ul id="star-rating">
                          <li className="rated"></li>
                          <li className="rated"></li>
                          <li className="rated"></li>
                          <li className="rated"></li>
                          <li></li>
                        </ul>
                      )}
                    </div>
                  ) : (
                    // <StarRatings
                    //     rating={this.state.ratingValue}
                    //     starRatedColor="#f7b15b"
                    //     // changeRating={this.changeRating}
                    //     numberOfStars={5}
                    //     name='star-container'
                    //     className = "rated"
                    //     />
                    <div className="app_rating"></div>
                  )}
                  {/* { this.props.texture.find(obj => obj.metadata === "price") !== null && this.props.texture.find(obj => obj.metadata === "price") !== undefined ? <h1 className="payment_amount rupee">{bannerimg.packages.length && bannerimg.packages[0].priceDetails.length > 0  ? <span className="rupee_symbol">₹ {bannerimg.packages[0].priceDetails[0].price}</span> : ''}</h1>:null} */}
                  {caroselimg.generalInfo.isSellable &&
                    this.props.texture.find(
                      (obj) => obj.metadata === "price"
                    ) !== null &&
                    this.props.texture.find(
                      (obj) => obj.metadata === "price"
                    ) !== undefined && (
                      <h1 className="payment_amount rupee">
                        {caroselimg.packages.length > 0 &&
                          caroselimg.packages[0].priceDetails.length > 0 ? (
                          <span className="rupee_symbol">
                            ₹ {caroselimg.packages[0].priceDetails[0].price}
                          </span>
                        ) : (
                          ""
                        )}
                      </h1>
                    )}
                  {!caroselimg.generalInfo.isSellable &&
                    caroselimg.generalInfo.contentRights &&
                    caroselimg.generalInfo.contentRights[0] === "avod" &&
                    this.props.texture.find(
                      (obj) => obj.metadata === "price"
                    ) !== null &&
                    this.props.texture.find(
                      (obj) => obj.metadata === "price"
                    ) !== undefined && (
                      <h1 className="payment_amount rupee">
                        <span className="rupee_symbol">Free</span>
                      </h1>
                    )}
                  {/* { caroselimg.generalInfo.isSellable && this.props.texture.find(obj => obj.metadata === "price") !== null && this.props.texture.find(obj => obj.metadata === "price") !== undefined ?  <h1 className="payment_amount rupee">{caroselimg.packages.length > 0 && caroselimg.packages[0].priceDetails.length > 0 ?<span className="rupee_symbol">₹ {caroselimg.packages[0].priceDetails[0].price}</span> : ''}</h1> : <h1 className="payment_amount rupee"><span className="rupee_symbol">Free</span></h1>} */}
                </div>
              )}
  
              {/* caroselimg.generalInfo.contentRights &&
          caroselimg.generalInfo.contentRights[0] === 'avod' && */}
              {/* <div className="remove_movie">
                    <img alt="removeimg" src={watchlist_remove_icon} />
                  </div> */}
              {/* </div> */}
              {/*{console.log(partnerLogos.link)}*/}
              {/* {this.props.layoutType === "liveProgramItem" && (
                  <div className="icon-caption">
                    <img
                      src={this.getThumbnailLiveTv(caroselimg)}
                      alt={caroselimg.globalServiceName}
                      className="icon-caption-image"
                    />
  
                    <div className="icon-caption-text">
                      <h2 className="text-one-line">
                        {caroselimg.globalServiceName}
                      </h2>
                      <span> {caroselimg.generalInfo.title}</span>
                    </div>
                  </div>
                )} */}
              {/* {this.props.caroselinfo.showTitle && (
                  <div className="icon-caption-text">
                    <h2 className="text-one-line">
                      
                    </h2>
                  </div>
                )} */}
              {/* {logo} */}
              {/* </Link> */}
            </div>
          );
        })
        : this.state.caroselimages.map((caroselimg, i) => {
          // console.log(caroselimg.title);
          // console.log(caroselimg);
  
          // console.log(caroselimg.images.values[1].link);
          var neimg =
            caroselimg.images &&
              caroselimg.images[1] &&
              caroselimg.images[1].link
              ? caroselimg.images[1].link
              : placeholder_vertical;
          return (
            <Link
              to={{
                pathname: `/${this.state.pageName}/filter`,
                search: this.checkFilterValues(caroselimg.title),
              }}
              key={i}
            >
              <img
                src={secureImages(neimg)}
                alt={caroselimg.title}
                className="genres_languages"
              />
            </Link>
          );
        });
    }
    checkFilterValues = (title) => {
      return this.carousel_info_name === "Portal_popular_genres"
        ? `?type=movie&genres=${title}`
        : `?type=movie&filtervalue=${title}`;
    };
        



  render() {
    let title = this.props.caroselinfo.title;
    if (this.props.caroselinfo.altTitle) {
      title = this.props.caroselinfo.altTitle;
    }
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "30px",
        slidesToShow: 1,
        speed: 500,
        arrows: false,
        autoplay: false,
      };

    return (
    <div className="layout_center_carusel">
        <div className="carousel_body">
      
        <div className="home_carosel_title">
        {this.state.caroselimages.length > 0 && 
          <Link
          to={{
            pathname: `/${this.state.pageName}/inside/${this.props.caroselinfo.name}`,
            search: `?name=${this.props.caroselinfo.title.replace(
              / /g,
              "-"
            )}&layoutType=${this.props.layoutType}&title=${title}`,
          }}
        ><h1 style={{ cursor: "default" , color:this.props.caroselinfo.bgSectionColor }}>{this.decodeHTMLEntities(title)}</h1></Link>}
         {this.state.caroselimages.length > 0 && 
          <Link
          to={{
            pathname: `/${this.state.pageName}/inside/${this.props.caroselinfo.name}`,
            search: `?name=${this.props.caroselinfo.title.replace(
              / /g,
              "-"
            )}&layoutType=${this.props.layoutType}&title=${title}`,
          }}>
          <img src={view_all_arrow_mm} className="view_all_arrow" alt="viewall_arrow_img" /></Link>}
        </div>
     
        <Slider {...settings}>

        {this.caroselimages()}
          {/* <div className="center_caousel_item">
            <h3><img src="https://d25tmfuvd960zw.cloudfront.net/122580/640x360_nonDRMMpsContent30Apr_122580_97498266-b7d1-4f4e-822c-fdbd40953909.jpeg" /></h3>
          </div>

          <div className="center_caousel_item">
            <h3><img src="https://d25tmfuvd960zw.cloudfront.net/122582/640x360_nonDRMYouTube30Apr_122582_fda2f05c-fb15-48c7-8a6b-2335d54e7201.jpg" /></h3>
          </div>

          <div className="center_caousel_item">
            <h3><img src="https://d25tmfuvd960zw.cloudfront.net/122581/640x360_nonDRMFB30Apr_122581_ccd8a22b-fdef-42fb-870b-504dc515ffcb.jpeg" /></h3>
          </div>

          <div className="center_caousel_item">
            <h3><img src="https://d25tmfuvd960zw.cloudfront.net/122545/640x360_nonDRMFB27Apr_122545_d6bc42c7-e150-4669-916a-16a30c975066.jpeg" /></h3>
          </div>

          <div className="center_caousel_item">
            <h3><img src="https://d25tmfuvd960zw.cloudfront.net/122547/640x360_nonDRMFByoutube27Apr_122547_747ae810-6798-48d1-baf4-96a5a1eba16c.jpg" /></h3>
          </div>

          <div className="center_caousel_item">
            <h3><img src="https://d25tmfuvd960zw.cloudfront.net/122546/640x360_nonDRMYouTube27Apr_122546_d82db696-52cd-4a27-9a80-d3be5d5faf26.jpeg" /></h3>
          </div> */}

        </Slider>
      </div>
      </div>
    );
  }
}
export default withRouter(CenterMode)