import React, { Component } from 'react';
// import { isFirefox} from "react-device-detect";
import myplexAPI, { URL_LICENCE } from "../../api/myplexAPI";
// import secureImages from "../../utils/secureImages";
import secureImages from "../../../commonfiles/secureImages";

// import {decryption, encryption} from "../../utils/encryption";
import { decryption, encryption } from "../../../commonfiles/encryption";

// import {PGLOBALS} from "../../utils/portalGlobals";
import { PGLOBALS } from "../../../commonfiles/portalGlobals";
import { cleverTapData } from "./Playerevents";
// import {getURLName} from "../../utils/urlMenu";
import { getURLName } from "../../../commonfiles/urlMenu";

// import {slugify} from "../../utils/stringSlugify";
import { slugify } from "../../../commonfiles/stringSlugify";

import ParseImgURL from "../../utils/parseImgURL";
import Observer from "@researchgate/react-intersection-observer";
// import {clock} from "../../utils/clock";
import { clock } from "../../../commonfiles/clock";

// import cleverTap from "../../common/clevertap";
// import {Fingerprint} from "../../utils/fingerprint";
// import {Fingerprint} from "../../../commonfiles/fingerprint";

import { withRouter } from "react-router-dom";
//import './video-js.min.css';
import bannerimage from "../../assets/images/banner_placeholder.jpg";
import G1popup from "../Uicomponents/G1popup";

class VideoJsPlayer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            videoInfo: [],
            poster: bannerimage,
            video_link: null,
            ui: '',
            showPopUp: false
        };
        this.scripts = [

            // "/videojs/video--7.10.2.min.js",
            "/videojs/video_7.20.2.min.js",
            "//imasdk.googleapis.com/js/sdkloader/ima3.js",
            "//imasdk.googleapis.com/pal/sdkloader/pal.js",
            "/videojs/can-autoplay.min.js",
            "/videojs/videojs-contrib-ads.min.js",
            "/videojs/videojs-contrib-eme.min.js",
            "/videojs/videojs-contrib-eme.new.min.js",
            //"/videojs/videojs-contrib-quality-levels.js",
            "/videojs/" + process.env.REACT_APP_PLAYERLICENCE_FILE,
            //"/videojs/nuevo.min.js",
            "/videojs/videojs.ima.min.js",
            //"/videojs/videojs-http-source-selector.js",
            "/videojs/current.js",
            // "/videojs/videojs-seek-buttons.min.js",
            "/videojs/videojs.thumbnails.min.js",
            //"/videojs/videojs.hotkeys.min.js"
            "/videojs/videojs.hotkeys.min.js"
        ];
    }
    componentDidMount() {
        const _self = this;
        // Fingerprint().then(function (value) {
        if (window.videojs) {
            // console.log('going')
            _self.loadVideoInfo();
            _self.flowPlayerLink();
        } else {
            // console.log('going')
            _self._loadScripts();
        }
        // });
    }
    componentWillUnmount() {
        var container = document.getElementById(this.props.caroselinfo.name);
        if (container !== null) {
            if (window.videojs(container)) {
                window.videojs(container).dispose()
            }
        }
    }
    _loadScripts = () => {
        if (this.scripts.length > 0) {
            const scriptSrc = this.scripts.shift();
            const elem = document.createElement("script");
            elem.src = scriptSrc;
            elem.onload = e => {
                this._loadScripts();
            };
            elem.onerror = () => {
                this._loadScripts();
            };

            return document.head.appendChild(elem);
        }
        this.loadVideoInfo();
        this.flowPlayerLink();
    };
    flowPlayerLink = () => {
        const _self = this;
        const video_ready_state = setInterval(() => {
            const api = document.getElementById(`${this.props.caroselinfo.name}_html5_api`);
            if (api !== null) {
                if (api.readyState >= 3) {
                    var reactFlowPlayer = document.getElementById(`${this.props.caroselinfo.name}`);
                    // debugger;
                    if (reactFlowPlayer !== null) {
                        reactFlowPlayer.onclick = element => {
                            // debugger;
                            // console.log(element.target.classList.contains("vjs-user-active"))
                            if (element.target.classList.contains("vjs-user-active")) {
                                localStorage.setItem("source", "carousel");
                                localStorage.setItem("source details", "autoPlay banner");
                                // api.src = "";
                                _self.props.history.push(_self.state.video_link)
                            }
                        };
                    }
                    clearInterval(video_ready_state)
                }
            }
        }, 1000);
    };
    loadVideoInfo = () => {
        let name = this.props.caroselinfo.name;
        // console.log(name)
        // if(name === 'Autoplay_liongate'){
        //     name = 'Auroplay_ABPMajha_vpwa'
        // }
        myplexAPI.getCarouselData(name, 1, 10).then(response => {
            //  console.log(response);
            if (response.data.status === "SUCCESS") {
                const _self = this;
                // console.log(response.data.results[0].generalInfo.title);
                if (response.data.results.length > 0) {
                    let promise = new Promise(function (resolve, reject) {
                        resolve(_self._getVideoURL(response.data.results[0]._id));
                    });
                    // console.log(response.data.results[0])
                    const image = ParseImgURL(response.data.results[0].images.values, "mdpi", "coverposter");
                    // console.log(image)
                    promise.then(function (res) {
                        _self.setState({
                            videoInfo: response.data.results,
                            poster: secureImages(image)
                        });
                    })
                }
            }
        });
    };
    closePopUp = () => {
        // console.log('working');
        this.setState({
            ui: '',
            showPopUp: false
        })
    };
    _getVideoURL(Id) {
        // Id = 210461;
        var mediaURL = `content/v3/media/${Id
            }?payload=${encryption(
                JSON.stringify({

                    network: "WIFI",
                    fields: "videos,videoInfo,images,contents,generalInfo,subtitles,thumbnailSeekPreview,skipConfig",
                    mcc: "404",
                    postalCode: "500033",
                    consumptionType: "stream",
                    mnc: "13",
                    //ucv: '2'
                    "x-myplex-tenant-id": process.env.REACT_APP_TENANT
                }),
                window.ENCRYPTION_KEY
            ).replace(/\+/g, "%2b")}
      &version=1`;
        //&x-myplex-tenant-id=f10c4847-86df-494c-a39f-0cc1df6feea7`;
        myplexAPI.getMediaAPi(mediaURL).then(response => {
            response.data = JSON.parse(
                decryption(response.data.response, window.ENCRYPTION_KEY)
            );
            // console.log(response.data)
            // console.log(response.data.results[0].generalInfo.title)
            if (response.data.status === "SUCCESS") {
                if (response.data.results.length > 0) {
                    if (response.data.results[0].videos.status === "SUCCESS" && response.data.results[0].videos.values) {
                        const items = [];
                        this.setState({
                            mediaUrlLink: response.data.results[0].videos.values[0].link
                        });
                        response.data.results[0].videos.values.forEach(item => {
                            if (item.type === "streaming" && item.format === "hls") {
                                items.push({
                                    src: item.link,
                                    format: item.format ? item.format : "",
                                    type: PGLOBALS.VIDEO_FORMATS[[item.format]],
                                    label: item.profile,
                                    res: item.resolution,
                                    results: item,
                                    licenseUrl: item.licenseUrl
                                });
                            }
                        });
                        this.videoPlayer(
                            items[0].type,
                            items[0].src,
                            response.data.results[0].images.values,
                            response.data.results[0],
                            items[0].licenseUrl
                        );
                        const data = response.data.results[0];
                        const auth = window.auth;
                        const video_info = items[0].results;
                        // const clever_tap_data = cleverTapData(data, auth, video_info);
                        const link = `${getURLName(data, this.state.pageName)}/detail/${data.generalInfo._id}/${slugify(data.generalInfo.title)}`;
                        // clever_tap_data["source"] = "carousel";
                        // clever_tap_data["consumption type"] = "autoplay";
                        // clever_tap_data["source details"] = "autoPlay Banner";
                        this.setState({
                            // clever_tap_data: clever_tap_data,
                            video_link: link
                        });
                        return true
                    }
                } else if (response.data.ui) {
                    myplexAPI.getContentData(Id).then(res => {
                        if (response.data.ui.action === "popup") {
                            try {
                                const data = res.data.results[0];
                                const auth = window.auth;
                                const video_info = {
                                    src: null,
                                    format: null,
                                    type: null,
                                    label: null,
                                    res: null,
                                    results: null,
                                    licenseUrl: null
                                };
                                // const clever_tap_data = cleverTapData(data, auth, video_info);
                                // cleverTap.videoDetailsViewed(clever_tap_data);
                            } catch (err) {
                                console.error(err);
                            }
                            this.setState({
                                ui: response.data.ui,
                                showPopUp: true
                            })
                        }
                    })
                }
            } else if (response.data.code === 209) {
                this.props.history.push('/redirect/vf')
                return true
            }
        })

    }
    videoPlayer = (type, URL, Imgarr, results, licenseUrl) => {
        // console.log("ppp",results);
        var container = document.getElementById(this.props.caroselinfo.name);
        if (container !== null) {
            URL = URL.replace(/^http:\/\//i, "https://");
            URL = URL.replace("li-jit-cdn-lb.lionsgateplay.com", "li-jit-cdn-lb-ssl.akamaized.net");
            const video_type = results.generalInfo.type;
            const content_id = results._id;

            if (video_type === "live" || video_type === "program") {
                // console.log(video_type, type, URL);
                const video_player = document.getElementById(this.props.caroselinfo.name);
                var cid = results && results._id;
                var title = results && results.generalInfo && results.generalInfo.title;
                let contentGenre = results.content.genre
                    .map((gen, i) => {
                        return gen.name;
                    })
                    .toString();
                var contentMetadata = {
                    ns_st_ci: cid,
                    ns_st_pr: title,
                    ns_st_ep: title,
                    ns_st_en: 0,
                    ns_st_ge: contentGenre,
                    ns_st_st: "ManoramaMax",
                    ns_st_pu: "ManoramaMax",
                    c4: "*null",
                    c2: 7947673,
                    ns_st_ddt: "*null",
                    ns_st_tdt: "*null",
                    // ns_st_st : null,
                    c3: 4,
                    c6: "*null",
                    ns_st_ft: "*null",
                };
                for (var labelName in contentMetadata) {
                    if (contentMetadata.hasOwnProperty(labelName)) {
                        video_player.dataset[labelName] = contentMetadata[labelName];
                        // console.log(video_player.dataset);
                    }
                }
                var comscorePlugin;
                // console.log(video_player.ComScore)
                // console.log(player.ComScore)
                if (video_player.ComScore) {
                    // console.log("video js comscore loaded");
                    // console.log('video js comscore loaded')
                    comscorePlugin = video_player.ComScore({ publisherId: "7947673" });
                    // console.log(results);
                }
                // console.log(type, URL);
                let nonceLoader;
                let managerPromise;
                nonceLoader = new window.goog.pal.NonceLoader();
                const request = new window.goog.pal.NonceRequest();
                request.adWillAutoPlay = true;
                request.adWillPlayMuted = true;
                request.continuousPlayback = false;
                request.descriptionUrl = 'https://example.com';
                request.iconsSupported = true;
                request.playerType = 'Sample Player Type';
                request.playerVersion = '1.0';
                request.ppid = 'Sample PPID';
                request.videoHeight = 480;
                request.videoWidth = 640;

                managerPromise = nonceLoader.loadNonceManager(request);
                managerPromise
                    .then((manager) => {
                        window.videojs(video_player, {
                            sources: [{
                                type: type,
                                src: `${URL}&ads.paln=${manager.getNonce()}`,
                            }],
                            autoplay: true,
                            liveui: true,
                            muted: true,
                            poster: ParseImgURL(Imgarr, "mdpi", "coverposter")
                        });
                    })
                    .catch((error) => {
                        window.videojs(video_player, {
                            sources: [{
                                type: type,
                                src: URL,
                            }],
                            autoplay: true,
                            liveui: true,
                            muted: true,
                            poster: ParseImgURL(Imgarr, "mdpi", "coverposter")
                        });
                    });


            } else {
                var license;

                // const publishing_house_id = results.publishingHouse.publishingHouseId;
                // if (this.publishing_house_ids.indexOf(publishing_house_id) !== -1) {
                //     license = licenseUrl;
                // } else {
                //     license = URL_LICENCE(content_id);
                // }
                license = licenseUrl;
                const video_player = document.getElementById(this.props.caroselinfo.name);
                // console.log(video_type, type, URL);
                // console.log(license);
                // console.log(licenseUrl);

                const options = {
                    crossorigin: 'anonymous',
                    cors: 'anonymous',
                    autoplay: true,
                    preload: "auto",
                    flash: {
                        hls: {
                            withCredentials: true
                        }
                    },
                };
                var player = window.videojs(video_player, options);
                // console.log("bb",results.content.adConfig.vast.web);
                if (results.content.adEnabled) {
                    var mainAdUrl = results.content && results.content.adConfig && results.content.adConfig.vast && results.content.adConfig.vast.web;
                    var ad_options = {
                        id: this.props.caroselinfo.name,
                        adTagUrl: mainAdUrl,
                    };
                    if (window.google && window.google.ima) {

                        window.google.ima.settings.setDisableCustomPlaybackForIOS10Plus(
                            true
                        );
                        player.ima(ad_options);
                    }

                }

                player.eme();

                player.ready(function () {
                    player.src({
                        src: URL,
                        type: type,

                        keySystems: {
                            'com.widevine.alpha': {
                                url: license
                            },
                            // 'com.microsoft.playready': {
                            //     serverURL: URL_LICENCEDRM(content_id)
                            // }
                        },
                        // poster: ParseImgURL(Imgarr, "mdpi", "coverposter")
                    });
                    player.muted(true);
                    player.on('error', function () {
                        player.error(null);
                    });
                    player.poster(ParseImgURL(Imgarr, "mdpi", "coverposter"));
                    player.play();
                });
                // player.responsive(true);
            }
        }
    };
    handleIntersection = e => {
        //  console.log(e);
        var video = document.getElementById(`${this.props.caroselinfo.name}_html5_api`);
        // debugger;
        // console.log(e.target.getAttribute("data-processed"), !e.isIntersecting)
        if (e.target.getAttribute("data-processed") || !e.isIntersecting) {
            // debugger
            if (video !== null) {
                video.pause();
                this.videoStoppedEvent();
            }

        } else {
            if (video !== null) {
                var b = setInterval(() => {
                    if (video.readyState >= 3) {
                        video.play();
                        clearInterval(b)
                    }
                }, 1000);
                // video.play();
                this.videoPlayedEvent()
            }
        }
    };
    videoStoppedEvent = () => {
        const video = document.getElementById(`${this.props.caroselinfo.name}_html5_api`);
        if (video !== null) {
            if (video.readyState === 4) {
                const video_duration = video.duration;
                const video_currentTime = video.currentTime;
                if (video_currentTime !== undefined) {
                    const completed = Math.round((video_currentTime / video_duration) * 100);
                    let cleverTap_data = { ...this.state.clever_tap_data };
                    let duration;
                    duration = clock.totalSeconds;
                    // console.log('duration',duration)
                    if (duration !== undefined) {
                        // if (cleverTap_data["category"] !== 'live') {
                        //     cleverTap_data["% completed"] = completed;
                        // }

                        // cleverTap_data["duration viewed"] = duration;
                        // cleverTap_data["duration"] = Math.round(video_duration);
                        // cleverTap.videoStoped(cleverTap_data);
                        const contentId = cleverTap_data["content id"];
                        const timestamp = Math.floor(Date.now());
                        let mediaSessionToken = "";
                        // if (data.mediaSessionToken) {
                        //     mediaSessionToken = data.mediaSessionToken;
                        // }
                        let tab = "home";
                        let cTitle = "Auto play banner"
                        let source = "carousel"
                        clock.destroy();
                        myplexAPI.updatePlayerMou(contentId, duration, timestamp, mediaSessionToken,
                            tab, cTitle, source
                        ).then(function (res) {
                            // console.log(res)
                        });
                    }
                }
            }
        }
    };
    videoPlayedEvent = () => {
        const video = document.getElementById(`${this.props.caroselinfo.name}_html5_api`);
        // let cleverTap_data = { ...this.state.clever_tap_data };
        if (video !== null) {
            var b = setInterval(() => {
                if (video.readyState >= 3) {
                    ;
                    if (video.duration !== null) {
                        // console.log(cleverTap_data)
                        // cleverTap_data["duration"] = Math.round(video.duration);
                        // cleverTap.videoPlayed(cleverTap_data);
                        clearInterval(b);
                        // debugger;
                        clock.destroy();
                        clock.clear();
                        clock.start();
                    }
                }
            }, 1000);
        }
    };
    render() {
        const options = {
            onChange: this.handleIntersection,
            rootMargin: "0% 0% -25%"
        };
        return (
            <div className="singlebannerlivevideo">
                {/* G1 & G2 upgrade */}
                {this.state.ui && this.state.ui.action === "popup" &&
                    <G1popup closePopUp={this.closePopUp} showPopUp={this.state.showPopUp} ui={this.state.ui} />}
                {/* G1 & G2 upgrade */}
                <div className="promo_video" id={`player_${this.props.caroselinfo.name}`}>
                    <h1>{this.props.caroselinfo.title}</h1>
                    {(this.state.videoInfo.length > 0 && this.state.mediaUrlLink) ?
                        <Observer {...options}>
                            <video id={this.props.caroselinfo.name} autoPlay data-setup='{"fluid": true}' muted={true} className="video-js vjs-default-skin vjs-big-play-centered" controls />
                        </Observer>
                        :
                        <img src={this.state.poster} className="promo_video_coverposter" alt="promo_video_coverposter" />
                    }
                </div>
            </div>
        )
    }
}


export default withRouter(VideoJsPlayer);
