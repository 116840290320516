import React,{ Component } from 'react';
import { Modal } from "react-bootstrap";
// import cleverTabEvents from "../../common/clevertap";

class G1popup extends Component{
    constructor(props) {
        super(props);
        this.state = {
            showPopUp: false,
            // cleverTapData: null
        }
    }
    componentDidMount(): void {
        // console.log(this.props)
        if(this.props.showPopUp){
            const label = this.props.ui.label;
            const data = {'action':'popup displayed',
                'label': label ? label : null,
                'circle': localStorage.getItem('circle_id')
            };
            this.setState({
                showPopUp: true,
                // cleverTapData: data
            });
            // cleverTabEvents.popUp(data);
        }
    }


    closePopUp = () => {
      this.setState({
          showPopUp: false
      });
        try {
            // let close_popUp = {...this.state.cleverTapData};
            // close_popUp['action'] = 'popup closed'
            // cleverTabEvents.popUp(close_popUp);
        }catch (e) {
            console.log(e);
        }
        this.props.closePopUp();
    };
    buttonAction = (url) =>{
        // let close_popUp = {...this.state.cleverTapData};
        // close_popUp['action'] = 'button clicked';
        // cleverTabEvents.popUp(close_popUp);
        window.location.assign(url);
    };
    render() {
        let Img_url = '';
        if(this.props.ui.imgUrl){
            Img_url = this.props.ui.imgUrl;
        }
        return (
            <div className="add_modal_section">
                <Modal show={this.state.showPopUp} onHide={this.closePopUp}>
                <Modal.Header className="add_modal_header" closeButton>
                    {this.props.ui.title &&
                        <h5>{this.props.ui.title}</h5> }
                </Modal.Header>

                        <Modal.Body className="add_modal">
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            {Img_url !== '' && <img src={Img_url} alt="image" /> }
                          <p>{this.props.ui.message}</p>
                          <div className="select_add_modal">
                              <button type="button" onClick={() => this.buttonAction(this.props.ui.button1.url)} className="add_modal_btn">{this.props.ui.button1.displayName}</button>
                          </div>
                        </Modal.Body>

                </Modal>
            </div>
        )
    }
}

export default G1popup;