import React from "react";
import ParseImgURL from "../../utils/parseImgURL";
//import placeholder_horizontal from "../../assets/images/movie_thumbnail_placeholder.png";
import placeholder_horizontal from "../../assets/images/movie_thumbnail_placeholder_main2.png";
import placeholder_vertical from "../../assets/images/videos___shows_placeholder.png";
import bannerimage from "../../assets/images/banner_placeholder.jpg";
import mobile_bannerimage from "../../assets/images/landscape_thumbnail_placeholder_720x404.jpg";
// import preview_placeholder from "../../assets/images/preview_placeholder.jpg";
import squarebanner from "../../assets/images/placeholder_image_square_375.jpg";



import "intersection-observer"; // optional polyfill
import Observer from "@researchgate/react-intersection-observer";
import { isMobile } from "react-device-detect";
//import LazyLoad, { forceCheck } from "react-lazyload";
const errorimg = (e, altimage,bannerclassname="") => {
  
 if(bannerclassname !=="" ) {
    var imgs = document.getElementsByClassName(bannerclassname);
      for (var i = 0; i < imgs.length; i++) {
        imgs[i].src = altimage;
      }
 }else {
    e.target.src = altimage;
 }
 

  
};
const handleIntersection = e => {
  if (e.target.getAttribute("data-processed") || !e.isIntersecting) return true;
  if (e.target.getAttribute("data-src") !== null) {
    e.target.setAttribute("src", e.target.getAttribute("data-src"));
  }
  e.target.setAttribute("data-processed", true);
};
const options = {
  onChange: handleIntersection,
  rootMargin: "0% 0% -25%"
};
const Img = props => {
  
  // console.log(isMobile);
  var id = props.caroselimg.generalInfo._id;
  var imagepath;
  var imageclass;
  var profile;
  var type;
  var altimage;

  if (!props.banner) {
    
    if (props.layouttype === "horizontalListBigItem") { 
    
      imageclass = "horizontalimage";
      profile = "mdpi";
      //type = "portraitcoverposter";
      type = "thumbnail";
      altimage = placeholder_horizontal;
    }
     else if(props.layouttype === "weeklyTrendingBigItem"){
      imageclass = "bigverticalimage";
      profile = "xxhdpi";
      type = "coverposter";
      altimage = bannerimage;

    }
    else if(props.layouttype === "previewCarousel"){
      imageclass = "previewCarousel";
      profile = "xhdpi";
      type = "squarebanner";
      altimage = squarebanner;
    }
     else if(props.layouttype === "roundedArtistCarousel"){
      imageclass = "horizontalimage";
      profile = "mdpi";
      type = "thumbnail";
      altimage = placeholder_horizontal;
    } 
    else if(props.layouttype === "weeklyTrendingMediumItem" || props.layouttype === "weeklyTrending3DListBigItem"){
      imageclass = "bigverticalimage";
      profile = "mdpi";
      type = "coverposter";
      altimage = bannerimage;
    } 
    else if(props.layouttype === "newsNestedLayout"){
      imageclass = "newsNestedLayout";
      profile = "xxhdpi";
      type = "newsnestedlayout";
      altimage = bannerimage;
    } 
    else {
      imageclass = "verticalimage";
      profile = "mdpi";
      type = "coverposter";
      altimage = placeholder_vertical;
    }
    imagepath = ParseImgURL(props.caroselimg.images.values, profile, type);
    
  } else {
    // console.log(props.layouttype.layoutType, 'else')
    imageclass = "bannerimage";
    if (isMobile) {
      if (props.layouttype.layoutType === "portraitBanner") {
        imagepath = ParseImgURL(
          props.caroselimg.images.values,
          "mdpi",
          "thumbnail"
        );
        altimage = placeholder_horizontal;
      } else if (props.layouttype.layoutType === "squareBanner") {
        imagepath = ParseImgURL(
          props.caroselimg.images.values,
          "hdpi",
          "squarebanner"
        );
        altimage = squarebanner;
      }else if (props.layouttype.layoutType === "singleBannerItem" || props.layouttype.layoutType === "autoPlaySingleBanner") {
        imagepath = ParseImgURL(
          props.caroselimg.images.values,
          "hdpi",
          "coverposter"
        );
        altimage = bannerimage;
        // console.log(altimage)
      } 
       else if (props.layouttype.layoutType === "horizontal3DListBigItem") {
        imagepath = ParseImgURL(
          props.caroselimg.images.values,
          "mdpi",
          "thumbnail"
        );
        altimage = mobile_bannerimage;
      }
      else {
        imagepath = ParseImgURL(
          props.caroselimg.images.values,
          "xhdpi",
          "coverposter"
        );
        altimage = mobile_bannerimage;
      }
      // console.log(imagepath);
      // console.log(altimage);
     
    } else {
      imagepath = ParseImgURL(
        props.caroselimg.images.values,
        "hdpi",
        "coverposter"
      );
      altimage = bannerimage;
     
    }
    
  }


  if (
    !("IntersectionObserver" in window) ||
    typeof props.observer === "undefined"
  ) {
    // var downloadingImg = new Image();
    // downloadingImg.src = imagepath;
    // downloadingImg.onload = function() {
    //   if (props.banner) {
    //     var elems = document.getElementsByClassName(
    //       `_img_${id}_${props.caroselname}`
    //     );
    //     for (var i = 0; i < elems.length; i += 1) {
    //       elems[i].src = downloadingImg.src;
    //     }
    //   } else {
    //     var image = document.getElementById(
    //       `_img_${id}_${props.caroselname || ""}`
    //     );
    //     if (image !== null) {
    //       image.src = downloadingImg.src;
    //     }
    //   }
    // };
    // downloadingImg.onerror = function(err) {
    //   // console.log(err);
    // };
  } else {
    // var observer = new IntersectionObserver(onIntersect);
    // document.querySelectorAll("[data-lazy-load]").forEach(function(img) {
    //   observer.observe(img);
    // });
    // function onIntersect(entries, observer) {
    //   entries.forEach(
    //     function(entry) {
    //       if (
    //         entry.target.getAttribute("data-processed") ||
    //         !entry.isIntersecting
    //       )
    //         return true;
    //       if (entry.target.getAttribute("data-src") !== null) {
    //         entry.target.setAttribute(
    //           "src",
    //           entry.target.getAttribute("data-src")
    //         );
    //       }
    //       entry.target.setAttribute("data-processed", true);
    //     },
    //     { rootMargin: "0px 0px px 0px" }
    //   );
    // }
  }

  return (
    <div >
      
     { !props.banner ?
      <Observer {...options}>
      
        <img
        
          className={`${imageclass} _img_${id}_${props.caroselname} ${props.myCustomClass}`}
          // id={`_img_${id}_${props.caroselname || ""}`}
          
          src={altimage}
          alt={props.caroselimg.title}
          // data-src={`https://img-cnvrt.myplex.com/unsafe/filters:format(webp)/${imagepath}`}
          data-src={`${process.env.REACT_APP_THUMBOR_IMAGES+imagepath}`}
          data-lazy-load
          onError={e => errorimg(e, altimage)}
        />
      </Observer> : 
       
        <img
          className={`${imageclass} _img_${id}_${props.caroselname}`}
          // id={`_img_${id}_${props.caroselname || ""}`}
          // src={`https://img-cnvrt.myplex.com/unsafe/filters:format(webp)/${imagepath}`}
          src={`${process.env.REACT_APP_THUMBOR_IMAGES+imagepath}`}
          alt={props.caroselimg.title}
          // data-src={`https://img-cnvrt.myplex.com/unsafe/filters:format(webp)/${imagepath}`}
          data-src={`${process.env.REACT_APP_THUMBOR_IMAGES+imagepath}`}
          data-lazy-load
          onError={e => errorimg(e, altimage,`_img_${id}_${props.caroselname}`)}
        />
     }
    </div>
  );
};

export default Img;
