import React from "react";
import { Helmet } from "react-helmet";
import ParseImgURL from "../utils/parseImgURL";
import vodaFoneLogo from "../assets/images/vodafone_play_logo_512.png";
// import ideaLogo from "../assets/images/IMT_512x512.png";
// import seo_json from "./seoDetailPage";
import { slugify } from "./../utils/stringSlugify";
import { getURLName } from "../../../src/commonfiles/urlMenu";
const seo = (props) => {
  // console.log(props);
  const app = process.env.REACT_APP_CONFIGARATION;
  let title;
  title = "Manorama MAX";
  // if(process.env.REACT_APP_METANAME === 'bcn'){
  //   title = 'Apex OTT '
  // }else{

  //   title = "ShreyasET";
  // }

  // eslint-disable-next-line
  let image = vodaFoneLogo;
  let upload_date =  props.liveStartDate ? props.liveStartDate : "2019-09-21T00:00:00+00:00";
  let StartDate = props.liveStartDate ? props.liveStartDate : "2019-09-21T00:00:00.000Z";
  // if (app === "idea") {
  //   title = "Idea Movies and Tv";
  //   image = ideaLogo;
  //    upload_date = "2019-11-04T00:00:00+00:00";
  //    StartDate = "2019-11-04T00:00:00.000Z";
  // }
  // eslint-disable-next-line
  // console.log(title)
  let og_title = `Watch the Latest Movies, TV Shows & News - ManoramaMAX.`;
  // console.log(og_title);
  // eslint-disable-next-line
  let meta_description = `Watch the Latest Movies, TV Shows & News - ManoramaMAX.`;
  let og_description = `Watch the Latest Movies, TV Shows & News - ManoramaMAX.`;
  // // eslint-disable-next-line
  let key_words = `watch malayalam movies online, movies online, watch malayalam tv shows online, tv shows, live news, watch malayalam live news online, ${title} originals, ${title}, watch  movies online`;
  let og_type = "video";
  //
  // console.log(props);
  if (props.og_title !== undefined) {
    og_title = props.og_title;
  }
  if (props.og_description !== undefined) {
    og_description = props.og_description;
  }
  if (props.meta_description !== undefined ||props.og_description !== undefined) {
    meta_description = props.og_description;
    // meta_description = meta_description && meta_description.replace(/'/g, "");
    meta_description = meta_description && meta_description.replace(/["']/g, "");
  }
  if (props.keywords !== undefined) {
    key_words = props.keywords;
  }
  if (props.type == "movie" || props.vtype =="movie") {
    og_type = "video.movie";
  }else if(props.type == "tvepisode" || props.vtype == "tvepisode"){
    og_type = "video.tv_show";
  }else if(props.type == "news"){
    og_type = "video.tv_show"
  }else if(props.type == "musicvideo" || props.vtype == "musicvideo"){
    og_type = "music.song" ; 
  }else if(props.type == "live"){
    og_type = "video.tv_show"
  }else if(props.type == "tvseries" || props.vtype == "tvseries"){
    og_type = "video.tv_show"
  }


  // eslint-disable-next-line
  // console.log(props.og_url)
  let og_url = window.location.href;
  if (props.content_Details) {
    og_url = `${window.location.origin}/${getURLName(props.content_Details[0], null)}/detail/${props.content_Details[0].generalInfo._id
      }/${slugify(props.content_Details[0].generalInfo.title)}`;

    if (props.content_Details[0].generalInfo.type == "tvepisode") {
      var beautifytitle =
        props.content_Details[0].generalInfo.title &&
        props.content_Details[0].generalInfo.title.split("|");
      var t = beautifytitle[0];
      if (beautifytitle[1]) {
        t = t + beautifytitle[1];
      }
      //  console.log(props.content_Details[0].globalServiceName);
      //  console.log(props.content_Details[0].generalInfo._id);
      //  console.log(beautifytitle);
      og_url = `${window.location.origin}/${getURLName(props.content_Details[0], null)}/detail/${props.content_Details[0].generalInfo._id}/${slugify(props.content_Details[0].globalServiceName)}/${slugify(t)}`;
      //  console.log(beautifytitle);
      //  console.log(url);
    }
  }
  // if(props.og_url !== undefined && props.og_url !== ''){

  //   og_url = props.og_url;
  // }
  // // console.log(og_url);
  // // Replacing with production URL'S
  // og_url = og_url.replace(
  //     /lite.vodafoneplay.in|preprodportal.vodafoneplay.in|pwastag.vodafoneplay.in|www.ideamoviesandtv.in/gi,
  //     "www.vodafoneplay.in"
  // );
  // if (app === "idea") {
  //   og_url = og_url.replace(
  //       /preprodilite.vodafoneplay.in|lite.ideamoviesandtv.in|imtpwa.vodafoneplay.in|www.vodafoneplay.in/gi,
  //       "www.ideamoviesandtv.in"
  //   );
  // }

  // og_url = og_url.replace(/^http:\/\//i, "https://"); // url converting in to secure url

  if (props.title !== "" && props.title !== undefined) {
    title = title + "-" + props.title;
  }
  if (props.image !== undefined) {
    image = ParseImgURL(props.image.values, "mdpi", "coverposter");
  }

  let episodeupdate = props.releaseYear;
  if (props.releaseYear === "" || props.releaseYear === null) {
    if (props.createdDate) {
      episodeupdate = props.createdDate;
    } else {
      episodeupdate = "2022-01-01T06:30:00.000Z";
    }
  }
  // if (props.URL !== undefined) {
  //   // SEO json file having the detail page as well as inside pages meta information
  //   try {
  //     // if (props.type !== 'movie') {
  //       const movie_details = seo_json
  //         .filter(item => item.URL === props.URL.toString())
  //         .shift(); // Fetching meta information from URL
  //       if (movie_details !== undefined) {
  //         og_title = movie_details.Title;
  //         og_description = movie_details.Description;
  //         key_words = movie_details.Keywords;
  //         if (props.og_title !== undefined) {
  //           og_title = og_title.replace("%channel_name%", props.og_title);
  //           og_description = og_description.replace(
  //             "%channel_name%",
  //             props.og_title
  //           );
  //         }
  //         if (app === "idea") {
  //           og_title = og_title.replace("Vodafone Play", "Idea Movies and Tv");
  //           og_description = og_description.replace(
  //             "Vodafone Play",
  //             "Idea Movies and Tv"
  //           );
  //           key_words = key_words.replace("Vodafone Play", "Idea Movies and Tv");
  //         }
  //         meta_description = og_description;
  //       }
  //       else{
  //         og_title = props.new_og_title;
  //         og_description = props.new_og_description;
  //         if (app === "idea") {
  //           if(og_title !==  undefined) {
  //             og_title = og_title.replace("Vodafone Play", "Idea Movies and Tv");
  //             og_description = og_description.replace(
  //                 "Vodafone Play",
  //                 "Idea Movies and Tv"
  //             );
  //             key_words = key_words.replace("Vodafone Play", "Idea Movies and Tv");
  //           }
  //           meta_description = meta_description.replace(
  //               "Vodafone Play",
  //               "Idea Movies and Tv"
  //           );
  //         }
  //       }
  //     // }

  //   } catch (e) {
  //     console.log(e);
  //   }

  // }
  const path_name = window.location.pathname;
  if (path_name === "/" || path_name === "/home") {
    // og_title = 'Home Page'
    og_title = ` Watch the Latest Malayalam Movies Online, TV Shows & Live News - manoramaMAX.`;
    og_description = "Malayalam only OTT which offers the latest Malayalam movies, TV serials and shows, web-series and Live TV News from the house of MM TV.";
    key_words =
      "Manorama News live, Mazhavil Manorama, manoramaMAX, live movies,TV shows, Free movies, watch movies online, movies online, watch tv shows online, tv shows, live news, watch live news online, Manorama MAX originals, Manorama MAX, watch free movies online, Full movies, watch movies, watch online, Mazhavil Manorama serials, Movie website, movies web, series website, Malayalam OTT, OKONG, Malayalam Movies, Malayalam serials, Malayalam News, Kerala News, Malayalam Web Series, Malayalam Reality Shows, Malayalam Music";
    //   if(process.env.REACT_APP_CONFIGARATION === "idea"){
    // og_title = `Watch Live News Channel Streaming of all Breaking News Online - ${title}`;
    // og_description = `Watch live news streaming online from your favourite news channels for free on ${title}. Catch today's latest breaking news live on your preferred news channels in HD`;
    //   }else{
    //     og_title =`Watch Live News Streaming for Latest Breaking News on Vodafone Play`;
    //     og_description = `Watch Live News on your preferred news channels for all latest breaking news only on Vodafone Play. Stay updated with live news streaming for Free in HD of all breaking news stories.`

    //   }
    // meta_description = og_description;
  } else if (path_name === "/Movies" || path_name === "/movies") {
    og_title = 'Watch Latest Movies Online - manoramaMAX'
    // og_title = `Watch Free Bollywood, Hollywood & Regional Movies Online - ${title}`;
    og_description = 'Watch the Latest Movies Malayalam:  Thriller, Romance, Comedy, Family Drama, Action, South Blockbusters - manoramaMAX';
    key_words = "manoramamax ,Malayalam OTT,Malayalam Movies, Malayalam Films, Latest Malayalam Blockbusters, Tovino Thomas, Anna Ben, Roshan Mathew, Parvathy Thiruvothu, Mammootty, Mohanlal, Prithviraj, Asif Ali, Aishwarya Lekshmi, Siddique, Jayasurya, Mukesh, Dulquer Salman, Fahad Fazil, Nariya Nazim, K. P. A. C. Lalitha"
    ;
    meta_description = og_description;
    og_type = "video.movie";
  }
   else if (path_name === "/programs" || path_name === "/Programs") {
    og_title = 'Watch the Latest Serials, TV Shows, Web Series, Reality Shows & Interviews - manoramaMAX'
    // og_title = `Watch Free Hindi, English & Regional ${title} Originals Online`;
    og_description = 'Watch the Latest Mazhavil Manorama Serials, TV Shows, Web Series, Reality Shows, Interviews - manoramaMAX';
    key_words = `Mazhavil Manorama serials, Manorama MAX, manoramamax,Malayalam OTT, Malayalam serials, Malayalam Web Series, Manjil Virinja Poovu, Oru Chiri Iru Chiri Bumper Chiri, Marimayam, Thatteem Mutteem, Thumbapoovu, Kalyani, Super Kudumbam, Meenakshi Kalyanam, Where We Stand, Something Something Like Love, Mazhavil Entertainment Awards, Vanitha Film Awards, Amma Event, Panam Tharum Padam, Ennum Sammatham, Super 4, Udan Panam,`;
    meta_description = og_description;
    og_type = "video.tv_show";
  } 
  else if (path_name === "/news" || path_name === "/News") {
    og_title = 'Watch Latest News Videos Online - manoramaMAX'
    // og_title = `Watch & Browse Entertainment & Reality TV Show Channels Online for Free - ${title}`;
    og_description = 'Watch the Latest News Videos Online:  Breaking News from Kerala, India, Updates and more';
    key_words = `Manorama News live, live news, watch live news online, manoramamax, Manorama MAX,Malayalam OTT,Malayalam News, Kerala News, Breaking News Malayalam, Counterpoint, Nere Chovve, Thiruva Ethirva, Kuttapathram, Speed News, Crime Story, Malayalam News Analysis, Top Kerala News`;
    // if(process.env.REACT_APP_CONFIGARATION === "idea"){
    // og_title = `Watch Live TV Channels for Latest TV Serials Online in HD for Free - ${title}`;
    // og_description = `Watch popular TV channels live for latest TV shows online in HD on ${title}. Stream live TV channels at your convenience to watch popular TV serial full episodes online for free.`;
    // }else{
    //   og_title =`Watch Live TV Channels & TV Serials Online on Vodafone Play`;
    //   og_description = `Watch Live TV Channels online for your favourite TV Shows exclusively on Vodafone Play. Live TV streaming on demand for popular TV serials for Free in HD.`;
    // }
    meta_description = og_description;
    og_type = "video.tv_show";
  } else if (path_name === "/music" || path_name === "/Music") {
    og_title = 'Listen to Latest Songs Online - manoramaMAX'
    // og_title = `About ${title} | ${title} Media Center`;
    og_description = 'Listen to Latest and Trending songs online on manoramaMAX. Manorama Music Songs.';
    key_words = `manoramamax, manorama MAX,Malayalam OTT, Malayalam Music, Hit Malayalam Songs, Vineeth Sreenivasan, MG Sreekumar, Yesudas, Devotional Malayalam songs, Latest Malayalam Film Music`;
    meta_description = og_description;
    og_type ="music.song";
  } else if (path_name === "/games" || path_name === "/Games") {
    // og_title = 'Games'
    // og_title = `${title} FAQ's | Help Center`;
    // og_description = `Get complete information on ${title} subscription, offers, benefits, activation etc. online in the help center. Click here to know more.`;
    // key_words = `${title} help center, ${title} faqs`;
    // meta_description = og_description;
    key_words = `manoramamax,Malayalam OTT, Games, Mobile Games, Quiz, Fun Games`;
  } else if (path_name === "/About") {
    og_title = 'About Us - manoramaMAX'
    og_description = `manoramaMAX – The first Malayalam only OTT which offers the latest movies, TV serials and shows, web-series and News from the house of MM TV.`
    meta_description = og_description;
  } else if (path_name === "/Terms-Of-Use") {
    og_title = 'Terms Of Use - manoramaMAX'
    og_description = `MMTV Services are offered for your personal use only and may not be used for commercial purposes `;
    meta_description = og_description;
  } else if (path_name === "/Frequently-Asked-Questions") {
    og_title = ' Frequently Asked Questions - manoramaMAX.'
    og_description = ` manoramaMAX: Malayalam only OTT which offers the latest Malayalam movies, TV serials and shows, web-series and Live TV News from the house of MM TV. `;
    meta_description = og_description;
  } else if (path_name === "/Privacy-Policy") {
    og_title = 'Privacy Policy - manoramaMAX.'
    og_description = `Privacy Policy - manoramaMAX. `;
    meta_description = og_description;
  } else if (path_name === "/TVChannels") {
    og_title = ' MMTV Live TV Channels - manoramaMAX.'
    og_description = ` MMTV Live TV Channels - manoramaMAX.`;
    meta_description = og_description;
  } else if (path_name === "/Contact") {
    og_title = ' Contact  - manoramaMAX.'
    og_description = `Subscription Queries, Technical Issues & Content Feedback - manoramaMAX.`;
    meta_description = og_description;
  }

  
  // if(app === "idea"){
  //   meta_description = meta_description.replace(
  //       "Vodafone Play",
  //       "Idea Movies and Tv"
  //   );
  // }
  // console.log(props.type);

  return (
    <Helmet>
      <link rel="canonical" href={og_url} />
      <title>{og_title}</title>
      <meta name="description" content={meta_description} />
      <meta name="title" content={og_title} />
      <meta property="og:type" content={og_type} />
      <meta property="og:title" content={og_title} />
      <meta property="og:description" content={meta_description} />
      <meta property="twitter:title" content={og_title} />
      <meta property="twitter:description" content={meta_description} />
      <meta property="og:url" content={og_url} />
      <meta name="keywords" content={key_words} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="300" />
      <meta property="og:image:height" content="300" />

      {(props.type === "movie") | (props.type === "tvepisode") && (
        <script type="application/ld+json">
          {`{
   "@context":"https://schema.org",
   "@type":"VideoObject",
   "name":"${props.og_title}",
   "description":"${meta_description}",
   "thumbnailUrl":[
      "${props.thumbnailUrl}"
   ],
   "uploadDate":"${episodeupdate}",
   "duration":"${props.movieMarkupDetails.duration}",
   "embedUrl":"${window.location}",
   "regionsAllowed":[{"@type":"Country","name":"IN"}],
   "publication":{"@type":"BroadcastEvent","isLiveBroadcast":false,"startDate":"${StartDate}"}
   }`}
        </script>
      )}
      {props.type === "live" && (
        <script type="application/ld+json">
          {`{
                "@context":"https://schema.org",
                "@type":"VideoObject",
                "name":"${props.og_title}",
                "description":"${meta_description}",
                "thumbnailUrl":[
                   "${props.thumbnailUrl}"
                ],
                "uploadDate":"${upload_date}",
                "publication":{
                   "@type":"BroadcastEvent",
                   "isLiveBroadcast":true,
                   "startDate":"${StartDate}"
                }
             }`}
        </script>
      )}
      {props.type === "live" && (
        <script type="application/ld+json">
          {`{
   "@context":[
      "http://schema.org",
      {
         "@language":"en"
      }
   ],
   "@type":"BroadcastService",
   "@id":"${window.location}",
   "name":"${props.og_title}",
   "broadcastDisplayName":"${props.og_title}",
   "broadcastAffiliateOf":{
      "@type":"Organization",
      "@id":"${window.location} "
   },
   "potentialAction":{
      "@type":"WatchAction",
      "target":{
         "@type":"EntryPoint",
         "urlTemplate":"${window.location}",
         "actionPlatform":[
            "http://schema.org/DesktopWebPlatform",
            "http://schema.org/MobileWebPlatform",
            "http://schema.org/AndroidPlatform",
            "http://schema.org/IOSPlatform"
         ]
      },
      "actionAccessibilityRequirement":{
         "@type":"ActionAccessSpecification",
         "category":"free",
         "availabilityStarts":"${props.liveStartDate}",
         "availabilityEnds":"${props.liveEndDate}",
         "eligibleRegion":[
            {
               "@type":"Country",
               "name":"India"
            }
         ]
      }
   }
}`}
        </script>
      )}
      {/* 
    { 
    props.type === "movie" &&
          <script type="application/ld+json">
              {`
              {
                "@context": "http://schema.org",
                "@type": "${props.type}",
                "name": "${props.movieMarkupDetails.title}",
                "dateCreated": "${props.movieMarkupDetails.releaseYear}",
                "director": "${props.movieMarkupDetails.director}",
                "actors": [${props.movieMarkupDetails.actorNames}],
                "image": "${props.movieMarkupDetails.image}",
                "duration": "${props.movieMarkupDetails.duration}",
                "productionCompany": "${props.movieMarkupDetails.production_company}"
                }
              `}
          </script>
          
    } */}

      {props.type === "movie" && (
        <script type="application/ld+json">
          {`{
   "@context":"http://schema.org",
   "@id":"${props._id}",
   "@type":"Movie",
   "name":"${props.movieMarkupDetails.title}",
   "dateCreated": "${props.releaseYear}",
   "url":"${window.location}",
   "description":"${meta_description}",
   "potentialAction":{
      "@type":"WatchAction",
      "target":[
         {
            "@type":"EntryPoint",
            "urlTemplate":"${window.location}",
            "inLanguage":"${props.movieMarkupDetails.language}",
            "actionPlatform":[
               "http://schema.org/DesktopWebPlatform",
               "http://schema.org/MobileWebPlatform",
               "http://schema.org/IOSPlatform",
               "http://schema.org/AndroidPlatform"
            ]
         }
      ],
      "expectsAcceptanceOf":{
         "@type":"Offer",
         "availabilityStarts":"${props.liveStartDate}",
         "availabilityEnds":"${props.liveEndDate}",
         "category":"free",
         "eligibleRegion":{
            "@type":"Place",
            "name":"IN"
         }
      }
   },
   "image":[
      "${props.thumbnailUrl}"
   ],
   "actor":[
      ${props.movieMarkupDetails.actorNames}
   ],
   "director":[
      {
         "@type":"Person",
         "name":"${props.movieMarkupDetails.director}"
      }
   ],"duration":"${props.movieMarkupDetails.duration}"
}`}
        </script>
      )}

      {/* { props.type === 'tvepisode'  &&
            <script type="application/ld+json">
                {`
                {
                "@context": ["http://schema.org", {"@language": "en"}],
                "@type": "${props.type}",
                "@id": "${props.movieMarkupDetails.url}",
                "url": "${props.movieMarkupDetails.url}",
                "name": "${props.movieMarkupDetails.title}",
                "episodeNumber": ${props.episodeNumber},
                "partOfSeason": {
                     "@type": "TVSeason",
                     "@id": "${props.movieMarkupDetails.url}",
                     "seasonNumber": 1
                },
                "partOfSeries": {
                    "@type": "TVSeries",
                    "@id": "${props.movieMarkupDetails.url}",
                    "name": "${props.movieMarkupDetails.title}"
                },
                "releasedEvent": {
                  "@type": "PublicationEvent",
                  "startDate": "${props.movieMarkupDetails.releaseYear}",
                  "location": {
                    "@type": "Country",
                    "name": "${props.movieMarkupDetails.countryCode}"
                  }
                },
                "description": "${props.og_description}",
                "actor": [
                  {
                    "@type": "Person",
                    "name": "${props.movieMarkupDetails.actor}"
                  },
                  {
                    "@type": "Person",
                    "name": "${props.movieMarkupDetails.actress}" 
                  }
                ]
              }
                `}
            </script>
      } */}
      {props.type === "tvepisode" && (
        <script type="application/ld+json">
          {`{
          "@context":"http://schema.org",
          "@id":"${props._id}",
          "@type":"TVEpisode",
          "name":"${props.movieMarkupDetails.title}",
          "episodeNumber":"${props.episodeNumber}",
          "partOfSeason":{
             "@type":"TVSeason",
             "seasonNumber":1
          },
          "partOfSeries":{
             "@type":"TVSeries",
             "name":"${props.movieMarkupDetails.title}"
          },
          "releasedEvent":{
             "@type":"PublicationEvent",
             "startDate":"${props.liveStartDate}",
             "location":{
                "@type":"Country",
                "name":"IN"
             }
          },
          "potentialAction":[
             {
                "@type":"WatchAction",
                "target":{
                   "@type":"EntryPoint",
                   "urlTemplate":"${window.location}",
                   "inLanguage":"${props.movieMarkupDetails.language}",
                   "actionPlatform":[
                      "http://schema.org/DesktopWebPlatform",
                      "http://schema.org/MobileWebPlatform",
                      "http://schema.org/IOSPlatform",
                      "http://schema.org/AndroidPlatform"
                   ]
                },
                "expectsAcceptanceOf":{
                   "@type":"Offer",
                   "availabilityStarts":"${props.liveStartDate}",
                   "availabilityEnds":"${props.liveEndDate}",
                   "category":"subscription",
                   "eligibleRegion":{
                      "@type":"Country",
                      "name":"IN"
                   }
                }
             }
          ],
          "image":[
             "${props.thumbnailUrl}"
          ],
          "description":"${meta_description}",
          "actor":[
             {
                "@type":"Person",
                "name":"${props.movieMarkupDetails.actor}"
             }
          ],
          "director":[
             {
                "@type":"Person",
                "name":"${props.movieMarkupDetails.director}"
             }
          ]
       }`}
        </script>
      )}

      {/* { props.type === 'tvseries' | props.type === 'vodchannel' | props.type === 'tvseason'  &&
            <script type="application/ld+json">
                {`
                {
                "@context": ["http://schema.org", {"@language": "en"}],
                "@type": "${props.type}",
                "@id": "${props.movieMarkupDetails.url}",
                "url": "${props.movieMarkupDetails.url}",
                "name": "${props.movieMarkupDetails.title}",
                "releasedEvent": {
                  "@type": "PublicationEvent",
                  "startDate": "${props.movieMarkupDetails.releaseYear}",
                  "location": {
                    "@type": "Country",
                    "name": "${props.movieMarkupDetails.countryCode}"
                  }
                },
                "description": "${props.og_description}",
                "actor": [
                  {
                    "@type": "Person",
                    "name": "${props.movieMarkupDetails.actor}"
                  },
                  {
                    "@type": "Person",
                    "name": "${props.movieMarkupDetails.actress}" 
                  }
                ]
              }
                `}
            </script>
      } */}
      {props.type === "tvseries" && (
        <script type="application/ld+json">
          {`{
  "@context": [
    "http://schema.org",
    {
      "@language": "en"
    }
  ],
  "@type": "TVSeries",
  "@id": "${window.location}",
  "url": "${window.location}",
  "name": "${props.movieMarkupDetails.title}",
  "potentialAction": {
    "@type": "WatchAction",
    "target": {
      "@type": "EntryPoint",
      "urlTemplate": "${window.location}",
      "inLanguage": "${props.movieMarkupDetails.language}",
      "actionPlatform": [
        "http://schema.org/DesktopWebPlatform",
        "http://schema.org/MobileWebPlatform",
        "http://schema.org/AndroidPlatform",
        "http://schema.org/IOSPlatform"
      ]
    },
    "actionAccessibilityRequirement": {
      "@type": "ActionAccessSpecification",
      "category": "free",
      "availabilityStarts": "${props.liveStartDate}",
      "availabilityEnds": "${props.liveEndDate}",
      "eligibleRegion": [
        {
          "@type": "Country",
          "name": "India"
        }
      ]
    }
  },
  "releasedEvent": {
    "@type": "PublicationEvent",
    "startDate": "${props.liveStartDate}",
    "location": {
      "@type": "Country",
      "name": "India"
    }
  },
  "description": "${meta_description}",
  "actor": [
    {
      "@type": "Person",
      "name": "${props.movieMarkupDetails.actor}"
    },
    {
      "@type": "Person",
      "name": "${props.movieMarkupDetails.actress}"
    }
  ]
}`}
        </script>
      )}
      {props.Stype === "tvseason" && (
        <script type="application/ld+json">
          {`{
  "@context": [
    "http://schema.org",
    {
      "@language": "en"
    }
  ],
  "@type": "TVSeason",
  "@id": "${window.location}",
  "url": "${window.location}",
  "name": "Season 1",
  "seasonNumber": 1,
  "partOfSeries": {
    "@type": "TVSeries",
    "@id": "${window.location.origin}/tvshows/detail/${
            props.contentId
          }/${slugify(props._title)}",
    "name": "${props.movieMarkupDetails.title}",
    "sameAs": "${window.location}"
  },
  "potentialAction": {
    "@type": "WatchAction",
    "target": {
      "@type": "EntryPoint",
      "urlTemplate": "${window.location}",
      "inLanguage": "${props.movieMarkupDetails.language}",
      "actionPlatform": [
        "http://schema.org/DesktopWebPlatform",
        "http://schema.org/MobileWebPlatform",
        "http://schema.org/IOSPlatform",
        "http://schema.org/AndroidPlatform"
      ]
    },
    "actionAccessibilityRequirement": {
      "@type": "ActionAccessSpecification",
      "category": "free",
      "availabilityStarts": "${props.liveStartDate}",
      "availabilityEnds": "${props.liveEndDate}",
      "eligibleRegion": [
        {
          "@type": "Country",
          "name": "India"
        },
        {
          "@type": "Country",
          "name": "US"
        }
      ]
    }
  },
  "sameAs": "${window.location}",
  "releasedEvent": {
    "@type": "PublicationEvent",
    "startDate": "${props.movieMarkupDetails.releaseYear}",
    "location": {
      "@type": "Country",
      "name": "US"
    }
  },
  "description": "${meta_description}",
  "actor": [
    {
      "@type": "Person",
      "name": "${props.movieMarkupDetails.actor}"
    },
    {
      "@type": "Person",
      "name": "${props.movieMarkupDetails.actress}"
    }
  ]
}`}
        </script>
      )}
      {props.episodes_ListViewFlag && (
        <script type="application/ld+json">
          {`{
          "@context":"https://schema.org",
          "@type":"ItemList",
          "itemListElement": ${props.episodes_List}
        }`}
        </script>
      )}
    </Helmet>
  );
};
export default seo;
