import React, {Component} from 'react';
// import Loader from '../../utils/loader'
import Loader from '../../../commonfiles/loader'

import "../Slider/Carousel.css";
import FilterData from './FilterData'

class FilterCarousel extends Component{
    constructor( props ){
        super(props);
        this.state = {
            startIndex: 1,
            filterCarousels: ['live','movie','tvSeries'],
            showLoader: true
        };
    }
    componentDidMount() {
        this._getData();
        const _self = this;
        setTimeout(function () {
            _self.setState({
                showLoader: false
            })
        },1000)
    }
    _getData = () => {
        return this.state.filterCarousels.map( (type, i) => {
              return ( <FilterData type={type} key={i} search={this.props.location.search}/> )
        });
    };
    render() {
        return(
            <section className="watchlist_content">
                 {this.state.showLoader && <Loader />}
                  {this._getData()}
            </section>
        )
    }
}

export default FilterCarousel;