import config from "./config";
// import { isMobile } from "react-device-detect";
function searchForLogos(publishingHouseName) {
    let link, name, waterMark = null;
    if(publishingHouseName !== '' ) {
        const properties = localStorage.getItem("proporties");
        if(properties != null) {
            var partnerDetails = properties.replace(/\\/g, "");
            partnerDetails= partnerDetails.slice(1, -1);
            let items;
            try {
                items = JSON.parse(partnerDetails).partnerDetails ;
              }
              catch(err) {
                 items = [] ;
              }
              if(items) {
                  const data = items.filter(item => item.name === publishingHouseName).shift();
                  if (data !== undefined) {
                      link = data.imageURL;
                      name = data.name;
                      waterMark = data.playerWatermarkURL
                  }
              }
        }
    }
    return {
        'link': link,
        'name': name,
        'water_mark': waterMark
    }
}
/* For Carousels */
export const carouselPatnerLogos  = (publishingHouseName) =>{
    if(config.carouselPartnerLogo) {
        return searchForLogos(publishingHouseName)
    }
    return {
        'link':undefined
    }

};

/* For Banners */
export const bannerPartnerLogos = (publishingHouseName) =>{
    if(config.bannerPartnerLogo){
        return  searchForLogos(publishingHouseName)
    }
    return {
        'link':undefined
    }
};