import React, { Component, Fragment } from "react";
import myplexAPI from "../../api/myplexAPI";
import Img from "../../utils/similarContentImg";
import { withRouter } from "react-router-dom";
// import cleverTap from "../../common/clevertap";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
// import {slugify} from "../../utils/stringSlugify";
// import {getURLName} from "../../utils/urlMenu";
import {slugify} from "../../../commonfiles/stringSlugify";
import {getURLName} from "../../../commonfiles/urlMenu";
import ParseImgURL from "../../utils/parseImgURL";
import { isMobile } from "react-device-detect";
import lensEvents from "../../LensAnalytics";
import sessionFun from "../../../commonfiles/SessionStorage";
//import firebase from "../../analytics/firebase"
// import firebaseEvents from "../../common/firebaseEvents";
// import { Link } from "react-router-dom";

class Search extends Component {
  _isMounted = false;
  _trendingValue = false;

  constructor(props) {
    super(props);
    this._trendingValue = props.trendingSearch;
    
    this.state = {
      caroselimages: [],
      searchResults: [],
      no_results: false,
      pageName: "home",
      searchTypeResultsTrending:[],
      searchTypeResultsRecent:[],
      recentSearchDetails:[],
      recentDiv:false,
      _recentSearchDetailValue : false,
      auth:
      localStorage.getItem("Logged-in") ||
      false,
      searchFields:'',
      type:'live,movie,tvSeries,vodChannel,vod,news,musicvideo,tvepisode'
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if(localStorage.getItem('results') !== null){
      this.setState({
        recentDiv :true
      })
    }else{
      this.setState({
        recentDiv :false
      })
    }
    if(this._trendingValue === false){
      this._fetchSearchResults();
    }else{
      this._fetchCarousels('search');
    }
    document.addEventListener("click", this.handleOutsideClick, false);
    const page_name = window.location.pathname.split("/")[1];
    if (page_name !== "") {
      this.setState({
        pageName: page_name
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("click", this.handleOutsideClick, false);
  }

  _fetchCarousels = (nav_menu) => {
    myplexAPI.getSearchTypes(nav_menu).then(response => {
      var i;
      if(response.data.results.length>0){
        for(i=0;i<response.data.results.length;i++){
            myplexAPI.getSearchTypesDetail(response.data.results[i].name).then(response =>{
              
              if(response.data.title === 'Trending Search' ){     
                this.setState({
                  searchTypeResultsTrending:response.data.results
                })
              }else
              if(response.data.title === 'Recent Search'){
                this.setState({
                  searchTypeResultsRecent: localStorage.getItem('results')
                 })
              }
            })
          }
      }
    });
  };
  _fetchTrendingResults(){
    if(this.state.searchTypeResultsTrending.length > 0){
      return  this.state.searchTypeResultsTrending.map((item, i) => {
        const id = item.generalInfo._id;
      const title = item.generalInfo.title;
      const type = item.generalInfo.type;
      const pageName = this.state.pageName;
        return (
        <div  key ={i} 
        onClick={() =>
          this.goToDetail("/"+getURLName( item, pageName)+"/detail/" +id+'/'+slugify(title),type,title,i,item.generalInfo._id )
        }>
          <img src={process.env.REACT_APP_THUMBOR_IMAGES+ParseImgURL(item.images.values, "mdpi", "coverposter")}alt=""/>
        </div>
        )
      })
    }        
  }

  _fetchRecentResults(){
    var _recentArrayResults = [];
    if(localStorage.getItem('results')!== null){
      _recentArrayResults = localStorage.getItem('results').split(",").slice(0, 8) ;
      return  [...new Set(_recentArrayResults)].map((item, i) => {
       return (
         <li
         key={i}
         onClick = {this._recentSearchClick}>{item}</li>
       )
       })
     }
  }

  _clearRecentSearch(){
    localStorage.removeItem('results');
    this._trendingValue = true
    // this.setState({
    //   recentDiv :false
    // })
    document.getElementsByClassName('recent_search')[0].classList.add('hidden')

  }

  _recentSearchClick = async e => {
    document.getElementById("mainSearch").value = e.currentTarget.innerText;
    this.props._intializeSearch(e)
    myplexAPI.getSearchResults(e, 10,"","live,movie,tvSeries,vodChannel,tvepisode,vod,news,musicvideo").then(response => {
      if(response.data.results.length > 0){
        this.setState({ searchResults: response.data.results, no_results: false });
        this._trendingValue = false
        this.setState(
          {
            _recentSearchDetailValue : true
          }
        )
      }
      else{
        this._trendingValue = true
        this.setState(
          {
            _recentSearchDetailValue : false
          }
        )
      }
    });
  }

  _fetchSearchResults() {
   
    myplexAPI.getSearchResults(this.props.keyword, 10,this.state.searchFields,this.state.type).then(response => {
   
    
      lensEvents.searchedkeywordfn(this.props.keyword)
     
      if (response !== undefined && this._isMounted) {
        if (response.data.results.length > 0) {
          this.setState({ searchResults: response.data.results.slice(0, 5) });
        } else {
          this.setState({ searchResults: [], no_results: true });
        }
      }
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.keyword !== prevProps.keyword) {
      this._fetchSearchResults();
      this.setState(
        {
          _recentSearchDetailValue : true
        }
      )
    }
  }
  goToDetail(path, video_type,title,i,id) {
    /* CleverTab Searched Event */
    const data = {
      category: video_type,
      keyword: this.props.keyword,
      "content clicked": "yes",
      mode: "Direct",
    };

       localStorage.setItem("source",'search');
       localStorage.setItem("tabName", "Search");
       localStorage.setItem('trackingId','');
       localStorage.setItem("source details",title);
       sessionFun(
        0,
        i+1,
        this.state.pageName,
         "search",
         "search",
         id
       );

       if(localStorage.getItem('results') !== null){
        localStorage.setItem('results',[localStorage.getItem('results'),title])
       }else{
        localStorage.setItem('results',title)
       }
       var searchedData={
        'keyword':this.props.keyword,
        'search model':'',
        'source':video_type,
        'content clicked':'yes',
        'content id':'',
        'content name':title,
        'content type':video_type,
        'content language':'',
        'content genre':'',
        'series name':''
     };
      // firebaseEvents.searched(searchedData);
      //  firebase.analytics().logEvent('searched', {
      //    'keyword':this.props.keyword,
      //    'search model':'',
      //    'source':video_type,
      //    'content clicked':'yes',
      //    'content id':'',
      //    'content name':title,
      //    'content type':video_type,
      //    'content language':'',
      //    'content genre':'',
      //    'series name':''
      // })


    // cleverTap.searched(data);
    this.props.closeSearch();
    document.getElementById("search_part").classList.remove("open-search");
    document.getElementById("mainSearch").value = "";

    localStorage.setItem('redirectUrl',path);
      //  console.log(this.auth);
    // if(this.state.auth){
      this.props.history.push(path);
    // }else{
    //   // console.log(this);

    //  //this.props.history.push("/login");

    // }
    // this.props.history.push(path);



  }
  searchResults = () =>
  
    this.state.searchResults.map((search, i) => {
      const id = search.generalInfo._id;
      const title = search.generalInfo.title;
      const type = search.generalInfo.type;
      const pageName = this.state.pageName;
      return (
        <li
          className="search-list__item"
          key={i}
          onClick={() =>
            this.goToDetail("/"+getURLName( search, pageName)+"/detail/" +id+'/'+slugify(title),type,title,i,search.generalInfo._id )
          }
        >
          <span className="search-list__link">
            <div className="image-holder">
              <Img caroselimg={search} key={i} />
            </div>
            <div className="text-holder">
              <span className="result-name">{title}</span>
              {/* <span className="result-title">{type}</span> */}
            </div>
          </span>
        </li>
        //</Link>
      );
    });
  handleOutsideClick = e => {
    if (this.node.contains(e.target) || e.target.id === "mainSearch") {
      return;
    }

    this.props.closeSearch();
  };
  filterSearch=(e,item)=>{
    
    
   if( e.currentTarget.classList.contains('filter_active')){
   // e.currentTarget.classList.remove('filter_active');
    //this.setState({searchFields: '',type:  ''},()=>{this._fetchSearchResults()})
    // if(item.displayName==='All'){
    //   this.setState({searchFields:[],type:[]})

    // }
    //    else{
    // // for( var i = 0; i < this.state.type.length; i++){ 
    
    // //   if ( JSON.stringify(this.state.type[i]) ===item.key ) { 
    // //       this.setState({searchFields:  this.state.searchFields.splice(i, 1),type:  this.state.type.splice(i, 1)})
    // //       }
    // //     }
  

    //   }
          
  } else{
    var tabsSearch= e.currentTarget.parentNode.children;
   
    for(var i=0;i<tabsSearch.length;i++){
     if(e.currentTarget.parentNode.children[i].classList.contains('filter_active')){
      e.currentTarget.parentNode.children[i].classList.remove('filter_active');
      }
    }
   e.currentTarget.classList.add('filter_active');
  // this.setState({searchFields:[...this.state.searchFields,item.searchFields.split(',')]})
  this.setState({searchFields:  item.searchFields,type:  item.key}, ()=>{this._fetchSearchResults()})  
   //this._fetchSearchResults();
  }
 
  
  }
  render() {
    let perpage = 0;
    if (this.props.layoutType === "horizontalListBigItem") {
      perpage = 10;
    } else {
      perpage = 7;
    }

    const options = {
      items: perpage,

      responsive: {
        320: {
          items: perpage === 10 ? 3 : 2,
          nav: true
        },
        481: {
          items: perpage === 10 ? 4 : 3,
          nav: false
        },
        768: {
          items: perpage === 10 ? 5 : 4,
          nav: false
        },
        1200: {
          items: perpage === 10 ? 6 : 5,
          nav: true
        },
        1300: {
          items: perpage === 10 ? 7 : 6,
          nav: true
        },
        1400: {
          items: perpage === 10 ? 8 : 6,
          nav: true
        },
        1500: {
          items: perpage === 10 ? 8 : 6,
          nav: true
        },
        1600: {
          items: perpage === 10 ? 9 : 7,
          nav: true
        },
        1700: {
          items: perpage === 10 ? 10 : 8,
          nav: true
        }
      },
      nav: true,
      navText: [
        "<i class='carousel_leftarrow'/>",
        "<i class='carousel_rightarrow'/>"
      ],
      rewind: false,
      margin: 10,
      dots: false,
      autoplay: false,
      loop: false
    };

    const events = {
      onDragged: function(event) {},
      onChanged: function(event) {}
    };

    return (
      <div
        className="live-search-result"
        ref={node => {
          this.node = node;
        }}
      >

<div className="mobile_search_filters">
              <div className="menu_mobile vfplay_menu_mobile">
                <ul className="menu-list menu-listres">
               
                  {sessionStorage.getItem('searchTabs') && this.props.keyword &&  this.props.keyword.trim()!=='' &&
                  JSON.parse(sessionStorage.getItem('searchTabs')).filter.map((item,i)=>{
                    return  <li className= {item.displayName==='All'? "menu_list_item filter_active":"menu_list_item"} onClick={(e)=>{this.filterSearch(e,item)}} >
                      <div className="menu_list_link" ><span>{item.displayName}</span></div>
                    </li>
                  })
                  }
                  {/* <li className="menu_list_item"><a className="menu_list_link " href="/vip"><span>Movies</span></a>
                  </li>
                  <li className="menu_list_item"><a className="menu_list_link " href="/movies"><span>Programs</span></a>
                  </li>
                  <li className="menu_list_item"><a className="menu_list_link " href="/news"><span>News</span></a>
                  </li>
                  <li className="menu_list_item"><a className="menu_list_link " href="/live-tv-channels"><span>Live TV</span></a>
                  </li>
                  <li className="menu_list_item"><a className="menu_list_link " href="/tv-shows"><span>TV Shows</span></a>
                  </li> */}
                </ul>
              </div>
            </div>
        <ul className="search-list">

      { this.props.trendingSearch && isMobile &&
      
        <div className="trending_search">
            <h2>Trending Search</h2>
            {this.state.searchTypeResultsTrending.length > 0 && 
             <OwlCarousel ref="car" options={options} events={events} >
                 { this._fetchTrendingResults() }
              </OwlCarousel>
            }
             {this.state.recentDiv &&
             <div className="recent_search">
               <div className="recent_search_title">
                 <h2>Search History</h2>
               </div>

               <div className="recent_search_clearall">
                 <h2 onClick ={() =>this._clearRecentSearch()}>Clear History</h2>
               </div>
               
               <div className="recent_search_results">
                  <ul>
                    {this._fetchRecentResults()}        
                    </ul>
               </div>
             </div>}
          </div>
        }
          {this.state.searchResults.length > 0 || this._recentSearchDetailValue ? (
            <Fragment>
              {this.searchResults()}
              <button
                type="button"
                className="result-all-btn"
                onClick={this.props.searchFunction}
              >
                More results
              </button>
            </Fragment>
          ) : this.state.no_results ? (
            <li id="no_results">
              <div className="media-body live-search-text">
                No matches found.
              </div>
            </li>
          ) : null}
        </ul>
      </div>
    );
  }
}
export default withRouter(Search);
