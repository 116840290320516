import React from 'react';

const _filtercarsouls = (name, fun, e) => {

	var elements = e.currentTarget.parentNode.children;
	for (var i = 0; i < elements.length; i++) {

		elements[i].classList.remove('active');
		elements[i].children[0].classList.remove('active');
	}
	e.currentTarget.classList.add('active');
	e.currentTarget.children[0].classList.add('active');
	fun(name);
}

const _fetchData = (data) => {

	//var elements = document.getElementsByClassName("menu-list ")[0].children;
	var elements = document.getElementById("topMenu") && document.getElementById("topMenu").children;
	if(elements){
		for (var i = 0; i < elements.length; i++) {
			if (i !== 0) {
				elements[i].classList.remove('active');
				elements[i].children[0].classList.remove('active');
			} else {
	
				elements[i].classList.add('active');
				elements[i].children[0].classList.add('active');
			}
	
		}
	}
	
	var result = data.nestedData.map((topmenu, index) => (

		<li className={index === 0 ? "menu_list_item active" : `menu_list_item${index}`} key={index} onClick={(e) => _filtercarsouls(topmenu.name, data.getcarosuls, e)}>
			<a key={index} className={index === 0 ? "menu_list_link active" : "menu_list_link"}
		><span>{topmenu.title}</span></a></li>
	));
	return result;
}

const TopMenu = React.memo((props) => {

	return <div className="menu_mobile vfplay_menu_mobile new_tabs_topheader">
		<ul className="menu-list menu-listres top-menu" id="topMenu">

			{_fetchData(props)}

		</ul>
	</div>
})

export default TopMenu;