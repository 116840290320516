import React, { Component } from "react";
// import Ad from "react-google-publisher-tag";
import { isMobile } from "react-device-detect";

class SmallAd extends Component {
  state = {
    dimensions: [],
    sizes:[],
    page: "",
  };

  componentDidMount = () => {
    window.googletag.cmd.push(function () {
      window.googletag.destroySlots();
    });

    var path = "";
    if (window.location.pathname === "/programs") {
      path = "programs";
    }
    if (window.location.pathname === "/news") {
      path = "news";
    }
    if (window.location.pathname === "/movies") {
      path = "movies";
    }
    if (window.location.pathname === "/music") {
      path = "music";
    }
    // if(sessionStorage.getItem("userPacks") && JSON.parse(sessionStorage.getItem("userPacks")).length <= 0){
      let features = true;
      if(sessionStorage.getItem("userfeatures") ){
        let userfetures=JSON.parse(sessionStorage.getItem("userfeatures"));
        features = userfetures.ads;

      }
      // if(features==true) {
      //   this.LoadScript(path);

      // }
      features && this.LoadScript(path)  ;

    // }
   
  };

  querygam_preview = (e) => {
    for (
      var n, r = new RegExp("(?:\\?|&)" + e + "=(.*?)(?=&|$)", "gi"), c = [];
      null != (n = r.exec(document.location.search));

    )
      c[c.length] = n[1];
    return c;
  };

  createCookie = () => {
    var expires = "";
    var date = new Date();
    var value = "true";
    var midnight = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59,
      59
    );
    expires = "; expires=" + midnight.toGMTString();

    var path = "/";

    document.cookie = "FirstVisit" + "=" + value + expires + "; path=" + path;
  };
  componentWillMount() {
    // this.state = {
    //   message: "This is an updated message"
    // };
    window.googletag.cmd.push(function () {
      window.googletag.destroySlots();
    });
  }
  getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  calculate_age = (dob) => {
    var birthDate = new Date(dob);
    var diff_ms = Date.now() - birthDate.getTime();
    var age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };
  LoadScript = (path) => {
    var elem = document.getElementById("gpt_ads");
    if (elem) {
      elem.remove();
    }
    const { googletag } = window;
    googletag.cmd.push(function () {
      googletag.destroySlots();
    });

    let dimensions = '[[970, 90],[728, 90],[300, 250],[336, 280],["fluid"]]';
    let Sizes = '[[728, 90], [970, 250], [970, 90], [990, 90], [1, 1]]';
    var gam_preview_id = this.querygam_preview("gam_preview");

    var mapping =
      'googletag.sizeMapping().addSize([1024, 0], [[728, 90], [970, 250], [970, 90], [990, 90], "fluid", [1, 1]]).addSize([740, 0], [[468, 60], [300, 250], [336, 280], [728, 90], "fluid", [1, 1]]).addSize([320, 0], [[320, 100], [320, 50], [300, 250], [336, 280], [300, 50], [300, 75], [300, 100], "fluid", [1, 1]]).build()';

    let content_page = "home";
    //var usertype = localStorage.getItem("Logged-in") ? 'authenticated' : "free";
    var usertype = "free";
    if(localStorage.getItem("Logged-in") === undefined && localStorage.getItem("Logged-in") === null){
      usertype = "free";
    }else if(localStorage.getItem("Logged-in") === 'true'){
      var results = '';
      if(sessionStorage.getItem("userPackages") !== undefined && sessionStorage.getItem("userPackages") !== null){
        results = JSON.parse(sessionStorage.getItem("userPackages"));
      }
      if(results && results.userType !== undefined && results.userType === ''){
        usertype = 'subscriber';
      }else if(results && results.userType !== undefined && results.userType !== ''){
        usertype = results.userType;
      }else{
        usertype = "authenticated";
      }
    }
    let DivId = "div-gpt-ad-1622714034453-1";
    let Devicecode = 1111;
    let AdUnits = "/21830968352/mmax/lb1/home";
    if (window.location.pathname === "/programs") {
      content_page = "section";
      DivId = "div-gpt-ad-1622714034453-3";
      AdUnits = "/21830968352/mmax/lb1/programs";
    } else if (window.location.pathname === "/news") {
      content_page = "section";
      DivId = "div-gpt-ad-1622714034453-5";
      AdUnits = "/21830968352/mmax/lb1/news";
    } else if (window.location.pathname === "/movies") {
      content_page = "section";
      DivId = "div-gpt-ad-1622714034453-7";
      AdUnits = "/21830968352/mmax/lb1/movies";
    } else if (window.location.pathname === "/music") {
      content_page = "section";
      DivId = "div-gpt-ad-1622714034453-9";
      AdUnits = "/21830968352/mmax/lb1/music";
    }
    if (isMobile) {
      
      dimensions =
        '[[728,90], [970, 250], [970, 90], [990, 90], [320, 100], [468, 60], [300, 250], [336, 280], [320, 50], [300, 50], [300, 75], [300, 100], "fluid", [1, 1]]';
        Sizes = '[[320, 100], [320, 50], [300, 250], [336, 280], [300, 50], [300, 75], [300, 100], [1, 1]]';
      Devicecode = 1000;
      if (window.location.pathname === "/programs") {
        DivId = "div-gpt-ad-1622714034453-2";
      } else if (window.location.pathname === "/news") {
        DivId = "div-gpt-ad-1622714034453-4";
      } else if (window.location.pathname === "/movies") {
        DivId = "div-gpt-ad-1622714034453-6";
      } else if (window.location.pathname === "/music") {
        DivId = "div-gpt-ad-1622714034453-8";
      } else {
        DivId = "div-gpt-ad-1622714034453-0";
      }
    }
    let userAge, gender;
    if (localStorage.getItem("dob")) {
      userAge = this.calculate_age(localStorage.getItem("dob"));
      if (userAge >= 13 && userAge <= 17) {
        userAge = "13to17yrs";
      } else if (userAge >= 18 && userAge <= 24) {
        userAge = "18to24yrs";
      } else if (userAge >= 25 && userAge <= 34) {
        userAge = "25to34yrs";
      } else if (userAge >= 35 && userAge <= 44) {
        userAge = "35to44yrs";
      } else if (userAge >= 45 && userAge <= 54) {
        userAge = "45to54yrs";
      } else if (userAge >= 55 && userAge <= 64) {
        userAge = "55to64yrs";
      } else if (userAge >= 65) {
        userAge = "65+yrs";
      } else {
        userAge = "";
      }
    } else {
      userAge = "";
    }
    if (localStorage.getItem("gender")) {
      if (
        localStorage.getItem("gender") == "M" ||
        localStorage.getItem("gender") == "m"
      ) {
        gender = "M";
      } else if (
        localStorage.getItem("gender") == "F" ||
        localStorage.getItem("gender") == "f"
      ) {
        gender = "F";
      } else {
        gender = "N";
      }
    } else {
      gender = "N";
    }
    let referrer = "direct";
    if (document.referrer) {
      referrer = document.referrer.match(/:\/\/(.[^/]+)/)[1];
    }
    const element = document.getElementById("gpt_ads_contentdetail");
    element && element.remove();
    
    var script = document.createElement("script");
    script.type = "text/javascript";
    // script.id= content_page+"_"+DivId+"_"+Devicecode;
    script.id = "gpt_ads";
    // script.innerHTML = 'apstag.init({pubID: "e23e80e2-e655-44aa-a417-5931f830f59d",adServer: "googletag",bidTimeout: 2e3});';
    script.innerHTML += 'apstag.fetchBids({';
    script.innerHTML +='slots: [{slotID: "'+DivId+'",slotName:"'+AdUnits+'",sizes:'+Sizes+'}]},';
    script.innerHTML += 'function(bids) { googletag.cmd.push(function() {';
    script.innerHTML += 'apstag.setDisplayBids();googletag.pubads().refresh();});});';
    script.innerHTML += "googletag.cmd.push(function () {";
    script.innerHTML += "googletag";
    script.innerHTML += '.defineSlot("' + AdUnits + '",';
    script.innerHTML += dimensions + ',"' + DivId + '")';

    if (gender != "N") {
      script.innerHTML += '.setTargeting("gender", ["' + gender + '"])';
    }
    script.innerHTML +=
      '.setTargeting("content_page", ["' +
      content_page +
      '"]).setTargeting("usertype",["'+usertype+'"]).setTargeting("source", ["' +
      content_page +
      '"])';
    if (userAge !== "") {
      script.innerHTML += '.setTargeting("age", ["' + userAge + '",])';
    }

    script.innerHTML +=
      '.setTargeting("platform", ["web"]).setTargeting("page", ["' +
      content_page +
      '"])';
    script.innerHTML += '.setTargeting("position", ["BTF"])';

    if (gam_preview_id.length > 0) {
      script.innerHTML +=
        '.setTargeting("gam_preview",["' + gam_preview_id + '"])';
    }
    if (!this.getCookie("FirstVisit")) {
      script.innerHTML += '.setTargeting("firstvisit", ["Y"])';
    }
    script.innerHTML += '.setTargeting("source",["' + referrer + '"])';
    script.innerHTML +=
      ".defineSizeMapping(" + mapping + ").addService(googletag.pubads());});";

    script.innerHTML += "googletag.pubads().enableSingleRequest();";

    script.innerHTML +=
      'googletag.pubads().set("page_url", "www.manoramamax.com");';
    script.innerHTML +=
      "googletag.pubads().collapseEmptyDivs();googletag.pubads().disableInitialLoad();";
    script.innerHTML += "googletag.enableServices();";
    let checkExisting = document.getElementById(script.id);
    if (checkExisting == null || checkExisting == undefined) {
      document.getElementsByTagName("head")[0].appendChild(script);
      this.createCookie();
    }

    this.setState({ page: path }, () => this.initiateAdd(path));
  };
  initiateAdd = (path) => {
    var uPacks = sessionStorage.getItem("userPacks")
      ? JSON.parse(sessionStorage.getItem("userPacks"))
      : null;

    if (!uPacks || (uPacks && uPacks.length <= 0)) {
      if (isMobile) {
        const s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.innerHTML =
          "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1622714034453-0'); });";
        if (path === "programs") {
          s.innerHTML =
            "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1622714034453-2'); });";
        }
        if (path === "news") {
          s.innerHTML =
            "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1622714034453-4'); });";
        }
        if (path === "movies") {
          s.innerHTML =
            "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1622714034453-6'); });";
        }
        if (path === "music") {
          s.innerHTML =
            "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1622714034453-8'); });";
        }
        this.instance.appendChild(s);
      } else {
        const s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.innerHTML =
          "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1622714034453-1'); }); ";

        if (path === "programs") {
          s.innerHTML =
            "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1622714034453-3'); }); ";
        }
        if (path === "news") {
          s.innerHTML =
            "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1622714034453-5'); }); ";
        }
        if (path === "movies") {
          s.innerHTML =
            "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1622714034453-7'); }); ";
        }
        if (path === "music") {
          s.innerHTML =
            "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1622714034453-9'); });";
        }
        this.instance.appendChild(s);
      }
    }
  };

  render() {
    // console.log(this.state.page);
    return (
      <div>
        {/* {this.state.page === "news" ? (
          isMobile ? ( */}
           <div
        className="detail_ad_section"
        id="dfp-ad-lb1"
        ref={(el) => (this.instance = el)} style={{"marginTop":"10px"}}
      ></div>
        <div
          className="ad-section"
          id="div-gpt-ad-1622714034453-4"
          ref={(el) => (this.instance = el)}
        ></div>
        {/* // ) : ( */}
        <div
          className="ad-section"
          id="div-gpt-ad-1622714034453-5"
          ref={(el) => (this.instance = el)}
        ></div>
        {/* // ) */}
        {/* // ) : this.state.page === "programs" ? (
        //   isMobile ? ( */}
        <div
          className="ad-section"
          id="div-gpt-ad-1622714034453-2"
          ref={(el) => (this.instance = el)}
        ></div>
        {/* // ) : ( */}
        <div
          className="ad-section"
          id="div-gpt-ad-1622714034453-3"
          ref={(el) => (this.instance = el)}
        ></div>
        {/* //   )
        // ) : this.state.page === "movies" ? (
        //   isMobile ? ( */}
        <div
          className="ad-section"
          id="div-gpt-ad-1622714034453-6"
          ref={(el) => (this.instance = el)}
        ></div>
        {/* // ) : ( */}
        <div
          className="ad-section"
          id="div-gpt-ad-1622714034453-7"
          ref={(el) => (this.instance = el)}
        ></div>
        {/* // ) 
        // ) : this.state.page === "music" ? (
          // isMobile ? ( */}
        <div
          className="ad-section"
          id="div-gpt-ad-1622714034453-8"
          ref={(el) => (this.instance = el)}
        ></div>
        {/* // ) : ( */}
        <div
          className="ad-section"
          id="div-gpt-ad-1622714034453-9"
          ref={(el) => (this.instance = el)}
        ></div>
        {/* // )
          // ): isMobile ? ( */}
        <div
          className="ad-section"
          id="div-gpt-ad-1622714034453-0"
          ref={(el) => (this.instance = el)}
        ></div>
        {/* // ) : ( */}
        <div
          className="ad-section"
          id="div-gpt-ad-1622714034453-1"
          ref={(el) => (this.instance = el)}
        ></div>
        {/* // )} */}
      </div>
    );
  }
}

export default SmallAd;
