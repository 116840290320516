import config from "./config";
import db from "./db";

const indexedDB = props => {
  const URL = props;
  return new Promise(function(resolve, reject) {
    let expiredTimeInMinutes = config.db_expiredTimeInMinutes;
    db.table("carousels")
      .get(URL)
      .then(function(db_response) {
        if (db_response !== undefined && config.indexedDB) {
          let expiredTime =
            parseInt(db_response.created_at) + expiredTimeInMinutes * 60 * 1000;
          if (expiredTime < Date.now()) {
            db.carousels
              .where("name")
              .equals(URL)
              .delete()
              .then(function() {
                resolve(false);
              });
          } else {
            resolve(db_response.data);
          }
        } else {
          resolve(false);
        }
      })
      .catch(db.MissingAPIError, function(e) {
        resolve(false);
      })
      .catch("SecurityError", function(e) {
        resolve(false);
      })
      .catch(function(e) {
        resolve(false);
      });
  });
};

export default indexedDB;
