import React, { Component } from "react";
import "./Viewall.css";
import Img from "../Uicomponents/Img";
import myplexAPI from "../../api/myplexAPI";
import FilterOptions from "./FilterOptions";
import { Link } from "react-router-dom";
import { queryStringParse } from "../../utils/portalhelper";
import SEO from "../../common/helmet";
// import {slugify} from "../../utils/stringSlugify";
// import {getURLName} from "../../utils/urlMenu";

import {slugify} from "../../../commonfiles/stringSlugify";
import {getURLName} from "../../../commonfiles/urlMenu";
import {carouselPatnerLogos} from "../../utils/partnerLogos";
import LazyLoad from "react-lazyload";

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryparams: {},
      FilterData: [],
      name: "",
      filterval: "",
      error: false,
      hasMore: true,
      isLoading: false,
      startindex: 1,
      Lanfilter: false,
      Genfilter: false,
      Generechecklist: [],
      Languagechecklist: [],
      axios_request: true,
      Filtercheck: false,
      pageName: "Home"
    };
    window.onscroll = () => {
      const {
        state: { error, isLoading, hasMore }
      } = this;

      if (error || isLoading || !hasMore) return;

      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        this.fetchData();
      }
    };
  }
  componentDidMount() {
    this.setParams();
    var Generechecklist = localStorage.getItem("Generechecklist");
    var Languagechecklist = localStorage.getItem("Languagechecklist");

    if (Generechecklist != null && Generechecklist !== "") {
      this.setState({
        Generechecklist: Generechecklist
      });
    }
    // eslint-disable-next-line eqeqeq
    if (Languagechecklist != null && Languagechecklist !== "") {
      this.setState({
        Languagechecklist: Languagechecklist
      });
    }
    const page_name = window.location.pathname.split("/")[1];
    if (page_name !== "") {
      this.setState({
        pageName: page_name
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.setState({
        FilterData: [],
        startindex: 1,
        hasMore: true,
        Generechecklist: [],
        Languagechecklist: []
      });

      this.setParams();
    }
  }
  setParams = () => {
    let params = queryStringParse(this.props.location.search);
    let name = "Movies";
    // eslint-disable-next-line
    {
      params.type === "live"
        ? (name = "Live TV")
        : params.type === "tvSeries"
        ? (name = "Tv Shows")
        : (name = "Movies");
    }
    let filtervalue = params.filtervalue;
    let genres = params.genres;
    if(filtervalue === undefined){
      filtervalue = '';
    }
    this.setState(
      {
        queryparams: params,
        name: name,
        filterval: filtervalue,
        Languagechecklist: [filtervalue],
        Generechecklist: [genres]
      },
      () => this.fetchData()
    );
  };
  applyFilter = filterType => {
    // debugger;
    var array = [];
    var array1 = [];
    var array2 = [];
    var name = "";
    // eslint-disable-next-line
    {
      filterType === "genere" ? (name = "chkgenere") : (name = "chklanguage");
    }
    var checkboxes = document.querySelectorAll(`input[name=${name}]:checked`);
    for (var i = 0; i < checkboxes.length; i++) {
      array.push(checkboxes[i].value);
    }
    if (filterType === "genere") {
      array1 = array;
      array2 = this.state.Languagechecklist;
    } else {
      array1 = this.state.Generechecklist;
      array2 = array;
    }
    localStorage.setItem("Generechecklist", array1);
    localStorage.setItem("Languagechecklist", array2);
    this.setState(
      {
        startindex: 1,
        FilterData: [],
        hasMore: true,
        Generechecklist: array1,
        Languagechecklist: array2
      },
      () => this.fetchData()
    );
  };
  resetFilter = filterType => {
    if (filterType === "genere") {
      var array1 = [];
      var array2 = this.state.Languagechecklist;
    } else {
      array1 = this.state.Generechecklist;
      array2 = [];
    }
    this.setState(
      {
        startindex: 1,
        FilterData: [],
        hasMore: true,
        Generechecklist: array1,
        Languagechecklist: array2
      },
      () => this.fetchData()
    );
    localStorage.setItem("Generechecklist", array1);
    localStorage.setItem("Languagechecklist", array2);
  };
  restAllFilter = () => {
    this.setState(
      {
        startindex: 1,
        FilterData: [],
        hasMore: true,
        Generechecklist: [],
        Languagechecklist: []
      },
      () => this.fetchData()
    );
    localStorage.setItem("Generechecklist", []);
    localStorage.setItem("Languagechecklist", []);
  };
  fetchData = () => {
    if (this.state.axios_request === true) {
      this.setState({
        axios_request: false
      });
      myplexAPI
        .getFilterhResults(
          this.state.startindex,
          this.state.queryparams.type,
          this.state.Languagechecklist,
          this.state.Generechecklist,
            24
        )
        .then(response => {
          this.setState({
            axios_request: true
          });
          if (response.data.results.length <= 0) {
            this.setState({
              Filtercheck: true
            });
          }
          if (response.data.results.length < 24) {
            this.setState({
              hasMore: false,
              axios_request: true
            });
          }
          this.setState({
            FilterData: [...this.state.FilterData, ...response.data.results],
            startindex: this.state.startindex + 2
          });
        });
    }
  };
  sourceDetails = source_details => {
    localStorage.setItem("source details", source_details);
    localStorage.setItem("source", "Filter");
    localStorage.setItem('trackingId','');
  };
  FilterData = () =>
    this.state.FilterData.map((caroselimg, i) => {
      let logo = null;
      if (caroselimg.publishingHouse !== undefined) {
        let partnerLogos = carouselPatnerLogos(
            caroselimg.publishingHouse.publishingHouseName
        );
        if (
            partnerLogos.link !== undefined &&
            this.props.layoutType !== "horizontalListSmallItem" &&
            this.props.layoutType !== "horizontalListMediumItem"
        ) {
          logo = (
              <LazyLoad once>
                <img
                    src={partnerLogos.link}
                    alt={partnerLogos.name}
                    className="filter_logo_partner"
                />
              </LazyLoad>
          );
        }
      }
      return (
        <div className="flex-dev filters_flex_dev" key={i}>
          <Link
            to={{
              pathname: `/${getURLName( caroselimg, this.state.pageName )}/detail/${
                caroselimg.generalInfo._id
              }/${slugify(caroselimg.generalInfo.title)}`
              // search: `?videotype=${caroselimg.generalInfo.type}`
            }}
            key={i}
          >
            <Img
              caroselimg={caroselimg}
              key={i}
              banner={false}
              layouttype={this.state.queryparams.type}
            />{" "}
            {logo}
          </Link>
          <div className="episodes-info">
            <span> {caroselimg.title}</span>
          </div>
        </div>
      );
    });

  render() {
    return (
      <div className="viewall_rectangle">
        <SEO
          title={this.state.name}
          meta_description={`${this.state.filterval}-${this.state.name}`}
        />
        {this.state.Generechecklist.length > 0 ||
        this.state.Languagechecklist.length > 0 ? (
          <div className="reset_filter_link">
            <h4 onClick={this.restAllFilter}>Reset Filters</h4>
          </div>
        ) : null}
        {this.state.name && (
          <FilterOptions
            name={this.state.name}
            type={this.state.queryparams.type}
            filterFunction={this.applyFilter}
            resetFunction={this.resetFilter}
            Generechecklist={this.state.Generechecklist}
            Languagechecklist={this.state.Languagechecklist}
          />
        )}
        {this.state.FilterData.length > 0 ? (
          <div
            className="flex-container"
            onClick={() => this.sourceDetails("Applied")}
          >
            {this.FilterData()}
          </div>
        ) : (
          this.state.Filtercheck && (
            <div className="results_empty">
              <h3>No Results Found</h3>
              <p>
                We don’t have results for the content you’re looking for.
                <br />
                Please check the spelling or search for something else.
              </p>
              <h2>SEARCH AGAIN</h2>
            </div>
          )
        )}
        {this.state.hasMore && (
          <div className="spinner">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
            <div className="bounce4" />
          </div>
        )}
      </div>
    );
  }
}
export default Filter;
