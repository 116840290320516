import React, { Component } from 'react';
import { Link } from "react-router-dom";

class EmptyWatchHistory extends Component {
    render() {
        return (
            <section className="watchlist_content">
            <div className="title_watchlist">
              <h2>My Wathch History</h2>
            </div>
            <div className="results_empty">
            <h3>watch list empty</h3>
            <p>
              You haven't Watched any videos.
              <br />
              Wathch Videos here
            </p>
            <Link to="/">
              <h2>BROWSE</h2>
            </Link>
          </div>
                
            </section>
        );
    }
}

export default EmptyWatchHistory;