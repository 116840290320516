// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// -- prod
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAKItEjCVADdz0CbEz1gbqImgR2ZyqFJTg",
    authDomain: "push-notifications-manoramamax.firebaseapp.com",
    databaseURL: "https://push-notifications-manoramamax.firebaseio.com",
    projectId: "push-notifications-manoramamax",
    storageBucket: "push-notifications-manoramamax.appspot.com",
    messagingSenderId: "822591294257",
    appId: "1:822591294257:web:42487b0489e587549e7e10",
    measurementId: "G-W154GRZGP6"
};
// Qa app
// const firebaseConfig = {
//     apiKey: "AIzaSyCTj60WSPqHgiQb40AxBw2xnVNcaOACKr8",
//     authDomain: "push-notifications-manoramamax.firebaseapp.com",
//     databaseURL: "https://push-notifications-manoramamax.firebaseio.com",
//     projectId: "push-notifications-manoramamax",
//     storageBucket: "push-notifications-manoramamax.appspot.com",
//     messagingSenderId: "822591294257",
//     appId: "1:822591294257:web:4f1f0d9b5f6925e69e7e10",
//     measurementId: "G-F7P22B8R56"
//   };
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);




// export const onMessageListener = () =>
//     new Promise((resolve) => {
//         onMessage(messaging, (payload) => {
//             // console.log("payload", payload)
//             // resolve(payload);
//         });
//     });

    
export const requestForToken = () => {
    return getToken(messaging, { vapidKey: 'BM3q3bjA1NOGb48urCI7e6CiFuXuI9Vwa-V4fDoZhsfU62z06LFQjSOWVwyB96-YEtWuQf58XYYl0yD1EIyTtSs' })
        .then((currentToken) => {
            if (currentToken) {
                // console.log('current token for client: ', currentToken);
                // Perform any other neccessary action with the token
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
};