import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as Sentry from "@sentry/react";
import * as serviceWorker from "./serviceWorker";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import { isMobile } from "react-device-detect";
// import ishttp from "./utils/httpMethods";
// import bugsnag from "@bugsnag/js";
// import bugsnagReact from "@bugsnag/plugin-react";
// import Environmentheader from "./components/Uicomponents/head";
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';


// if (isMobile || process.env.REACT_APP_DESKTOP === "true") {
  //var bugsnagClient = bugsnag("8544cf661ee6deb787552d63f3b2c2b4");
  // if (
  //   window.location.protocol !== "https:" &&
  //   !document.domain.includes("localhost")
  // ) {
  //   ishttp();
  // }
  // else {
	//    if (process.env.REACT_APP_CONFIGARATION === "idea") {
  //   document.body.classList.add("idea-theme");
  //   document.body.classList.remove("dark-theme");
  //   import("./Idea/assets/css/Ideaapp.css").then(() => {
  //     ReactDOM.render(
  //       <React.Fragment>
  //         <Environmentheader
  //           configaration={process.env.REACT_APP_CONFIGARATION}
  //         />
  //         <App />
  //       </React.Fragment>,
  //       document.getElementById("root")
  //     );
  //   });
  // } else {
    //getting from env config
    const sentry_dns = process.env.REACT_APP_SENTRY_DNS;
    Sentry.init({ dsn: sentry_dns,
      release: '1.1.1 '+process.env.REACT_APP_ENVIRONMENT, });
    let email = localStorage.getItem('userMail');
    let user_id = localStorage.getItem('masked_user_id');

    if(user_id === null || user_id === undefined) {
        user_id =  null
    }
    if(email === null || email === undefined) {
        email =  null
    }
        Sentry.setUser({email: email});

    Sentry.setUser({id: user_id});
    ReactDOM.render(
      <React.Fragment>
        {/* <Environmentheader
          configaration={process.env.REACT_APP_CONFIGARATION}
        /> */}
        <App/>
      </React.Fragment>,
      document.getElementById("root")
    );
  // }
  // }
  // bugsnagClient.use(bugsnagReact, React);
  // var ErrorBoundary = bugsnagClient.getPlugin("react");
 
// } else {
  // document.body.classList.add("light-theme");
  // document.body.classList.remove("dark-theme");
  // const Vfdesktop = lazy(() => import("./desktop/Vfdesktop"));

  // ReactDOM.render(
    // <Suspense fallback={<p />}>
      // <Vfdesktop />
    // </Suspense>,
    // document.getElementById("root")
  // );
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
