import React, { Component, lazy, Suspense } from "react";  
import { Route, Switch } from "react-router-dom";
import Render from "../general/Render";
import Header from "../general/Header/Header";
// import ChangeContentLanguage from "../general/Header/ChangeContentLanguage"
import AppLanguage from "../general/Header/AppLanguage";
// import Footer from "../components/Footer/Footer";
// import viewall from "../components/Inside/Viewall";
// import Searchresult from "../general/Inside/Searchresults";
// import Detail from "../components/Inside/detail";
// import About from "../general/Static/Aboutus";
// import Terms from "../general/Static/Terms";
// import Faqs from "../general/Static/Faqs";
// import AboutPage from "../general/Static/About";
// import Ptptandc from "../general/Static/Ptptandc";
// import Savedatetandc from "../general/Static/SaveTheDateTandC";

import Filter from "../general/Inside/FilterCarousel";
// import Watchlist from "../general/Inside/Watchlist";
import FilterViewAll from "../general/Inside/Filters";
// import redirectToVFPlay from "../components/Static/redirectToVFPlay";
import myplexAPI,{URL_MENU} from "../api/myplexAPI";
import indexedDB from "../utils/indexedDB";
import Login from "../general/Header/Login";
import Reels from "../components/reels";
// import Registration from "../general/Header/Registration";
// import Actorprofile from "../general/Header/Actorprofile";
// import Editprofile from "../general/Header/Editprofile";
// import EditProfile1 from "../general/Header/EditProfile1";
// import Ordersubscription from "../general/Header/Ordersubscription";
import Device from "../general/Inside/Device";
// import activate from "../general/Inside/Device";

// import LayoutCenterCarousel from "../general/Slider/LayoutCenterCarousel";
// import LayoutCenterBanner from "../general/Slider/LayoutCenterBanner";
// import Uptandc from '../general/Static/Uptandc';


//import LayoutProfileRadio from "../general/Slider/LayoutProfileRadio";
// import LayoutCastandCrew from "../general/Slider/LayoutCastandCrew";
//import LayoutSimilarNewsCarousel from "../general/Slider/LayoutSimilarNewsCarousel";
//import LayoutSimilarNewsCarousel from "../general/Slider/LayoutSimilarNewsCarousel";
// import LayoutTrailersCarousel from "../general/Slider/LayoutTrailersCarousel";




// import Test from "../../manorama/Test"
// import Promotions from "../components/Header/Promotions";
// import Promotions2 from "../components/Header/Promotions2";

//import Worlds from "../general/Slider/worlds"

// import Myaccount from "../general/Header/Myaccount";

// import ForgotPassword from "../general/Header/ForgotPassword"
// import MySubscriptions from "../general/Header/MySubscriptions";
// import Subscribe from "../general/Header/Subscribe";

// import SubscriptionPlans from "../general/Header/SubscriptionPlans";
// import NewsTab from "../general/Slider/NewsTab";
//import SpeedNews from "../general/Header/SpeedNews";
// import NewsDetail from "../general/Header/NewsDetail";
// import PlayList from "../general/Inside/PlayList";
// import Settings from "../general/Header/Settings";
// import WatchHistory from "../general/Header/WatchHistory";
//import Countries from "../general/Header/Countries";
// import BreakingNews from "../general/Slider/BreakingNews";


// import EpisodesList from "../components/EpisodesList";
// import Test from "../components/Test";
const PlayList = lazy(() => import("../general/Inside/PlayList"));
const Settings = lazy(() => import("../general/Header/Settings"));
const WatchHistory = lazy(() => import("../general/Header/WatchHistory"));
const Uptandc = lazy(() => import("../general/Static/Uptandc"))
const SubscriptionPlans = lazy(() => import("../general/Header/SubscriptionPlans"))
const Subscribe = lazy(() => import("../general/Header/Subscribe"))
const Editprofile = lazy(() => import ("../general/Header/Editprofile")) 
const EditProfile1 = lazy(() => import ("../general/Header/EditProfile1"))
const Watchlist = lazy(() => import ("../general/Inside/Watchlist"))
const MySubscriptions = lazy(() => import("../general/Header/MySubscriptions"))
const ForgotPassword = lazy(() => import("../general/Header/ForgotPassword"))
const Myaccount = lazy(() => import("../general/Header/Myaccount"))
const Registration = lazy(() => import("../general/Header/Registration"))
const Ptptandc = lazy(()  => import("../general/Static/Ptptandc"))
const Savedatetandc = lazy(() => import ("../general/Static/SaveTheDateTandC"))
const AboutPage = lazy(() => import("../general/Static/About")) 
const Uprandr = lazy(() => import("../general/Static/Uprandr"))
const Supershopping = lazy(() => import("../general/Static/Supershopping"))
const ranirajacontest = lazy(() => import("../general/Static/ranirajacontest"))
const rulesandregulations = lazy(() => import("../general/Static/rulesandregulations"))
const dubaicontest = lazy(() => import("../general/Static/dubaicontest"))
const eas = lazy(() => import("../general/Static/eas"))


// const kbcontest = lazy(() => import("../general/Static/kbcontest"))


const EpisodesList = lazy(() => import("../general/EpisodesList"));
const About = lazy(() => import("../general/Static/Aboutus"));
const Terms = lazy(() => import("../general/Static/Terms"));
const Dtandc = lazy(() => import("../general/Static/Dtandc"));
const TVChannels = lazy(() => import("../general/Static/TVChannels"));
const Faq = lazy(() => import("../general/Static/Faqs"));
const PrivacyPolicy = lazy(() => import("../general/Static/PrivacyPolicy"));
const Detail = lazy(() => import("../general/Inside/detail"));
const Contact = lazy(() => import("../general/Static/Contact"));
const Redirection = lazy(() => import("../components/redirection"));



// const FilterViewAll = lazy(() => import("../components/Inside/Filters"));
//const Watchlist = lazy(() => import("../components/Inside/Watchlist"));
const viewall = lazy(() => import("../general/Inside/Viewall"));
const Searchresult = lazy(() => import("../components/Inside/Searchresults"));
const Footer = lazy(() => import("../general/Footer/Footer"));
//const Reels = lazy(() => import("../components/Scroll"));
const TermsBlastersFootballContest  = lazy(() => import("../general/Static/Terms_blasters_football_contest"));
const PopeesContest  = lazy(() => import("../general/Static/PopeesContest"));
const UdanPanam  = lazy(() => import("../general/Static/UdanPanam"));
const loginFailed = lazy(() => import("../general/Static/loginFailed"));

class BcnRoutes extends Component {
  list=';'
  constructor(props){
    super(props);
    this.state = {
      menu: [],
      issonyliv: false,
      properties:[]

    };
    this._isMounted = false
  }
  WaitingComponent(Component) {
    return props => (
      <Suspense fallback={<div style={{ color: "black" }}>Loading...</div>}>
        <Component {...props} />
      </Suspense>
    );
  }
  componentDidMount() {
    this._isMounted = true;
    if(window.location.pathname !=="/sony-liv-banner"){
      this.setState({
        issonyliv: true 
      });

    }
    this.getproperties();
    this.getMenu();
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  getMenu = () => {
    const that = this;
    indexedDB(URL_MENU(window.nav_menu))
        .then(function (result) {
          if (result !== false) {
            that.setState({
              menu: result
            });
          } else {
            that._fetchMenus();
          }
        })
        .catch(function (err) {
          that._fetchMenus();
        });
  };
  getproperties = () => {
    myplexAPI.properties().then(response => {
      if(response.data.commonParams){
        localStorage.setItem('commonParams',JSON.stringify(response.data.commonParams));
      }
    

      this.setState({
        properties: response.data
      });
    });

  }
  _fetchMenus = () => {
    const nav_menu = window.nav_menu;
    this.list = localStorage.getItem('selectedLanguageList');
    this.appLanguage = localStorage.getItem('selectedAppLanguageList')

    // console.log(nav_menu);
    // console.log(this.appLanguage)
    myplexAPI.pullPageData(nav_menu,this.list,this.appLanguage).then(response => {
      this.navMenuResponse(response);
    });
  };
  navMenuResponse = response => {
    const that = this;
      
      if (that._isMounted) {
        that.setState({
          menu: response.data.results
        });
        myplexAPI.storeIndexedDb(
            response.request.responseURL,
            response.data.results
        );
      }
  };
  render() {
    // if (window.location.pathname === '/test') return <Test/> ;
    
    return (
       Object.keys(this.state.properties).length > 0 ?
      <div>
        {this.state.menu.length > 0 &&
          <Route path="/" component={this.WaitingComponent(() => <Header menu={this.state.menu}/>)}/>
        }
        <Switch>
        <Route path="/Watch-History" exact component={this.WaitingComponent(WatchHistory)} />
          <Route path="/" exact component={Render} />
       
          <Route path="/App-Language"  component={AppLanguage} />
          <Route path="/Display-Language"  component={AppLanguage} />



          <Route path="/:Movies/filter" component={Filter} />
          <Route path="/:Movies/filter-view-all" component={FilterViewAll} />
          <Route
            path="/:PageName/detail/:Id/:Url?"
            component={this.WaitingComponent(Detail)}
          />
           <Route
            path="/:PageName/:Id/:Url/episodes/:year?"
            component={this.WaitingComponent(EpisodesList)}
          />
          <Route
            path="/:PageName/:Id/:Url/:season/episodes/:year?"
            component={this.WaitingComponent(EpisodesList)}
          />
          <Route path="/Searchresults" component={this.WaitingComponent(Searchresult)}  />
          <Route
            path="/:type/inside/:page"
            component={this.WaitingComponent(viewall)}
          />
          {/* <Route path="/Content-Language" exact component={ChangeContentLanguage} /> */}
          <Route path="/About-Us" component={this.WaitingComponent(AboutPage)} />
          <Route path="/Terms-Of-Use" component={this.WaitingComponent(Terms)} />
          <Route path="/Terms-Blasters-Football-Contest" component={this.WaitingComponent(TermsBlastersFootballContest)} />
          <Route path="/Popees-Contest" component={this.WaitingComponent(PopeesContest)} />
          <Route path="/Dtandc" component={this.WaitingComponent(Dtandc)} />
          <Route path="/UP4-RandR" component={this.WaitingComponent(Uprandr)} />
          <Route path="/supershopping" component={this.WaitingComponent(Supershopping)} />
          <Route path="/ranirajacontest" component={this.WaitingComponent(ranirajacontest)} />
          <Route path="/rulesandregulations" component={this.WaitingComponent(rulesandregulations)} />
          <Route path="/dubaicontest" component={this.WaitingComponent(dubaicontest)} />
          <Route path="/eas" component={this.WaitingComponent(eas)} />
        <Route path="/reels" component={this.WaitingComponent(Reels)}/>
          {/* <Route path="/reels"  component={this.WaitingComponent(Reels)} /> */}
          
          {/* <Route path="/kbcontest" component={this.WaitingComponent(kbcontest)} /> */}
          <Route path="/dubaicontest" component={this.WaitingComponent(dubaicontest)} />
          <Route path="/eas" component={this.WaitingComponent(eas)} />


          <Route path="/Frequently-Asked-Questions" component={this.WaitingComponent(Faq)} />
          <Route path="/Help" component={this.WaitingComponent(Faq)} />
          <Route path="/TVChannels" component={this.WaitingComponent(TVChannels)} />
          {/* <Route path="/redirect/vf" component={redirectToVFPlay} /> */}
          <Route path="/Watchlist" component={this.WaitingComponent(Watchlist)} />
          <Route path="/My-Watchlist" component={this.WaitingComponent(Watchlist)} />
          <Route path="/Favorites" component={this.WaitingComponent(Watchlist)} />
          <Route path="/Liked-Videos" component={this.WaitingComponent(Watchlist)} />
          <Route path="/Playlist" component={this.WaitingComponent(PlayList)} />
          <Route path="/device" component={Device} />
          <Route path="/activate" component={Device} />

          <Route path="/Contact" component={this.WaitingComponent(Contact)} />
          <Route path="/ptp-tandc" component={this.WaitingComponent(Ptptandc)} />
          <Route path="/save-date-tandc" component={this.WaitingComponent(Savedatetandc)} />
    
          
          <Route path="/Login" component={Login} />
          <Route path="/Registration" component={this.WaitingComponent(Registration)} />
          {/* <Route path="/Actorprofile/:actorname" component={Actorprofile} /> */}
          {/* <Route path="/Editprofile" component={this.WaitingComponent(Editprofile)} /> */}
          <Route path="/EditProfile" component={this.WaitingComponent(EditProfile1)} />
          {/* <Route path="/countries" component={Countries} /> */}
          {/* <Route path="/My-Stuff" component={Ordersubscription} /> */}
          {/* <Route path="/LayoutCenterCarousel" component={LayoutCenterCarousel} />
          <Route path="/LayoutCenterBanner" component={LayoutCenterBanner} />
           */}

          {/* <Route path="/LayoutProfileRadio" component={LayoutProfileRadio} /> */}
          {/* <Route path="/LayoutCastandCrew" component={LayoutCastandCrew} /> */}
           {/* <Route path="/LayoutSimilarNewsCarousel" component={LayoutSimilarNewsCarousel} /> */}
           {/* <Route path="/LayoutSimilarNewsCarousel" component={LayoutSimilarNewsCarousel} /> */}
           {/* <Route path="/LayoutTrailersCarousel" component={LayoutTrailersCarousel} /> */}
           
           <Route path="/About"  component= {this.WaitingComponent(About)} />
          
          
          {/* <Route path="/worlds" component={Worlds} /> */}
          {/* <Route path="/test" component={Test} /> */}
          {/* <Route path="/newstab" component={NewsTab} /> */}
          {/* <Route path="/ChangePassword" component={ChangePassword} /> */}
          {/* <Route path="/speednews" component={SpeedNews} /> */}
          {/* <Route path="/newsdetail" component={NewsDetail} /> */}
          <Route path="/Settings" component={this.WaitingComponent(Settings)} />
          

          <Route path="/Myaccount" component={this.WaitingComponent(Myaccount)} />
          <Route path="/subscribe" component={this.WaitingComponent(Subscribe)} />
          <Route path="/MySubscriptions" component={this.WaitingComponent(MySubscriptions)} />
          <Route path="/SubscriptionPlans" component={this.WaitingComponent(SubscriptionPlans)} />
           <Route path="/install" component={this.WaitingComponent(Redirection)} />
          <Route path="/Uptandc" component={this.WaitingComponent(Redirection)} />
          <Route
            path="/forgotpassword"
            component={this.WaitingComponent(ForgotPassword)}
          />
                    
          
          <Route
            path="/Privacy-Policy"
            component={this.WaitingComponent(PrivacyPolicy)}
          />          
          <Route path="/Udan-Panam-5" component={this.WaitingComponent(UdanPanam)} />
          <Route path="/loginFailed" component={this.WaitingComponent(loginFailed)} />
          <Route path="/:Page" component={Render} />
          
        </Switch>
        {this.state.menu.length > 0 && 
          <Route path="/" component={this.WaitingComponent(() => <Footer menu={this.state.menu} issonyliv={this.state.issonyliv}/>)}/>
        }
        {/*<Route path="/" component={this.WaitingComponent(Footer)} />*/}
      </div> : null 
    );
  }
}
export default BcnRoutes;
