import React from 'react';

const loader = props => {

    return ( <div id="pageloader">
        <div className="loader-item">
            <div className="mainspinner">
                <div className="loader loading"/>
            </div>
        </div>
    </div>)

};
export default loader;