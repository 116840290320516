export const clock = {
    totalSeconds: 0,
    start: function() {
        var self = this;
        this.interval = setInterval(function() {
            self.totalSeconds += 1;
            window.timer = parseInt(self.totalSeconds);
            // console.log( window.timer)
        }, 1000);
    },
    pause: function() {
        clearInterval(this.interval);
        delete this.interval;
    },
    resume: function() {
        if (!this.interval) this.start();
    },
    clear: function() {
        this.totalSeconds = 0;
    },
    destroy: function () {
        clearInterval(this.interval);
        delete this.interval;
    }
};