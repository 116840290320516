export const slugify = (string) =>{
    return string.toString().toLowerCase()
        .replace(/\s+/g, '-	') // Replace spaces with -
        .replace(/&/g, '-and-') // Replace & with 'and'
        // eslint-disable-next-line no-useless-escape
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        // eslint-disable-next-line no-useless-escape
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
};