 const mysessiongStorage=(carouselPositions,contentPostion,pageName,source,sourceName,id)=>{
    var storageValue =
    carouselPositions +
    "@@" +
    (contentPostion) +
    "@@" +
    pageName+"@@"+source+"@@"+sourceName;
    sessionStorage.setItem(id, storageValue);
    
}
export default mysessiongStorage