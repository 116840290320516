import React, { Component } from "react";
import top_close_icon from "../../assets/images/top_close_icon.png";
import manoramamax_icon from "../../assets/images/Manoramamax_icon_32.png";
import {isIOS} from "react-device-detect";

class Topheader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TopheaderClose: sessionStorage.getItem("installApp") || true,
    };
  }
  componentDidMount() {

    this.Handleclasses();
  }
  Handleclasses = () => {
    if(sessionStorage.getItem("installApp")==undefined || sessionStorage.getItem("installApp")===true){
      
        document.body.className += ' installappPopup';
    }
    

  };

  TopheaderClose = () => {
    this.setState({ TopheaderClose: false });
    if (document.querySelector(".sliderbody, .player_section, .footer_details, .viewall_rectangle") != null) {
    document
      .querySelector(
        ".sliderbody, .player_section, .footer_details, .viewall_rectangle, .watchlist_content, .my_profile_page"
      )
      .classList.remove("topheader_banner");
    }
    document.querySelector(".trigger").classList.remove("trigger_topheader");
    document
      .querySelector(".carousel_body") && document
      .querySelector(".carousel_body")
      .classList.remove("newsNestedLayout");
    if (document.querySelector(".footer_details, .viewall_rectangle") != null) {
      document
        .querySelector(".footer_details, .viewall_rectangle")
        .classList.remove("topheader_faqs");
    }
    document.querySelector('body').classList.remove('installappPopup');
    sessionStorage.setItem("installApp", false);
  };

  render() {
    return (
      <div>
        {this.state.TopheaderClose === true && (
          <div className="installapp">
            <div className="row install_row">
              <div className="col-xs-8 ">
                <div className="topheader_bgimg">
                  <div className="topheader_logo" onClick={this.TopheaderClose}>
                    <img src={top_close_icon} />
                  </div>

                  <div
                    className="topheader_mnr_logo"
                    onClick={this.TopheaderClose}
                  >
                    <img src={manoramamax_icon} />
                  </div>

                  <div className="topheader_data">
                    <h5>Download manoramaMAX</h5>
                    <h6>Enjoy a seamless experience!</h6>
                  </div>
                </div>
              </div>
              <div className="col-xs-4">
                <button type="button" className="btn-install">
                { isIOS ? 
                <a
                    // onClick={this._installApp}
                    href="https://apps.apple.com/us/app/manoramamax/id1468454034"
                  >
                    INSTALL APP
                  </a>
                :
                  <a
                    // onClick={this._installApp}
                    href="https://play.google.com/store/apps/details?id=com.mmtv.manoramamax.android"
                  >
                    INSTALL APP
                  </a>
                }
                </button>
              </div>
            </div>
          </div>

        )}
        {/* this.state.Handleclasses() */}
      </div>
    );
  }
}

export default Topheader;
