
//import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
//import view_all_arrow_mm from "../../assets/images/view_all_arrow_mm.png";

//
import React, { Component } from "react";
import Img from "../Uicomponents/Img";

import "react-owl-carousel2/src/owl.carousel.css";
//import StarRatings from 'react-star-ratings';

import "react-owl-carousel2/src/owl.theme.default.css";
import { Link } from "react-router-dom";
import myplexAPI, { URL_CAROUSELCONTENT, URL_MENU } from "../../api/myplexAPI";
import "./Carousel.css";
import ParseImgURL from "../../utils/parseImgURL";
//import watchlist_remove_icon from "../../assets/images/watchlist_remove_icon.png";
// import secureImages from "../../utils/secureImages";
import secureImages from "../../../commonfiles/secureImages";

import indexedDB from "../../utils/indexedDB";
// import { slugify } from "../../utils/stringSlugify";
import { slugify } from "../../../commonfiles/stringSlugify";

//import { carouselPatnerLogos } from "../../utils/partnerLogos";
//import LazyLoad from "react-lazyload";
// import { getURLName } from "../../utils/urlMenu";
import { getURLName } from "../../../commonfiles/urlMenu";

import { withRouter } from "react-router";
import placeholder_vertical from "../../assets/images/videos___shows_placeholder.png";
import { isMobile } from "react-device-detect";
//import viewall_arrow from "../../assets/images/view_all_arrow.png";
//import carousel_freetag from "../../assets/images/freetag_thumbnail.png";
import sessionFun from "../../../commonfiles/SessionStorage"


class SpeedNews extends Component {
    list = '';
    constructor(props) {
      super(props);
      // console.log(props)
      this.state = {
        caroselimages: [],
        pageName: "home",
        trackingId: '',
  
      };
      this.carousel_info_name = this.props.caroselinfo.name;
      this._isMounted = false;
      this.auth = localStorage.getItem("Logged-in");
  
    }
    componentDidMount() {
      this._isMounted = true;
  
  
  
      const page_name = window.location.pathname.split("/")[1];
      if (page_name !== "") {
        this.setState({
          pageName: page_name
        });
        // if (page_name === 'Watch-History') {
        //   this.getSsoClientId();
  
        // }
      }
      this.loadCarouselsData();
    }
    componentDidUpdate(prevsprops) {
      if (this.props.caroselinfo.name !== prevsprops.caroselinfo.name) {
        this.loadCarouselsData();
      }
    }
    componentWillUnmount() {
      localStorage.removeItem("Languagechecklist", "");
  
      this._isMounted = false;
    }
    // getSsoClientId = () => {
    //   myplexAPI.getSsoAppInfo().then(response => {
    //     // console.log(response)
    //     if (response.data.code === 200 && response.data.status === 'SUCCESS') {
  
    //       window.location.href = `https://id.manoramaonline.com/authorize?scope=openid+email+profile+offline_access&response_type=code+token+id_token&client_id=${response.data.app_id}&redirect_uri=https://pwastagmmtv.myplex.com/login&state=1135778640&nonce=212883085`
    //     }
  
    //   })
    // }
    loadCarouselsData() {
      // eslint-disable-next-line
      {
        this.carousel_info_name !== "portalMobile_Discovery" &&
          this.carousel_info_name !== "portaldiscovery" &&
          this.carousel_info_name !== "portalMobileDiscovery" &&
          this.carousel_info_name !== "Portal_popular_genres" &&
          this.carousel_info_name !== "Portal_popular_languages"
          ? this._getCarouselsData(1)
          : this._getCarouselsData(0);
      }
    }
    _getCarouselsData = e => {
      const that = this;
      let url = "";
      const carousel_name = this.props.caroselinfo.name;
      // console.log(carousel_name)
      const network = window.navigator.onLine;
      if (e === 1) {
        url = URL_CAROUSELCONTENT(carousel_name, 1, 10,this.props.caroselinfo.modified_on);
      } else {
        url = URL_MENU(carousel_name);
      }
      // console.log(carousel_name,carousel_name.match(/MyReco/g))
      if (
        carousel_name !== "continue_watching_portal_mobile" ||
        carousel_name !== "IMT_portal_continue_watching" ||
        carousel_name !== "portal_continue_watching" ||
        carousel_name.match(/MyReco/g) === null
      ) {
        // console.log(carousel_name)
        indexedDB(url).then(function (response) {
          if (response !== false && that._isMounted) {
            that.setState({
              caroselimages: response
            });
          } else {
            that._fetchCarousels(e, carousel_name);
          }
        });
      } else {
        if (network === true) {
          this._fetchCarousels(e, carousel_name);
        }
      }
    };
    _fetchCarousels = (e, carousel_name) => {
      const that = this;
      if (e === 1) {
        if (
          carousel_name === "continue_watching_portal_mobile" ||
          carousel_name === "IMT_portal_continue_watching" ||
          carousel_name === "portal_continue_watching" ||
          carousel_name.match(/MyReco/g) !== null
        ) {
          myplexAPI
            .getContinueWatchingData(carousel_name, 1, 10)
            .then(response => {
              // console.log(carousel_name, response)
  
              if (response !== null) {
                if (response.data !== null && this._isMounted) {
                  that._responseData(response, 1);
                  this.setState({
                    trackingId: response.data.trackingId
                  })
                }
              }
            });
        } else {
          this.list = localStorage.getItem('selectedLanguageList');
          myplexAPI.getCarouselData(carousel_name, 1, 10, this.props.caroselinfo.modified_on).then(response => {
            // console.log(carousel_name, response)
            that._responseData(response);
  
          });
        }
      } else {
        this.list = localStorage.getItem('selectedLanguageList');
        this.appLanguage = localStorage.getItem('selectedAppLanguageList')
  
        myplexAPI.pullPageData(carousel_name, this.list, this.appLanguage).then(response => {
          // console.log(response)
          that._responseData(response);
        });
      }
    };
    _responseData = (response, flag = 0) => {
      // console.log(response.data.status)
      if (response.data && response.data.status && response.data.status === "SUCCESS" && this._isMounted) {
        this.setState({
          caroselimages: response.data.results
        });
        if (flag === 0) {
          myplexAPI.storeIndexedDb(
            response.request.responseURL,
            response.data.results
          );
        }
      }
    };
    decodeHTMLEntities = text => {
      var entities = {
        amp: "&",
        apos: "'",
        lt: "<",
        gt: ">",
        quot: '"',
        nbsp: "\xa0"
      };
      var entityPattern = /&([a-z]+);/gi;
  
      return text.replace(entityPattern, function (match, entity) {
        entity = entity.toLowerCase();
        if (entities.hasOwnProperty(entity)) {
          return entities[entity];
        }
  
        // return original string if there is no matching entity (no replace)
        return match;
      });
    };
  
    carouselClick = (URL, type, title, images, description) => {
      // console.log(URL)
      // console.log(type)
      // console.log(title)
      // console.log(images)
      // console.log(description)
  
      var src;
      if (isMobile) {
        src = ParseImgURL(images.values, "mdpi", "coverposter"); //images.values[0].link;
      } else {
  
        src = ParseImgURL(images.values, "xxhdpi", "coverposter");
      }
  
  
  
      localStorage.setItem('actorImage', src)
      localStorage.setItem('actorType', type)
      localStorage.setItem('personDecription', description)
  
      if (this.auth) {
  
        if (type !== 'person' && type !== 'actor') {
  
          this.props.history.push(URL);
  
        } else {
          this.props.history.push(`/Actorprofile/${title}`)
        }
      } else {
        if (type !== 'person' && type !== 'actor') {
          localStorage.setItem('redirectUrl', URL);
          this.props.history.push(URL);
  
  
          // this.props.history.push("/login");
  
        } else {
          this.props.history.push(`/Actorprofile/${title}`)
        }
  
        //  this.props.history.push("/login");
      }
  
    }
    viewall = () => {
      localStorage.setItem('texture', JSON.stringify(this.props.texture))
    }
    caroselimages = () => {
  
      return this.props.layoutType !== "nestedCarousel"
        ? this.state.caroselimages.map((caroselimg, i) => {
          // var logo = null;
          // if (caroselimg.publishingHouse !== undefined) {  
          //   let partnerLogos = carouselPatnerLogos(
          //     caroselimg.publishingHouse.publishingHouseName
          //   );
          //   if (
          //     partnerLogos.link !== undefined &&
          //     this.props.layoutType !== "horizontalListSmallItem" &&
          //     this.props.layoutType !== "horizontalListMediumItem"
          //   ) {
          //     logo = (
          //       <LazyLoad once>
          //         <img
          //           src={partnerLogos.link}
          //           alt={partnerLogos.name}
          //           className="carousel_logo_patner"
          //         />
          //       </LazyLoad>
          //     );
          //   }
          // }
          sessionFun(this.props.carousel_count,i+1,this.state.pageName,'carosuel',this.props.caroselinfo.title,caroselimg.generalInfo._id);
          // var firebaseValue = this.props.carousel_count + '-' + (i + 1) + '-' + this.state.pageName+"-"+this.props.caroselinfo.title;
          // sessionStorage.setItem(caroselimg.generalInfo._id, firebaseValue);
          // console.log(caroselimg.title,caroselimg.content.genre)
          // console.log(caroselimg)
          return (
            // <Link
            //   to={{
            //     pathname: `/${getURLName(
            //       caroselimg,
            //       this.state.pageName
            //     )}/detail/${caroselimg.generalInfo._id}/${slugify(
            //       caroselimg.generalInfo.title
            //     )}`
            //     // search: `?videotype=${caroselimg.generalInfo.type}`
            //   }}
            //   key={i}
            // >
  
            // <div className="carousel_img">
            <div className="speed_news_info col-sm-4" onClick={() => this.carouselClick(`/${getURLName(
              caroselimg,
              this.state.pageName
            )}/detail/${caroselimg.generalInfo._id}/${slugify(
              caroselimg.generalInfo.title
            )}`, caroselimg.generalInfo.type, caroselimg.generalInfo.title, caroselimg.images, caroselimg.generalInfo.description)} key={i}>
             
              <Img
                caroselimg={caroselimg}
                key={i}
                banner={false}
                layouttype={this.props.layoutType}
                caroselname={this.props.caroselinfo.name}
                observer={true}
                myCustomClass="speed_news_img"
              />
            <div className="news_titles">
              <h3>{caroselimg.generalInfo.title}</h3>
                   <p>{this.getYear(caroselimg.content.releaseDate)}</p>
  
             </div>
             
           
           
            </div>
          );
        })
        : this.state.caroselimages.map((caroselimg, i) => {
          // console.log(caroselimg.title);
          // console.log(caroselimg);
  
          // console.log(caroselimg.images.values[1].link);
          var neimg = caroselimg.images && caroselimg.images[1] && caroselimg.images[1].link ? caroselimg.images[1].link : placeholder_vertical;
          return (
            <Link
              to={{
                pathname: `/${this.state.pageName}/filter`,
                search: this.checkFilterValues(caroselimg.title)
              }}
              key={i}
            >
              <img
                src={secureImages(neimg)}
                alt={caroselimg.title}
                className="genres_languages"
              />
            </Link>
          );
        });
    };
    checkFilterValues = title => {
      return this.carousel_info_name === "Portal_popular_genres"
        ? `?type=movie&genres=${title}`
        : `?type=movie&filtervalue=${title}`;
    };
    getThumbnailLiveTv = item => {
      //console.table(item.images.values);
      return ParseImgURL(item.images.values, "mdpi", "thumbnail");
    };
    getPartnerLogo = array => {
      return array.find(function (e) {
        return e.profile === "hdpi";
      });
    };
    getclassName = link => {
      // var result = link.find(function(e) {
      //   return e.profile === "hdpi";
      // });
      var result = this.getPartnerLogo(link);
      let imgclass;
      let issquare = result.link.includes("square");
      let ishook = result.link.includes("hook");
      let ishungama = result.link.includes("hungama");
      let isarre = result.link.includes("arre");
      if (issquare) {
        imgclass = "squareimage";
      } else if (ishook) {
        imgclass = "hookimage";
      } else if (ishungama) {
        imgclass = "hungamaimage";
      } else if (isarre) {
        imgclass = "arreimage";
      } else {
        imgclass = "logo";
      }
      return imgclass;
    };
    sourceDetails = (source_details, trackingId) => {
      // console.log(trackingId)
      // console.log(source_details)
      localStorage.setItem("source details", source_details);
      localStorage.setItem("source", "carousel");
      localStorage.setItem('trackingId', trackingId)
    };
  
    getYear = date => {
      if (!date) {
        return null;
      }
      let d = new Date(date);
      let relDate=`${d.getDate()}-${d.toLocaleString('default', { month: 'short' })}-${d.getFullYear()}`;
      
      // let relDate=`${d.getDate()}-${d.toLocaleString('default', { month: 'short' })}-${d.getFullYear()}  ${d.getUTCHours()<10?"0"+ d.getUTCHours():d.getUTCHours()}:${d.getUTCMinutes()<10?"0"+d.getUTCMinutes():d.getUTCMinutes()}:${d.getUTCSeconds()<10?"0"+d.getUTCSeconds():d.getUTCSeconds()}`;
      return relDate; 
    };
    render() {
        //let title=this.props.caroselinfo.title;
        // const settings = {
        //     dots: false,
        //     infinite: true,
        //     slidesToShow: 3,
        //     slidesToScroll: 1,
        //     vertical: true,
        //     arrows: false,
        //     autoplay: true,
        //     autoplaySpeed: 2000,
        //     verticalSwiping: true,
        //     beforeChange: function(currentSlide, nextSlide) {
        //       console.log("before change", currentSlide, nextSlide);
        //     },
        //     afterChange: function(currentSlide) {
        //       console.log("after change", currentSlide);
        //     }
        //   };
        return (
            <div className="speed_news_part">
               
                 <div className="container">
                 {this.caroselimages()}    
              {/* <div className="speed_news_info col-sm-4">
                    <img src={'https://d25tmfuvd960zw.cloudfront.net/300001/1280x720_SampleNewsclip02_300001_8a0e4e61-9202-4f09-aee2-97369070a5d6.jpg'} className="speed_news_img" />
                    <div className="news_titles">
                    <h3>Lorem Ipsum 8.30 AM</h3>
                    <p>Lorem Ipsum 25,2021</p>
                    </div>
                </div>
                <div className="speed_news_info col-sm-4">
                    <img src={'https://d25tmfuvd960zw.cloudfront.net/300005/1280x720_DemononDrm_300005_0f16ce1a-caf6-44eb-8477-e512fb1389a4.jpeg'} className="speed_news_img" />
                    <div className="news_titles">
                    <h3>Lorem Ipsum 8.30 AM</h3>
                    <p>Lorem Ipsum 25,2021</p>
                    </div>
                </div>
                <div className="speed_news_info col-sm-4">
                    <img src={'https://d25tmfuvd960zw.cloudfront.net/300000/1280x720_SampleMovie02_300000_933e4260-9b11-460e-a1e6-8421dc12b247.jpg'} className="speed_news_img" />
                    <div className="news_titles">
                    <h3>Lorem Ipsum 8.30 AM</h3>
                    <p>Lorem Ipsum 25,2021</p>
                </div>
        </div> */}
                </div> 
            </div>
        );
    }
}

export default withRouter(SpeedNews);