import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { Form } from "react-bootstrap/lib/Navbar";
import closeicon from "../../assets/images/close-circle-outline.png";
import success_illustration from "../../assets/images/success_illustration.png";
import "./popupmodal.css";
import myplexAPI from "../../api/myplexAPI";
import DemoGraphicCongratsPopup from "./DemoGraphicCongratsPopup";
import {isPremimumUser,isDemographicPlan} from "../../../../src/manorama/utils/portalhelper";
import {
	ToastsContainer,
	ToastsStore,
	ToastsContainerPosition,
  } from "react-toasts";
function Popupmodal(props) {
    const [show, setShow] = useState(false);
    //const [reward, setreward] = useState(false);
    //const [properties, setProperties] = useState('');
    const [gender, setGender] = useState("");
    const [selectedYear, setSelectedYear] = useState("");
    const [yearsList, setYearsList] = useState([]);
    const [error, setError] = useState('');
    const [genderError, setGenderError] = useState('');
    const [yearError, setYearError] = useState('');
    const [popupTitle, setPopupTitle] = useState('');
    const [popupText, setPopupText] = useState('');
    const [popupButtonText, setPopupButtonText] = useState('');
    const [popupClickText, setPopuplickText] = useState('');
    const [pwaDemographicSuccessPopup, setPwaDemographicSuccessPopup] = useState(false);
    const [profileUpdatepopupTerms, setProfileUpdatepopupTerms] = useState('');
    const [buttondisabled,setButtondisabled] = useState(false);
    useEffect(() => {        
        // data();
        //getproperties();
        setYears();
        assignData();    
    }, [props]);
    useEffect(() => {
        if(localStorage.getItem('Logged-in') === undefined || localStorage.getItem('Logged-in') === null){
            setPopupTitle(props.pwaDemographicConfig.signUp_Update_popup_title);
            setPopupText(props.pwaDemographicConfig.signUp_Update_popup_message);
            setPopupButtonText(props.pwaDemographicConfig.signUp_Update_popup_submit_button_text);
            setPopuplickText(props.pwaDemographicConfig.signUp_Update_popup_click_text);
        }else{
            setPopupTitle(props.pwaDemographicConfig.profileUpdate_popup_title);
            setPopupText(props.pwaDemographicConfig.profileUpdate_popup_message);
            setPopupButtonText(props.pwaDemographicConfig.profileUpdate_popup_submit_button_text);
            setPopuplickText(props.pwaDemographicConfig.profileUpdate_popup_click_text);
        }
        setProfileUpdatepopupTerms(props.pwaDemographicConfig.profileUpdate_popup_Terms);
        var date = new Date();
        var month = date.getMonth() + 1;
        var todayDate = date.getDate()+'-'+month+'-'+date.getFullYear();
        var demoGraphicPopupLocalShoweddata = {};
        if(localStorage.getItem("demoGraphicPopupShoweddata") !== undefined && localStorage.getItem("demoGraphicPopupShoweddata") !== null){
            demoGraphicPopupLocalShoweddata = JSON.parse(localStorage.getItem("demoGraphicPopupShoweddata"));
        }
        if(props && props.pageType && props.pageType === 'landing'){
            var count = demoGraphicPopupLocalShoweddata.count !== undefined && demoGraphicPopupLocalShoweddata.count !== null ? demoGraphicPopupLocalShoweddata.count : 0;
            if(demoGraphicPopupLocalShoweddata.date !== todayDate && count < props.pwaDemographicConfig.profileUpdate_popup_later_limit){
                var demoGraphicPopupShoweddata = {};
                demoGraphicPopupShoweddata['date'] = todayDate;
                demoGraphicPopupShoweddata['count'] = demoGraphicPopupLocalShoweddata.count !== undefined && demoGraphicPopupLocalShoweddata.count !== null ? demoGraphicPopupLocalShoweddata.count + 1 : 1;
                //console.log("demoGraphicPopupShoweddata ",demoGraphicPopupShoweddata);
                localStorage.setItem("demoGraphicPopupShoweddata",JSON.stringify(demoGraphicPopupShoweddata));
                setShow(props.showPopup);                
            }else{
                if(count === props.pwaDemographicConfig.profileUpdate_popup_later_limit){
                    localStorage.setItem("demoGraphicShowBanner",'true');
                }                    
            }
        }else{
            setShow(props.showPopup);
        }
    },[]);
    const assignData = () => {
        var demographicFilledData = localStorage.getItem("demographicData");
        if(demographicFilledData !== undefined && demographicFilledData !== null){
            var demographic_data = JSON.parse(demographicFilledData);
            if(demographic_data !== undefined && demographic_data.dod){
                setSelectedYear(demographic_data.dod);
            }
            if(demographic_data !== undefined && demographic_data.gender){
                setGender(demographic_data.gender)
            }
        }else{
            if(localStorage.getItem("dob") !== undefined && localStorage.getItem("dob") !== null && localStorage.getItem("dob") !== ''){
                var dob_profile =  localStorage.getItem("dob").split("-");
                if(dob_profile.length > 0){
                    setSelectedYear(dob_profile[0]);
                }
            }
            if(localStorage.getItem("gender") !== undefined && localStorage.getItem("gender") !== null && localStorage.getItem("gender") !== ''){
                setGender(localStorage.getItem("gender"))
            }
        }
    }
    // const data = () => {
    //     setreward(true);
    // }
    // const getproperties = () => {
    //     if(sessionStorage.getItem('properties') !== undefined && sessionStorage.getItem('properties') !== null){
    //         myplexAPI.properties().then(response => {
    //             if(response.data.code === 200 && response.data.code === 'SUCCESS' && response.data.properties){
    //               sessionStorage.setItem('properties',JSON.stringify(response.data.properties));
    //             }   
    //             setProperties(response.data.properties);
    //         });
    //     }else{
    //         setProperties(JSON.parse(sessionStorage.getItem('properties')));
    //     }    
    // }
    const close = () => {
        props.setpopup(false);
    }
    const selectGender = (e) => {
        if (e.target.checked === true) {
            setGender(e.target.value);
        } else if (e.target.checked === false) {
            setGender('');
        }
    }
    const selectYear = (e) => {
        setSelectedYear(e.target.value);
    }
    const setYears = () => {
        var date = new Date();
        var currentYear = date.getFullYear();
        var year = currentYear-18;
        let yearItems = [];
        for (var i = 1900;i <= year; i++){
            yearItems.push(i);
        }
        setYearsList(yearItems.reverse());
    }
    const getYears = () =>
        yearsList.map((value, i) => {
			return (
				<option value={value} key={i}>
					{value}
				</option>
			);
	});
    const submitDemographicData = () => {
        setError("");
        setGenderError("");
        setYearError("");
        //console.log("gender ",gender);
        if(gender === ''){
            setGenderError("Please select Gender to proceed");
            return false;
        }
        if(selectedYear === ''){
            setYearError("Please select Year of Birth to proceed");
            return false;
        }
        var data = {"gender":gender,"dod":selectedYear}
        var countryLocation = sessionStorage.getItem('user_country');
        if(countryLocation && countryLocation === 'IN'){
            if(localStorage.getItem("Logged-in") === 'true'){
                //saveDemographicData(data);
                var packages = JSON.parse(sessionStorage.getItem('userPackages'));
                var isDemographicPlanRes = '';
                if(packages !== undefined && packages !== null && packages.results.length > 0){
                    isDemographicPlanRes = isDemographicPlan(packages.results,'DEMOGRAPHIC');
                }
                var isNotPremimumUser = isPremimumUser();
                if(isDemographicPlanRes && isDemographicPlanRes !== '' && isDemographicPlanRes.packName && isDemographicPlanRes.packName === 'DEMOGRAPHIC'){
                    ToastsStore.info("You have already claimed the offer");
                    removeDemographicLocalstorageData();
                    props.setpopup(false);
                }else if(!isNotPremimumUser){
                    ToastsStore.info("You are not eligible for the offer");                
                    removeDemographicLocalstorageData();
                    props.setpopup(false);
                }else{
                    saveDemographicData(data);
                }
            }else{
                ToastsStore.info(props.pwaDemographicConfig.profileUpdate_popup_eligibility);
                localStorage.setItem("demographicData",JSON.stringify(data));
                localStorage.setItem("demographicSubmitClick","true");
                getSsoClientId();
            }
        }
        
    }
    const getSsoClientId = () => {
		myplexAPI.getSsoAppInfo().then((response) => {
			// console.log(response)
			if (response.data.code === 200 && response.data.status === "SUCCESS") {
				localStorage.setItem("client_id", response.data.app_id);
				window.location.href = `https://id.manoramaonline.com/authorize?scope=openid+email+profile+offline_access+mobile&response_type=code+token+id_token&client_id=${response.data.app_id}&redirect_uri=${process.env.REACT_APP_DOMAINNAME}login&state=1135778640&nonce=212883085`;
			}
		});
	};
    const saveDemographicData = (data) => {
        setButtondisabled(true);
        var isNotPremimumUser = isPremimumUser();
        if(isNotPremimumUser){      
            myplexAPI.demographicData(data).then(response => {
                //console.log("response - ",response);
                if (response.data.code === 200 && response.data.status === "SUCCESS") {
                    setButtondisabled(false);
                    setPwaDemographicSuccessPopup(true);
                    // localStorage.removeItem("demographicData");
                    // localStorage.removeItem("demographicSubmitClick");
                    // localStorage.removeItem("demoGraphicPopupShoweddata");
                    // localStorage.removeItem("demoGraphicShowBanner");
                    removeDemographicLocalstorageData();
                }else{
                    setButtondisabled(false);
                    ToastsStore.info(response.data.message);
                }
            });
        }else{
            setButtondisabled(false)
        }
    }
    const setDemoGraphicCongratsPopup = (status) => {
        setPwaDemographicSuccessPopup(status);
    }
    const removeDemographicLocalstorageData = () => {
        // localStorage.removeItem("demographicData");
        localStorage.removeItem("demographicSubmitClick");
        // localStorage.removeItem("demoGraphicPopupShoweddata");
        // localStorage.removeItem("demoGraphicShowBanner");
    }
    const closePopUp = (status) => {
        props.setpopup(status);
    }
    return (
        <>
            {props && props.pwaDemographicConfig && Object.keys(props.pwaDemographicConfig).length > 0 &&
            <Modal
                className="popup_registration"
                show={show}
                //onHide={show}
            >
                <div className="complete_registerpopup">
                    <ModalHeader>
                        <ModalTitle>{popupTitle}</ModalTitle>
                        <img src={closeicon} className="closeicon" alt="closeicon" onClick={()=>close()}/>
                     
                    </ModalHeader>
                    <ModalBody>
                        <div className="popup_content">
                            <p className="popup_text">{popupText}</p>
                            <form>
                                <div>{error && <p>{error}</p>}</div>
                                <div className="select_data">
                                    <p>Select your Gender</p>
                                    <div className="gender_selection">
                                        <input type="radio" name="gender" value="M" id="male" defaultChecked={gender==="M"} onChange={(e)=>selectGender(e)} />
                                        <label htmlFor="male">Male</label>
                                        <input type="radio" name="gender" value="F" id="female" defaultChecked={gender==="F"} onChange={(e)=>selectGender(e)} />
                                        <label htmlFor="female">Female</label>
                                        <input type="radio" name="gender" value="O" id="others" defaultChecked={gender==="O"} onChange={(e)=>selectGender(e)} />
                                        <label htmlFor="others">Others</label>
                                    </div>
                                    {genderError && <p>{genderError}</p>}
                                </div>
                                <div className="years_birth">
                                    <label htmlFor="years">Year of Birth</label>
                                    <select name="years" id="years" form="yearsform" onChange={(e)=>selectYear(e)} value={selectedYear} >
                                        <option value="select">select</option>
                                        {yearsList && yearsList.length > 0 && getYears()}
                                    </select>
                                    {yearError && <p>{yearError}</p>}
                                </div>
                                <button type="button" className="submit_btn" disabled={buttondisabled} onClick={()=>submitDemographicData()}>{popupButtonText}</button>
                                <p className="claim_reward">{popupClickText}</p>                                
                            </form>                                                 
                        </div>
                        <div className="popupterms">{profileUpdatepopupTerms}</div>
                    </ModalBody>
                </div>

                {/* } */}
            </Modal>
            }
            {pwaDemographicSuccessPopup && <DemoGraphicCongratsPopup pwaDemographicSuccessPopup={pwaDemographicSuccessPopup} setDemoGraphicCongratsPopup={setDemoGraphicCongratsPopup} pwaDemographicConfig={props.pwaDemographicConfig} closePopUp={closePopUp} />}
            <ToastsContainer
            store={ToastsStore}
            position={ToastsContainerPosition.BOTTOM_CENTER}
            />

        </>
    );
}
export default Popupmodal;
