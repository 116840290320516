
export const getURLName = ( content_info, page_name ) => {
    try {
        // console.log(content_info.tags);
        // console.log(content_info.publishingHouse);
        // console.log(content_info.generalInfo.type);
        const content_type = content_info.generalInfo.type;
        // console.log(page_name);
        if (content_type !== '') {
            if (content_type === 'movie') {
                return 'movies'
            }else if(content_type === 'trailer'){
                return 'trailer'
            }
            else if(content_type === 'news'){
                return 'news'
            }
            else if(content_type === 'live' || content_type === 'program'){
                // Checking news || TV
                if(typeof content_info.content.genre !== undefined){
                    if(content_info.content.genre.length === 1){ //
                        const genre_name = content_info.content.genre[0].name;
                        if(genre_name === 'News'){
                            return 'news'
                        }else{
                            return 'tv'
                        }
                    }
                }
            }
            else if(content_type === 'musicvideo'){
                return 'music'
            }
    
            else if(content_type === 'tvseries' || content_type === 'tvseason' || content_type === 'tvepisode'){
                return 'programs'
            } 
            
            else {

                if(content_info.tags && content_info.tags.length > 0) {
                    let result = content_info.tags.find(
                        requestobj => requestobj.name === "type_original"
                      );
                      if(result && result.name === 'type_original'){
                            return 'originals'
                      }else{
                        return 'tvshows';
                      }
                }else if(content_info.content.tags && content_info.content.tags.length > 0){
                    let result = content_info.content.tags.find(
                        requestobj => requestobj.name === "type_original"
                    );
                    if(result && result.name === 'type_original'){
                        return 'originals'
                    }else{
                        return 'tvshows';
                    }
                }
                  return 'tvshows';
            }
        }else{
            //If we are not getting content type from API, It will be highlighted
            // current menu
            return page_name;
        }
    }catch (e) {
        console.log(e);
        //If any error will come based on contents on the menu
        // that will be highlighted
        return page_name;
    }
};