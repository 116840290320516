import CryptoJS from "crypto-js";

const iv = CryptoJS.enc.Hex.parse("0000000000000000");
export const encryption = (plain_text, key) => {
  try {
    const secretKey = CryptoJS.enc.Utf8.parse(key);
    const encrypted_data = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(plain_text),
      secretKey,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC
      }
    );
    return encrypted_data.toString();
  } catch (e) {
    console.log("Through exception while Encrypt the data - " + e);
  }
  return null;
};
export const decryption = (cipher_text, key) => {
  try {
    const secretKey = CryptoJS.enc.Utf8.parse(key);
    const decrypted_data = CryptoJS.AES.decrypt(cipher_text, secretKey, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC
    });
    return decrypted_data.toString(CryptoJS.enc.Utf8);
  } catch (e) {
    console.log("Through exception while Decrypt the data - " + e);
  }
  return null;
};
