// export default App;
import { useState, useEffect, useRef } from "react";
//import BottomNav from "../../shorts/BottomNav";
import VideoCard from "../../shorts/VideoCard";
import myplexAPI from "../api/myplexAPI";
//import placeholder_horizontal from "../assets/images/movie_thumbnail_placeholder_main2.png";
import ParseImgURL from "../utils/parseImgURL";
import { isMobile } from "react-device-detect";
import { redirectPlayStore } from "../utils/portalhelper";
function Scroll(props) {
	const [videos, setvideos] = useState([]);
	const [videosLoaded, setvideosLoaded] = useState(false);
	const [startIndex, setStartIndex] = useState(1);
	const [muteStatus, setmuteStatus] = useState(false);
	const promotionId = useRef("");
	const getVideos = () => {
		let time = new Date().getTime();
		//console.log("promotionId ",promotionId.current);
		myplexAPI
			.getCarouselData(
				props.caroselinfo.name,
				startIndex,
				props.caroselinfo.pageSize,
				props.caroselinfo.modified_on,
				time,
				promotionId.current
			)
			.then((response) => {
				if (response.data.status === "SUCCESS") {
					//console.log("response - ",response);
					promotionId.current = "";
					var newVideos = [];
					if (response.data.results.length > 0) {
						newVideos = response.data.results;
						setvideos((oldVideos) => [...oldVideos, ...newVideos]);
						setvideosLoaded(true);
						setStartIndex(startIndex + 1);
					}
				} else {
					
				}
			}).catch(error => {
				console.log("Error - ", error)
			});
	};
	useEffect(() => {
		var head = document.head;
		var link = document.createElement("link");
		var stylePath = "/videojs/Shorts.css";
		link.type = "text/css";
		link.rel = "stylesheet";
		link.href = stylePath;
		head.appendChild(link);
		//console.log("path name ", window.location.pathname.split("/"));
		var pathName = window.location.pathname.split("/");
		if (pathName[2] && pathName[2] !== undefined && pathName[2] !== null) {
			promotionId.current = pathName[2];
		}
		if(isMobile && pathName[2] && pathName[2] !== undefined && pathName[2] !== null) {
			redirectPlayStore(promotionId.current,"minis");
		}
		getVideos();
		return () => { head.removeChild(link); }
	}, []);
	return (
		<main>
			<div className="slider-container">
				{videos.length > 0 ? (
					<>
						{videos.map((video, id) => (
							<VideoCard
								key={id}
								index={id}
								author={video.generalInfo.title}
								videoURL={video.previews !== undefined && Object.keys(video.previews).length > 0 && video.previews.values.length > 0 ? video.previews.values[0].link : ''}
								//videoURL={video}
								authorLink='Manorama'
								lastVideoIndex={videos.length - 1}
								getVideos={getVideos}
								poster={ParseImgURL(video.images !== undefined && Object.keys(video.images).length > 0 && video.images.values.length > 0 ? video.images.values : [], "mdpi", "thumbnail")}
								description={video.generalInfo.description}
								item={video}
								muteStatus={setmuteStatus}
								muted={muteStatus}
							/>
						))}
					</>
				) : (
					<>
						<span>Nothing to show here</span>
					</>
				)}
				
			</div>
			{/* <BottomNav /> */}
		</main>
	);
}
export default Scroll;
