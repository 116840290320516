import { useRef, useState, useEffect } from "react";
import useIsInViewport from "../useIsInViewport";
// import like_icon_default from "../manorama/assets/images/like_icon_default.png";
// import like_icon_highlighted from "../manorama/assets/images/like_icon_selected.png";
// import share_icon_default from "../manorama/assets/images/share_icon_default.png";
import mute from "../manorama/assets/images/mute.png";
import unmute from "../manorama/assets/images/unmute.png";
import Sharelike from "./Sharelike";
// import playIcon from "../manorama/assets/images/play_icon_detail.png";
// import pauseIcon from "../manorama/assets/images/Pause.png";
import placeholder_horizontal from "../manorama/assets/images/movie_thumbnail_placeholder_main2.png";
import myplexAPI from "../../src/manorama/api/myplexAPI";
const VideoCard = ({
	index,
	author,
	videoURL,
	authorLink,
	lastVideoIndex,
	getVideos,
	poster,
	description,
	item,
	muteStatus,
	//muted
}) => {
	const video = useRef();
	const isInViewport = useIsInViewport(video);
	const [loadNewVidsAt, setloadNewVidsAt] = useState(lastVideoIndex);
	const [activemute, setunmute] = useState(false);
	// const [Play_pause_icon, setPlay_pause_icon] = useState("pause");
	// const [playPauseSection, setplayPauseSection] = useState(false);
	const isPassed = useRef(false);
	// if (isInViewport) {		
	// 	setTimeout(() => {
	// 		video.current.play();
	// 	}, 1000);
	// 	// console.log("loadNewVidsAt ",loadNewVidsAt);
	// 	// console.log("video current id ",Number(video.current.id));
	// 	if (loadNewVidsAt === Number(video.current.id)) {
	// 		setloadNewVidsAt((prev) => prev + 10);
	// 		getVideos();
	// 	}
	// }
	const toggleClass = () => {
		if (video.current.muted) {
			video.current.muted = false;
		} else {
			video.current.muted = true;
		}
		setunmute(!activemute);
		muteStatus(!activemute)
	};
	const togglePlay = () => {
		let currentVideo = video.current;
		if (currentVideo.paused) {
			currentVideo.play();
			//setplayPauseSection(false);
		} else {
			currentVideo.pause();
			// setPlay_pause_icon("play");
			// setplayPauseSection(true);
		}
		
	};
	useEffect(() => {
		//setplayPauseSection(false);
		if(!isInViewport) {
			video.current.pause();
			//document.querySelectorAll('video').forEach(vid => vid.pause());
		}
		else{
			
			setTimeout(() => {
				//document.querySelectorAll('video').forEach(vid => { if(vid.play()){ vid.pause();}});
				video.current.play();
				//setPlay_pause_icon("pause");
			}, 1000);
			// console.log("loadNewVidsAt ",loadNewVidsAt);
			// console.log("video current id ",Number(video.current.id), "index ",index);
			if (loadNewVidsAt === Number(video.current.id)) {
				setloadNewVidsAt((prev) => prev + 10);
				getVideos();
			}
		}	
		//setunmute(muted);	
		video.current.onended = function () {
			console.log("Ended");
			if(isInViewport){
				isPassed.current = false;
				mouUpdate("stop",0);
				myplexAPI.updatePreviewMou(
					item._id,
					Math.round(video.current.duration),
					Math.floor(Date.now())
				);
				video.current.play();
			}
		}
		video.current.onplay = function () {
			console.log("play");
			if(isPassed.current){
				var mediaSessionToken = "";
				if(item.mediaSessionToken){
					mediaSessionToken = item.mediaSessionToken;
				}
				if(isInViewport){
					mouUpdate("resume",Math.round(video.current.currentTime));
					isPassed.current = false;
				}
			}
			
		}
		video.current.onpause = function () {
			console.log("paused");
			console.log(Math.round(video.current.currentTime),Math.round(video.current.duration))
			if(isInViewport){				
				
				isPassed.current = true;
				mouUpdate("Pause",Math.round(video.current.currentTime));
			}
		}
		return () => { 
			if(isInViewport){
				mouUpdate("Pause",Math.round(video.current.currentTime));
				myplexAPI.updatePreviewMou(
					item._id,
					Math.round(video.current.duration),
					Math.floor(Date.now())
				);
			}
		 }
	}, [isInViewport]);
	// useEffect(() => {
	// 	setunmute(muted);
	// },[muted]);
	const mouUpdate = (action,elapsedTime) => {
		var mediaSessionToken = "";
			if(item.mediaSessionToken){
				mediaSessionToken = item.mediaSessionToken;
			}
			if(isInViewport){
				myplexAPI
				.playerStatus(item._id, action, item.generalInfo.type, elapsedTime, mediaSessionToken)
				.then((response) => {
					// console.log(response);
					// console.log(action)
				});
			}
	}
	return (
		<div className="slider-children">
			<video
				//muted={!muted}
				muted
				playsInline
				className="video"
				ref={video}
				onClick={togglePlay}
				id={index}
				autoPlay={index === 0 ? true : false}
				webkit-playsinline="true"
				x-webkit-airplay="allow"
				preload="auto"
				poster={poster != '' ? poster : placeholder_horizontal}
				// controls
				disablePictureInPicture
				controlsList="nodownload"
				//loop
			>
				<source src={videoURL} type="video/mp4" />
			</video>
			<div className="muteshorts" onClick={toggleClass}>
				{!activemute ?
					<img src={mute} alt="mute" className="mute" /> :
					<img src={unmute} alt="unmute" className="unmute" />}
			</div>
			{/* {playPauseSection && 
				<div className="Play_pause" onClick={togglePlay}>
					{
						Play_pause_icon && Play_pause_icon === 'pause' ?
						<img src={pauseIcon} alt="pause" />
						:
						<img src={playIcon} alt="play" />
					}
				</div>
			} */}
			<div className="shortsBottomDiv">
				<div className="video-content">
					<p>@{author}</p>
					<p>
						<span dangerouslySetInnerHTML={{ __html: description }}></span>
					</p>
				</div>
				<Sharelike item={item} />
			</div>
		</div>
	);
};
export default VideoCard;