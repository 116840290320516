import secureImages from './secureImages';
//import playerBackgroundImg from "../assets/images/videos___shows_placeholder.png"

const ParseImgURL = (imgArr, profile, type) => {
  let imgURL =
    imgArr &&
    imgArr.find &&
    imgArr.find(i => i.profile === profile && i.type === type);
  return imgURL ? secureImages(imgURL.link) : "";
};
export default ParseImgURL;