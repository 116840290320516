import React, { Component } from "react";
//import OwlCarousel from "react-owl-carousel2";
// import "react-owl-carousel2/src/owl.carousel.css";
// import "react-owl-carousel2/src/owl.theme.default.css";
import Img from "../Uicomponents/Img";
// import { Link } from "react-router-dom";
import myplexAPI, { URL_CAROUSELCONTENT } from "../../api/myplexAPI";
// import Loader from "../../utils/loader";
import Loader from "../../../commonfiles/loader";

import indexedDB from "../../utils/indexedDB";
// import { slugify } from "../../utils/stringSlugify";
import { slugify } from "../../../commonfiles/stringSlugify";
import { Link } from "react-router-dom";
import { bannerPartnerLogos } from "../../utils/partnerLogos";
import { getURLName } from "../../../commonfiles/urlMenu";
import { withRouter } from "react-router";
import StarRatings from "react-star-ratings";
import banner_freetag from "../../assets/images/freetag_banner.png";
import sessionFun from "../../../commonfiles/SessionStorage";
import Slider from "react-slick";
import premium_badge_lock from "../../assets/images/premium_badge_lock.png";
import ParseImgURL from "../../utils/parseImgURL";
import { isFirefox } from "react-device-detect";

class Desktopbanner extends Component {
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      bannerimages: [],
      showLoader: true,
      pageName: "home",
    };
    this.list = "";
    this.auth = localStorage.getItem("Logged-in");
  }

  componentDidMount() {
    this.Loadbannerdata();
    const page_name = window.location.pathname.split("/")[1];
    if (page_name !== "") {
      this.setState({
        pageName: page_name,
      });
    }
    // this.firstVideo();
    
  }
  firstVideo = () => {
    // var video = document.querySelectorAll(".Vid0");
    var that = this;
    setTimeout(function () {
      document.querySelectorAll("video").forEach((video) => {
        if (isFirefox) {
          video.controls = false;
        }
      });
      document.querySelectorAll(".Vid0").forEach((video) => {
        // console.log(video);
        // video.onplay = () => {
        //   // if(isFirefox){
        //   //   video.controls = false ;

        //   // }

        // };
        video.onended = function () {
          if (that.slider) {
            // alert("hi");
            that.slider.slickPlay();
            video.src = null;
          }
        };
      });
    }, 3000);
  };
  componentDidUpdate(prevsprops) {
    // console.log(prevsprops);
    if (this.props.caroselinfo.name !== prevsprops.caroselinfo.name) {
      // console.log("hi");
      this.Loadbannerdata();
    }
  }
  Loadbannerdata() {
    const that = this;
    const url = URL_CAROUSELCONTENT(
      this.props.caroselinfo.name,
      1,
      this.props.caroselinfo.pageSize,
      this.props.caroselinfo.modified_on
    );

    indexedDB(url)
      .then(function (response) {
        if (response !== false) {
          that.setState({
            bannerimages: response,
            showLoader: false,
          });
        } else {
          that._fetchCarousels();
        }
      })
      .catch(function (err) {
        that._fetchCarousels();
      });
  }
  _fetchCarousels = () => {
    this.list = localStorage.getItem("selectedLanguageList");

    myplexAPI
      .getCarouselData(
        this.props.caroselinfo.name,
        1,
        this.props.caroselinfo.pageSize,
        this.props.caroselinfo.modified_on
      )
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          this.setState({
            bannerimages: response.data.results,
            showLoader: false,
          });

          myplexAPI.storeIndexedDb(
            response.request.responseURL,
            response.data.results
          );
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  textureDisplay = () => {};

  // bannerClick = (URL) => {
  //   if (this.auth) {
  //     this.props.history.push(URL);
  //   } else {
  //     // console.log(this);
  //     localStorage.setItem("redirectUrl", URL);

  //     this.props.history.push(URL);
  //   }
  // };
  bannerClick = (URL,i,id) => {
    // console.log(URL);
    if (!this.auth) {
      localStorage.setItem("redirectUrl", URL);
     
    }
    localStorage.setItem("source details", this.props.caroselinfo.title);
    localStorage.setItem("source", "carousel");
    sessionFun(
      this.props.carousel_count,
      i + 1,
      this.state.pageName,
      "banner",
      this.props.caroselinfo.title,
      id
    );
  };
  onVideoBannerClick = (URL, e,i,id) => {
    localStorage.setItem("source details", this.props.caroselinfo.title);
    localStorage.setItem("source", "carousel");
    sessionStorage.setItem("promobanner", true);
     sessionFun(
        this.props.carousel_count,
        i + 1,
        this.state.pageName,
        "banner",
        this.props.caroselinfo.title,
       id
      );


    if (e.target.currentTime) {
      sessionStorage.setItem("vCurTime", e.target.currentTime);
      sessionStorage.setItem("vDur", e.target.duration);
      sessionStorage.setItem(
        "vStatus",
        e.target.duration - e.target.currentTime === 0 ? "Ended" : "Skipped"
      );
    }
    this.props.history.push(URL);
  };
  // Firstvideo = (i) => {
  //   alert(i);
  //   console.log(this.slider);
  //   // if (this.slider && this.slider.props.children[0].props.children.type === "video") {
  //     console.log("hi");
  //     document.querySelectorAll(".Vid" + 0).forEach((vid) => {
  //        console.log(vid.id)
  //       vid.src = vid.getAttribute("data-src");
  //       if (this.slider) {
  //         this.slider.slickPause();
  //       }
  //       var that = this;

  //       setTimeout(function () {

  //         vid.play();

  //         vid.onplay = () => {
  //           vid.muted = true;

  //           const auth = window.auth;
  //         };

  //         vid.onended = function () {
  //           if (that.slider) {
  //             that.slider.slickPlay();
  //           }
  //           const timestamp = Math.floor(Date.now());
  //           const auth = window.auth;

  //           const completed = Math.round(
  //             (vid.currentTime / vid.duration) * 100
  //           );

  //           /**video stopped  event*/
  //           if (that.slider) {
  //             // console.log(Math.round(vid.duration));
  //             // if (that.slider.props.children[n].props) {
  //             //   // cleverTap.videoStoped(cleverTap_data);
  //             //   myplexAPI.updatePreviewMou(
  //             //     that.slider.props.children[n].props.cid,
  //             //     Math.round(vid.duration),
  //             //     timestamp
  //             //   );
  //             // }
  //             vid.src = null;
  //           }
  //         };
  //       }, 1000);
  //     });
  //   // }
  // };
  myevent = (c, n) => {
    // console.log(this.slider.props.children[n].props);
    if (this.slider.props.children[c].props.children[0].type === "video") {
      document.querySelectorAll(".Vid" + c).forEach((vid, i) => {
        var that = this;

        const timestamp = Math.floor(Date.now());

        vid.pause();

        /**player events */

        const auth = window.auth;
        // let cleverTap_data = that.cleverTapData(
        //   that.state.bannerimages[c],
        //   auth
        // );
        const completed = Math.round((vid.currentTime / vid.duration) * 100);

        // cleverTap_data["duration viewed"] = Math.round(vid.currentTime);
        // cleverTap_data["duration"] = Math.round(vid.duration);
        // cleverTap_data["% completed"] = Math.round(completed);

        /**video stopped  event*/
        // console.log('pause',cleverTap_data)

        // cleverTap.videoStoped(cleverTap_data);
        // console.log(that.slider.props.children[c].props.children[0].props.cid);
        if (i == 0) {
          if (!isNaN(vid.duration)) {
            myplexAPI.updatePreviewMou(
              that.slider.props.children[c].props.children[0].props.cid,
              Math.round(vid.duration),
              timestamp
            );
          }
          myplexAPI.playerStatus(
            that.slider.props.children[c].props.children[0].props.cid,
            "pause",
            this.state.bannerimages[c].generalInfo.type,
            Math.round(vid.currentTime)
          );
        }

        vid.src = null;
      });
    }
    if (this.slider.props.children[n].props.children[0].type === "video") {
      document.querySelectorAll(".Vid" + n).forEach((vid, i) => {
        vid.src = vid.getAttribute("data-src");
        if (this.slider) {
          this.slider.slickPause();
        }
        var that = this;

        setTimeout(function () {
          vid.play();

          vid.onplay = () => {
            vid.muted = true;
            // if(isFirefox){
            //   vid.controls = false ;

            // }
            // vid.controls = false;
            const auth = window.auth;

            // let cleverTap_data = that.cleverTapData(
            //   that.state.bannerimages[n],
            //   auth
            // );
            // cleverTap_data["duration"] = Math.round(vid.duration);

            /**video played  event*/
            // console.log('play',cleverTap_data)
            // cleverTap.videoPlayed(cleverTap_data);
            // console.log(cleverTap_data)
          };
          if (i === 0) {
            vid.onended = function () {
              if (that.slider) {
                // alert("hi");
                that.slider.slickPlay();
              }
              const timestamp = Math.floor(Date.now());
              const auth = window.auth;
              // let cleverTap_data = that.cleverTapData(
              //   that.state.bannerimages[n],
              //   auth
              // );
              const completed = Math.round(
                (vid.currentTime / vid.duration) * 100
              );

              // cleverTap_data["duration viewed"] = Math.round(vid.currentTime);
              // cleverTap_data["duration"] = Math.round(vid.duration);
              // cleverTap_data["% completed"] = Math.round(completed);

              /**video stopped  event*/
              if (that.slider) {
                // console.log(that.slider.props.children[n].props.cid);
                if (that.slider.props.children[n].props) {
                  // cleverTap.videoStoped(cleverTap_data);
                  myplexAPI.updatePreviewMou(
                    that.slider.props.children[n].props.children[0].props.cid,
                    Math.round(vid.duration),
                    timestamp
                  );
                }
                vid.src = null;
              }
            };
          }
        }, 1000);
      });
    }
  };
  bannerimages = () =>
    this.state.bannerimages.map((bannerimg, i) => {
      let logo = "";
      if (bannerimg.publishingHouse !== undefined) {
        const publishingHouseName =
          bannerimg.publishingHouse.publishingHouseName;
        let partnerLogo = bannerPartnerLogos(publishingHouseName);
        if (partnerLogo.link !== undefined) {
          logo = (
            <img
              src={partnerLogo.link}
              alt={partnerLogo.name}
              className="logo_patner"
            />
          );
        }
      }
      // sessionFun(
      //   this.props.carousel_count,
      //   i + 1,
      //   this.state.pageName,
      //   "banner",
      //   this.props.caroselinfo.title,
      //   bannerimg.generalInfo._id
      // );

      if (
        bannerimg.previews &&
        bannerimg.previews.values &&
        bannerimg.previews.values.length > 0
      ) {
        return (
          <div key={i} className="center_caousel_item">
            <video
              controls
              disablePictureInPicture
              controlsList="nodownload"
              playsInline
              data-id={bannerimg._id}
              id={"vbannerplayer" + i}
              src={bannerimg.previews.values[0].link}
              data-src={bannerimg.previews.values[0].link}
              cid={bannerimg.generalInfo._id}
              className={"Vid" + i}
              preload="none"
              // poster= {process.env.REACT_APP_THUMBOR_IMAGES+ParseImgURL(
              //   bannerimg.images.values,
              //   "hdpi",
              //   "coverposter"
              // )}
              poster= {ParseImgURL(
                  bannerimg.images.values,
                 "hdpi",
                 "coverposter"
                 )}
              autoPlay={i === 0 ? true : false}
              onClick={(e) =>
                this.onVideoBannerClick(
                  `/${getURLName(bannerimg, this.state.pageName)}/detail/${
                    bannerimg.generalInfo._id
                  }/${slugify(bannerimg.generalInfo.title)}`,
                  e,i,bannerimg.generalInfo._id
                )
              }
              key={i}
              webkit-playsinline="true"
              x-webkit-airplay="allow"
              muted
            ></video>
             <div className="banner_title_name">
              <p>{bannerimg.generalInfo.title}</p>
              
               {/* | <p>genre</p> */}
              </div>
            {bannerimg.generalInfo.accessLabel && sessionStorage.getItem("userPacks") != null && JSON.parse(sessionStorage.getItem("userPacks")).length <= 0 &&  (
              <div className="banner_premium_badge_lock">
                <img src={premium_badge_lock} alt="premium_badge_lock" />
              </div>
            )}
          </div>
        );
      } else {
        var redirecturl = `/${getURLName(
          bannerimg,
          this.state.pageName
        )}/detail/${bannerimg.generalInfo._id}/${slugify(
          bannerimg.generalInfo.title
        )}`;
        if (bannerimg.content.actionType === "Subscribe") {
          var redirecturl = `/subscribe/${bannerimg.content.actionURL}`;
        }
        if (
          bannerimg.content.actionType === "launchWebPage" ||
          bannerimg.content.actionType === "launchWebPageInChrome"
        ) {
          redirecturl = bannerimg.content.actionURL;
        }

        return (
          <div className="center_caousel_item" key={i}>
             {bannerimg.content.actionType === "launchWebPage" ||
          bannerimg.content.actionType === "launchWebPageInChrome" ? 
            <a href ={redirecturl}
              key={i}
             >
            
            <Img
              caroselimg={bannerimg}
              key={i}
              banner={true}
              layouttype={this.props.caroselinfo}
              caroselname={this.props.caroselinfo.name}
              observer={true}
            />
          </a> :
            <Link
              to={redirecturl}
              onClick={() =>
                this.bannerClick(
                  `/${getURLName(bannerimg, this.state.pageName)}/detail/${
                    bannerimg.generalInfo._id
                  }/${slugify(bannerimg.generalInfo.title)}`,i,bannerimg.generalInfo._id
                )
              }
              key={i}
            >
              {!bannerimg.generalInfo.isSellable &&
                bannerimg.generalInfo.contentRights &&
                bannerimg.generalInfo.contentRights[0] === "avod" && (
                  <div className="banner_freetag">
                    <img src={banner_freetag} alt="banner_freetag" />
                  </div>
                )}
              <Img
                caroselimg={bannerimg}
                key={i}
                banner={true}
                layouttype={this.props.caroselinfo}
                caroselname={this.props.caroselinfo.name}
                observer={true}
              />
              <div className="banner_title_name">
              <p > {bannerimg.generalInfo.title } </p> 
              {/* | <p> genre</p> */}
              </div>
            </Link> }
            {bannerimg.generalInfo.accessLabel && sessionStorage.getItem("userPacks") != null && JSON.parse(sessionStorage.getItem("userPacks")).length <= 0 && (
              <div className="banner_premium_badge_lock">
                <img src={premium_badge_lock} alt="premium_badge_lock" />
              </div>
            )}
            {this.props.texture !== null && this.props.texture.length > 0 && (
              <div className="banner_caption">
                {this.props.texture.find((obj) => obj.metadata === "title") !==
                  null &&
                this.props.texture.find((obj) => obj.metadata === "title") !==
                  undefined ? (
                  <h1>{bannerimg.generalInfo.title}</h1>
                ) : null}
                {this.props.texture.find((obj) => obj.metadata === "rating") !==
                  null &&
                this.props.texture.find((obj) => obj.metadata === "rating") !==
                  undefined ? (
                  <div className="payment_amount">
                    <div className="app_rating">
                      {parseInt(bannerimg.content.contentRating) > 0 ? (
                        <StarRatings
                          rating={parseInt(bannerimg.content.contentRating)}
                          starRatedColor="#ffcc33"
                          numberOfStars={5}
                          name="star-container"
                          className="rated"
                        />
                      ) : (
                        <ul id="star-rating">
                          <li className="rated"></li>
                          <li className="rated"></li>
                          <li className="rated"></li>
                          <li className="rated"></li>
                          <li></li>
                        </ul>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="payment_amount"></div>
                )}
                <div className="breadcrumb_section">
                  <ul>
                    {this.props.texture.find(
                      (obj) => obj.metadata === "language"
                    ) !== null &&
                    this.props.texture.find(
                      (obj) => obj.metadata === "language"
                    ) !== undefined &&
                    bannerimg.content.language.length > 0 ? (
                      <li>{bannerimg.content.language[0]}</li>
                    ) : (
                      ""
                    )}
                    {this.props.texture.find(
                      (obj) => obj.metadata === "genre"
                    ) !== null &&
                    this.props.texture.find(
                      (obj) => obj.metadata === "genre"
                    ) !== undefined &&
                    bannerimg.content.genre.length > 0 ? (
                      <li>{bannerimg.content.genre[0].name}</li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>

                {bannerimg.generalInfo.isSellable &&
                  this.props.texture.find((obj) => obj.metadata === "price") !==
                    null &&
                  this.props.texture.find((obj) => obj.metadata === "price") !==
                    undefined && (
                    <h1 className="payment_amount rupee">
                      {bannerimg.packages.length > 0 &&
                      bannerimg.packages[0].priceDetails.length > 0 ? (
                        <span className="rupee_symbol">
                          ₹ {bannerimg.packages[0].priceDetails[0].price}
                        </span>
                      ) : (
                        ""
                      )}
                    </h1>
                  )}
                {!bannerimg.generalInfo.isSellable &&
                  bannerimg.generalInfo.contentRights &&
                  bannerimg.generalInfo.contentRights[0] === "avod" &&
                  this.props.texture.find((obj) => obj.metadata === "price") !==
                    null &&
                  this.props.texture.find((obj) => obj.metadata === "price") !==
                    undefined && (
                    <h1 className="payment_amount rupee">
                      <span className="rupee_symbol">Free</span>
                    </h1>
                  )}
              </div>
            )}
            {logo}
          </div>
        );
      }
    });
    getBannerAutoPlaySpeed = () => {
      var frequency = sessionStorage.getItem("properties") ? (JSON.parse(sessionStorage.getItem("properties")).pwabannerScrollFrequency ? (JSON.parse(sessionStorage.getItem("properties"))).pwabannerScrollFrequency : 3000) : 3000;
      return this.state.bannerimages[0] &&
      this.state.bannerimages[0].previews &&
      this.state.bannerimages[0].previews.values &&
      this.state.bannerimages[0].previews.values.length > 0
        ? 0
        : parseInt(frequency)
    }
  render() {
    const settings = {
      //  items: 5,
      // className: "slider variable-width",
      //  adaptiveHeight: true,
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "270px",
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      dots: true,
      autoplay:
        this.state.bannerimages[0] &&
        this.state.bannerimages[0].previews &&
        this.state.bannerimages[0].previews.values &&
        this.state.bannerimages[0].previews.values.length > 0
          ? false
          : true,
      //   variableWidth: true
      autoplaySpeed : this.getBannerAutoPlaySpeed(),
      onInit: () => {
        this.firstVideo();
      },
      beforeChange: (current, next) => {
        //   console.log( this.slider.props.children[0].type );
        // console.log( this.slider.props.children[0].type );
        this.myevent(current, next);
      },
    };

    return this.state.bannerimages.length ? (
      <div className="layout_center_banner mm_banner video_center_banner">
        <div className="carousel_body">
          {this.state.bannerimages.length > 0 && (
            <Slider {...settings} ref={(c) => (this.slider = c)}>
              {this.bannerimages()}
            </Slider>
          )}
          {this.state.showLoader && <Loader />}
        </div>
      </div>
    ) : null;
  }
}
export default withRouter(Desktopbanner);
