import React from "react";
// import Header from "./components/Header/Header";
import MmtvRoutes from "./manorama/Routes/manoramaroutes"
import { BrowserRouter } from "react-router-dom";
import Notification from "./firebase/notification"
// import "./utils/config";

import "./App.css";
// import "./manorama/general/Inside/Viewall.css"

import withClearCache from "./ClearCache";

const ClearCacheComponent = withClearCache(MainApp);


function App() {
  return <ClearCacheComponent />;
}
function MainApp() {
  return (
    <BrowserRouter>
      <div className="wrapper">

        <MmtvRoutes />
        <Notification/>
      </div>
    </BrowserRouter>
  );
}

// class App extends Component {

//   render() {

//     return (
//       <BrowserRouter>
//         <div className="wrapper">
//           <MmtvRoutes />

//         </div>
//       </BrowserRouter>
//     );
//   }
// }
export default App;

