import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Img from "../Uicomponents/Img";
import { Link } from "react-router-dom";
import myplexAPI, { URL_CAROUSELCONTENT } from "../../api/myplexAPI";
//import Loader from "../../utils/loader";
import Loader from "../../../commonfiles/loader";

//import mobile_bannerimage from "../../assets/images/mobile_placeholder.jpg";
import indexedDB from "../../utils/indexedDB";
import { slugify } from "../../utils/stringSlugify";
//import { slugify } from "../../../commonfiles/stringSlugify";

import { bannerPartnerLogos } from "../../utils/partnerLogos";
//import { getURLName } from "../../../commonfiles/urlMenu";
import { getURLName } from "../../utils/urlMenu";

//import { isMobile  } from "react-device-detect";
import { withRouter } from "react-router";
import StarRatings from 'react-star-ratings';
import banner_freetag from "../../assets/images/freetag_banner.png";
import sessionFun from "../../../commonfiles/SessionStorage"
import premium_badge_lock from "../../assets/images/premium_badge_lock.png";

//import StarsRating from 'stars-rating'


 class MmBanner extends Component {
  constructor(props){
    super(props);
    // console.log(props)
    this.state = {
      bannerimages: [],
      showLoader: true,
      pageName: "home"
    };
      this.list ='';
    this.auth = localStorage.getItem("Logged-in");

  }

  
  
  componentDidMount() {
   
    this.Loadbannerdata();
    const page_name = window.location.pathname.split("/")[1];
    if (page_name !== "") {
      this.setState({
        pageName: page_name
      });
    }
  }
  componentDidUpdate(prevsprops) {
    // console.log(prevsprops);
    if (this.props.caroselinfo.name !== prevsprops.caroselinfo.name) {
      this.Loadbannerdata();
    }
  }
  Loadbannerdata() {
    const that = this;
    const url = URL_CAROUSELCONTENT(this.props.caroselinfo.name, 1,this.props.caroselinfo.pageSize,this.props.caroselinfo.modified_on);

    indexedDB(url)
      .then(function(response) {
        if (response !== false) {
          that.setState({
            bannerimages: response,
            showLoader: false
          });
          // setTimeout(function() {
          //   that.setState({ showLoader: false });
          // }, 400);
        } else {
          that._fetchCarousels();
        }
      })
      .catch(function(err) {
        that._fetchCarousels();
      });
  }
  _fetchCarousels = () => {
    //const glbl_state = this;
    this.list = localStorage.getItem('selectedLanguageList');

    myplexAPI
      .getCarouselData(this.props.caroselinfo.name, 1, this.props.caroselinfo.pageSize, this.props.caroselinfo.modified_on)
      .then(response => {
        // console.log(this.props.caroselinfo.name,response)
        if(response.data.status === 'SUCCESS') {
          this.setState({
            bannerimages: response.data.results,
            showLoader: false
          });
          // setTimeout(function() {
          //   glbl_state.setState({ showLoader: false });
          // }, 400);
          // glbl_state.setState({ showLoader: false });
          myplexAPI.storeIndexedDb(
              response.request.responseURL,
              response.data.results
          );
        }else{
          this.setState({
            showLoader: false
          })
        }
      });
  };
  // test  = (e) => {

  //   var owl = window.$('.owl-carousel');
   
  //   // owl.owlCarousel();
  //   // owl.owlCarousel();
  //   // // Go to the next item
  //   // $('.customNextBtn').click(function() {

  //   console.log(e.item);
  //   var current = (e.item.index + 1) - e.relatedTarget._clones.length / 2;
  //     	var allItems = e.item.count;
  //     	if (current > allItems || current <= 0) {
  //         owl.trigger('next.owl.carousel');
  //     	}
  //   //  if(e.item.index < 8){
  //   //   owl.trigger('next.owl.carousel');
  //   //  }
    
      
       
  //   // })
  //   // this.refs.car.next()
  // }

  textureDisplay =() =>{
    
  }

  // bannerClick = (URL) =>{
  //   // console.log(URL)


  //   if(this.auth){
  //     this.props.history.push(URL);
  //   }else{
  //     // console.log(this);
  //   localStorage.setItem('redirectUrl',URL);

  //     this.props.history.push(URL);
  //   //  this.props.history.push("/login");

  //   }

  // }
  bannerClick=(URL,i,id)=>{
    if(!this.auth)
    {localStorage.setItem('redirectUrl',URL);}
    localStorage.setItem("source details", this.props.caroselinfo.title);
    localStorage.setItem("source", "carousel");
    sessionFun(this.props.carousel_count,i+1,this.state.pageName,'banner',this.props.caroselinfo.title,id);
  }
  bannerimages = () =>
    this.state.bannerimages.map((bannerimg, i) => {
      let logo = "";
      if (bannerimg.publishingHouse !== undefined) {
        const publishingHouseName =
          bannerimg.publishingHouse.publishingHouseName;
        let partnerLogo = bannerPartnerLogos(publishingHouseName);
        if (partnerLogo.link !== undefined) {
          logo = (
            <img
              src={partnerLogo.link}
              alt={partnerLogo.name}
              className="logo_patner"
            />
          );
        }
      }
      // sessionFun(this.props.carousel_count,i+1,this.state.pageName,'banner',this.props.caroselinfo.title,bannerimg.generalInfo._id);
    //   var firebaseValue =
    //   this.props.carousel_count +
    //   "-" +
    //   (i + 1) +
    //   "-" +
    //   this.state.pageName+"-"+this.props.caroselinfo.title;
    // sessionStorage.setItem(bannerimg.generalInfo._id, firebaseValue);
      // console.log(bannerimg)
      // console.log(this.props.texture.find(obj => obj.metadata === 'titl'))
      // this.props.texture.map((item,i)=>{
      //   console.log(item.metadata)
      // })
      // console.log(getURLName(bannerimg, this.state.pageName));
      return (
        // <Link
        //   to={{
        //     pathname: `/${getURLName(bannerimg, this.state.pageName)}/detail/${
        //       bannerimg.generalInfo._id
        //     }/${slugify(bannerimg.generalInfo.title)}`
        //     // search: `?videotype=${bannerimg.generalInfo.type}`
            
        //   }}
        //   key={i}
        // >
        <div 
        // onClick = {() => this.bannerClick(`/${getURLName(bannerimg, this.state.pageName)}/detail/${
        //   bannerimg.generalInfo._id
        // }/${slugify(bannerimg.generalInfo.title)}`)} key={i} 
        className='mm_banner_item'>

           <Link to={`/${getURLName(bannerimg, this.state.pageName)}/detail/${
          bannerimg.generalInfo._id
        }/${slugify(bannerimg.generalInfo._id)}`} onClick = {() => this.bannerClick(`/${getURLName(bannerimg, this.state.pageName)}/detail/${
          bannerimg.generalInfo._id
        }/${slugify(bannerimg.generalInfo.title)}`,i,bannerimg.generalInfo._id)} key={i} >
        {!bannerimg.generalInfo.isSellable && bannerimg.generalInfo.contentRights &&
        bannerimg.generalInfo.contentRights[0] === 'avod' && <div className="banner_freetag"> 
          <img src={banner_freetag} alt="banner_freetag" />
        </div>}
          <Img
            caroselimg={bannerimg}
            key={i}
            banner={true}
            layouttype={this.props.caroselinfo}
            caroselname={this.props.caroselinfo.name}
            observer={true}
            
          />
          </Link>
           {bannerimg.generalInfo.accessLabel && sessionStorage.getItem("userPacks") != null && JSON.parse(sessionStorage.getItem("userPacks")).length <= 0 &&
           <div className="premium_badge_lock">
                <img src={premium_badge_lock} alt="premium_badge_lock" />
              </div>}
        {this.props.texture !== null && this.props.texture.length > 0 &&
        <div className="banner_caption">
      {this.props.texture.find(obj => obj.metadata === 'title') !== null && this.props.texture.find(obj => obj.metadata === 'title') !== undefined ?<h1>{bannerimg.generalInfo.title}</h1> : null}
      {this.props.texture.find(obj => obj.metadata === "rating") !== null && this.props.texture.find(obj => obj.metadata === "rating") !== undefined ? 
      // <div className="payment_amount">
      //     <div className="app_rating">
     
      //  </div>
      //  </div>
        <div className="payment_amount">
        <div className="app_rating">
      {  parseInt(bannerimg.content.contentRating) > 0 ?
        <StarRatings
          rating={parseInt(bannerimg.content.contentRating)}
          starRatedColor="#ffcc33"
          numberOfStars={5}
          name='star-container'
          className = "rated"
          /> :
               <ul id="star-rating">
           <li className="rated"></li>
           <li className="rated"></li>
           <li className="rated"></li>
           <li className="rated"></li>
           <li></li>
          </ul>}
     </div>
     </div>
      // <div className="payment_amount">
      // <StarRatings
      //     rating={2}
      //     starRatedColor="#f7b15b"
      //     // changeRating={this.changeRating}
      //     numberOfStars={5}
      //     name='star-container'
      //     className = "rated"
      //     />
      //     </div>
       :       <div className="payment_amount">
</div>      }
          <div className="breadcrumb_section">
            <ul>
            {this.props.texture.find(obj => obj.metadata === "language") !== null && this.props.texture.find(obj => obj.metadata === "language") !== undefined && bannerimg.content.language.length > 0 ? <li>{bannerimg.content.language[0]}</li>:''}
            {this.props.texture.find(obj => obj.metadata === "genre") !== null && this.props.texture.find(obj => obj.metadata === "genre") !== undefined && bannerimg.content.genre.length > 0 ?<li>{bannerimg.content.genre[0].name}</li> : ''}
            </ul>
          </div>
  
          {/* { bannerimg.generalInfo.isSellable && this.props.texture.find(obj => obj.metadata === "price") !== null && this.props.texture.find(obj => obj.metadata === "price") !== undefined ? <h1 className="payment_amount rupee">{bannerimg.packages.length > 0 && bannerimg.packages[0].priceDetails.length > 0  ? <span className="rupee_symbol">₹ {bannerimg.packages[0].priceDetails[0].price}</span> : ''}</h1>:<h1 className="payment_amount rupee"><span className="rupee_symbol">Free</span></h1>} */}
          { bannerimg.generalInfo.isSellable && this.props.texture.find(obj => obj.metadata === "price") !== null && this.props.texture.find(obj => obj.metadata === "price") !== undefined &&  <h1 className="payment_amount rupee">{bannerimg.packages.length > 0 && bannerimg.packages[0].priceDetails.length > 0 ?<span className="rupee_symbol">₹ {bannerimg.packages[0].priceDetails[0].price}</span> : ''}</h1>}
      { !bannerimg.generalInfo.isSellable && bannerimg.generalInfo.contentRights &&
        bannerimg.generalInfo.contentRights[0] === 'avod' && this.props.texture.find(obj => obj.metadata === "price") !== null && this.props.texture.find(obj => obj.metadata === "price") !== undefined &&  <h1 className="payment_amount rupee"><span className="rupee_symbol">Free</span></h1>}
       
        </div>}
          {logo}
          </div>
        // </Link>
      );
    });
    getBannerAutoPlaySpeed = () => {
      var frequency = sessionStorage.getItem("properties") ? (JSON.parse(sessionStorage.getItem("properties")).pwabannerScrollFrequency ? (JSON.parse(sessionStorage.getItem("properties"))).pwabannerScrollFrequency : 3000) : 3000;
      return this.state.bannerimages[0] &&
      this.state.bannerimages[0].previews &&
      this.state.bannerimages[0].previews.values &&
      this.state.bannerimages[0].previews.values.length > 0
        ? 0
        : parseInt(frequency)
    } 
 
  render() {   
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 1,
      speed: 500,
      arrows: false,
      autoplay: true,
      autoplaySpeed : this.getBannerAutoPlaySpeed(),
      dots: true,
    };

    return (
     this.state.bannerimages.length ?
      <div className="layout_center_banner mm_banner">
        {this.state.bannerimages.length>0? 
      <Slider {...settings}>
          {this.bannerimages() }
        {/* <div className="mm_banner_item">
          
            <img src="https://static.vodafoneplay.in/412903/192x256_7b38efa3-4532-4c94-91f2-bebdde98109e.jpg" className="mm_banner_img"/>
        </div>
        <div className="mm_banner_item">
            <img src="https://img2.vodafoneplay.in/379300/192x256_128f8dbd-5e45-4c04-bead-d76cdc4d2991.jpg" className="mm_banner_img"/>
        </div>
        <div className="mm_banner_item">
            <img src="https://img2.vodafoneplay.in/383601/192x256_6521b24d-0385-420e-8273-422a0d1c48dc.jpg" className="mm_banner_img"/>
        </div>
        <div className="mm_banner_item">
            <img src="https://static.vodafoneplay.in/412903/192x256_7b38efa3-4532-4c94-91f2-bebdde98109e.jpg" className="mm_banner_img"/>
        </div>
        <div className="mm_banner_item">
            <img src="https://img2.vodafoneplay.in/379300/192x256_128f8dbd-5e45-4c04-bead-d76cdc4d2991.jpg" className="mm_banner_img"/>
        </div>
        <div className="mm_banner_item">
            <img src="https://img2.vodafoneplay.in/383601/192x256_6521b24d-0385-420e-8273-422a0d1c48dc.jpg" className="mm_banner_img"/>
        </div> */}
        {/* <div>
          <h3>2</h3>
        </div>
        <div>
          <h3>3</h3>
        </div>
        <div>
          <h3>4</h3>
        </div>
        <div>
          <h3>5</h3>
        </div>
        <div>
          <h3>6</h3>
        </div> */}
      </Slider>:<Loader />}
    </div>:null
    
    );
  }
}
export default withRouter(MmBanner) ;
