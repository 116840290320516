import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import Img from "../Uicomponents/Img";
import { Link } from "react-router-dom";
import myplexAPI, { URL_CAROUSELCONTENT } from "../../api/myplexAPI";
// import Loader from "../../utils/loader";
import Loader from "../../../commonfiles/loader";

// import mobile_bannerimage from "../../assets/images/mobile_placeholder.jpg";
import indexedDB from "../../utils/indexedDB";
// import { slugify } from "../../utils/stringSlugify";
import { slugify } from "../../../commonfiles/stringSlugify";

import { bannerPartnerLogos } from "../../utils/partnerLogos";
import { getURLName } from "../../../commonfiles/urlMenu";
// import { getURLName } from "../../utils/urlMenu";

import { isMobile } from "react-device-detect";
import { withRouter } from "react-router";
import StarRatings from "react-star-ratings";
import banner_freetag from "../../assets/images/freetag_banner.png";
import play_icon_detail from "../../assets/images/play_icon_detail.png";
import sessionFun from "../../../commonfiles/SessionStorage";
// import Ad from 'react-google-publisher-tag';
import premium_badge_lock from "../../assets/images/premium_badge_lock.png";
// import StarsRating from 'stars-rating'

class Banner extends Component {
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      bannerimages: [],
      showLoader: true,
      pageName: "home",
    };
    this.list = "";
    this.auth = localStorage.getItem("Logged-in");
  }

  componentDidMount() {
    // window.COMSCORE.beacon(
    //   {
    //   "test":true
    //     }
    // );

    this.Loadbannerdata();
    const page_name = window.location.pathname.split("/")[1];
    if (page_name !== "") {
      this.setState({
        pageName: page_name,
      });
    }
  }
  componentDidUpdate(prevsprops) {
    // console.log(prevsprops);
    if (this.props.caroselinfo.name !== prevsprops.caroselinfo.name) {
      this.Loadbannerdata();
    }
  }
  Loadbannerdata() {
    const that = this;
    const url = URL_CAROUSELCONTENT(
      this.props.caroselinfo.name,
      1,
      this.props.caroselinfo.pageSize,
      this.props.caroselinfo.modified_on
    );

    indexedDB(url)
      .then(function (response) {
        if (response !== false) {
          that.setState({
            bannerimages: response,
            showLoader: false,
          });
          // setTimeout(function() {
          //   that.setState({ showLoader: false });
          // }, 400);
        } else {
          that._fetchCarousels();
        }
      })
      .catch(function (err) {
        that._fetchCarousels();
      });
  }
  _fetchCarousels = () => {
    //const glbl_state = this;
    this.list = localStorage.getItem("selectedLanguageList");

    myplexAPI
      .getCarouselData(
        this.props.caroselinfo.name,
        1,
        this.props.caroselinfo.pageSize,
        this.props.caroselinfo.modified_on
      )
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          this.setState({
            bannerimages: response.data.results,
            showLoader: false,
          });
          // setTimeout(function() {
          //   glbl_state.setState({ showLoader: false });
          // }, 400);
          // glbl_state.setState({ showLoader: false });
          myplexAPI.storeIndexedDb(
            response.request.responseURL,
            response.data.results
          );
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };
  // test  = (e) => {

  //   var owl = window.$('.owl-carousel');

  //   // owl.owlCarousel();
  //   // owl.owlCarousel();
  //   // // Go to the next item
  //   // $('.customNextBtn').click(function() {

  //   console.log(e.item);
  //   var current = (e.item.index + 1) - e.relatedTarget._clones.length / 2;
  //     	var allItems = e.item.count;
  //     	if (current > allItems || current <= 0) {
  //         owl.trigger('next.owl.carousel');
  //     	}
  //   //  if(e.item.index < 8){
  //   //   owl.trigger('next.owl.carousel');
  //   //  }

  //   // })
  //   // this.refs.car.next()
  // }

  textureDisplay = () => {};

  // bannerClick = (URL) =>{
  //   //  console.log(URL)
  //   if(this.auth){
  //     // this.props.history.push(URL);
  //   }else{
  //     // console.log(this);
  //   localStorage.setItem('redirectUrl',URL);

  //     // this.props.history.push(URL);
  //   //  this.props.history.push("/login");
  //   }

  // }

  bannerClick = (URL,i,id) => {
    localStorage.setItem("source details", this.props.caroselinfo.title);
      localStorage.setItem("source", "carousel");
      sessionFun(
        this.props.carousel_count,
        i + 1,
        this.state.pageName,
        "banner",
        this.props.caroselinfo.title,
        id
      );
    if (!this.auth) {
      localStorage.setItem("redirectUrl", URL);
    }
  };
  bannerimages = () =>
    this.state.bannerimages.map((bannerimg, i) => {
      let logo = "";
      if (bannerimg.publishingHouse !== undefined) {
        const publishingHouseName =
          bannerimg.publishingHouse.publishingHouseName;
        let partnerLogo = bannerPartnerLogos(publishingHouseName);
        if (partnerLogo.link !== undefined) {
          logo = (
            <img
              src={partnerLogo.link}
              alt={partnerLogo.name}
              className="logo_patner"
            />
          );
        }
      }
      // sessionFun(
      //   this.props.carousel_count,
      //   i + 1,
      //   this.state.pageName,
      //   "banner",
      //   this.props.caroselinfo.title,
      //   bannerimg.generalInfo._id
      // );
      //   var firebaseValue =
      //   this.props.carousel_count +
      //   "-" +
      //   (i + 1) +
      //   "-" +
      //   this.state.pageName+"-"+this.props.caroselinfo.title;
      // sessionStorage.setItem(bannerimg.generalInfo._id, firebaseValue);
      // console.log(bannerimg)
      // console.log(this.props.texture.find(obj => obj.metadata === 'titl'))
      // this.props.texture.map((item,i)=>{
      //   console.log(item.metadata)
      // })
      var redirecturl = `/${getURLName(
        bannerimg,
        this.state.pageName
      )}/detail/${bannerimg.generalInfo._id}/${slugify(
        bannerimg.generalInfo.title
      )}`;
      if (bannerimg.content.actionType === "Subscribe") {
        redirecturl = `/subscribe/${bannerimg.content.actionURL}`;
      }

      if (
        bannerimg.content.actionType === "launchWebPage" ||
        bannerimg.content.actionType === "launchWebPageInChrome"
      ) {
        redirecturl = bannerimg.content.actionURL;
      }
      return (
        // <Link
        //   to={{
        //     pathname: `/${getURLName(bannerimg, this.state.pageName)}/detail/${
        //       bannerimg.generalInfo._id
        //     }/${slugify(bannerimg.generalInfo.title)}`
        //     // search: `?videotype=${bannerimg.generalInfo.type}`

        //   }}
        //   key={i}
        // >
        <div
        // onClick = {() => this.bannerClick(`/${getURLName(bannerimg, this.state.pageName)}/detail/${
        //   bannerimg.generalInfo._id
        // }/${slugify(bannerimg.generalInfo.title)}`)} key={i}
        >
          {bannerimg.content.actionType === "launchWebPage" ||
          bannerimg.content.actionType === "launchWebPageInChrome" ? (
            <a href ={redirecturl}
              key={i}
            >
              {!bannerimg.generalInfo.isSellable &&
                bannerimg.generalInfo.contentRights &&
                bannerimg.generalInfo.contentRights[0] === "avod" && (
                  <div className="banner_freetag">
                    <img src={banner_freetag} alt="banner_freetag" />
                  </div>
                )}
              <Img
                caroselimg={bannerimg}
                key={i}
                banner={true}
                layouttype={this.props.caroselinfo}
                caroselname={this.props.caroselinfo.name}
                observer={true}
              />
            </a>
          ) : (
            <Link
              to={redirecturl}
              onClick={() => {
                this.bannerClick(
                  `/${getURLName(bannerimg, this.state.pageName)}/detail/${
                    bannerimg.generalInfo._id
                  }/${slugify(bannerimg.generalInfo.title)}`,i,bannerimg.generalInfo._id
                );
              }}
              key={i}
            >
              {!bannerimg.generalInfo.isSellable &&
                bannerimg.generalInfo.contentRights &&
                bannerimg.generalInfo.contentRights[0] === "avod" && (
                  <div className="banner_freetag">
                    <img src={banner_freetag} alt="banner_freetag" />
                  </div>
                )}
              <Img
                caroselimg={bannerimg}
                key={i}
                banner={true}
                layouttype={this.props.caroselinfo}
                caroselname={this.props.caroselinfo.name}
                observer={true}
              />
            </Link>
          )}

          {bannerimg.generalInfo.accessLabel && sessionStorage.getItem("userPacks") != null && JSON.parse(sessionStorage.getItem("userPacks")).length <= 0 && (
            <div className="premium_badge_lock">
              <img src={premium_badge_lock} alt="premium_badge_lock" />
            </div>
          )}
          <div className="banner_play_icon">
            <img src={play_icon_detail} className="" alt="banner_play_icon" />
          </div>
          {this.props.texture !== null && this.props.texture.length > 0 && (
            <div className="banner_caption">
              {this.props.texture.find((obj) => obj.metadata === "title") !==
                null &&
              this.props.texture.find((obj) => obj.metadata === "title") !==
                undefined ? (
                <h1>{bannerimg.generalInfo.title}</h1>
              ) : null}
              {this.props.texture.find((obj) => obj.metadata === "rating") !==
                null &&
              this.props.texture.find((obj) => obj.metadata === "rating") !==
                undefined ? (
                // <div className="payment_amount">
                //     <div className="app_rating">

                //  </div>
                //  </div>
                <div className="payment_amount">
                  <div className="app_rating">
                    {parseInt(bannerimg.content.contentRating) > 0 ? (
                      <StarRatings
                        rating={parseInt(bannerimg.content.contentRating)}
                        starRatedColor="#ffcc33"
                        numberOfStars={5}
                        name="star-container"
                        className="rated"
                      />
                    ) : (
                      <ul id="star-rating">
                        <li className="rated"></li>
                        <li className="rated"></li>
                        <li className="rated"></li>
                        <li className="rated"></li>
                        <li></li>
                      </ul>
                    )}
                  </div>
                </div>
              ) : (
                // <div className="payment_amount">
                // <StarRatings
                //     rating={2}
                //     starRatedColor="#f7b15b"
                //     // changeRating={this.changeRating}
                //     numberOfStars={5}
                //     name='star-container'
                //     className = "rated"
                //     />
                //     </div>
                <div className="payment_amount"></div>
              )}
              <div className="breadcrumb_section">
                <ul>
                  {this.props.texture.find(
                    (obj) => obj.metadata === "language"
                  ) !== null &&
                  this.props.texture.find(
                    (obj) => obj.metadata === "language"
                  ) !== undefined &&
                  bannerimg.content.language.length > 0 ? (
                    <li>{bannerimg.content.language[0]}</li>
                  ) : (
                    ""
                  )}
                  {this.props.texture.find(
                    (obj) => obj.metadata === "genre"
                  ) !== null &&
                  this.props.texture.find((obj) => obj.metadata === "genre") !==
                    undefined &&
                  bannerimg.content.genre.length > 0 ? (
                    <li>{bannerimg.content.genre[0].name}</li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>

              {/* { bannerimg.generalInfo.isSellable && this.props.texture.find(obj => obj.metadata === "price") !== null && this.props.texture.find(obj => obj.metadata === "price") !== undefined ? <h1 className="payment_amount rupee">{bannerimg.packages.length > 0 && bannerimg.packages[0].priceDetails.length > 0  ? <span className="rupee_symbol">₹ {bannerimg.packages[0].priceDetails[0].price}</span> : ''}</h1>:<h1 className="payment_amount rupee"><span className="rupee_symbol">Free</span></h1>} */}
              {bannerimg.generalInfo.isSellable &&
                this.props.texture.find((obj) => obj.metadata === "price") !==
                  null &&
                this.props.texture.find((obj) => obj.metadata === "price") !==
                  undefined && (
                  <h1 className="payment_amount rupee">
                    {bannerimg.packages.length > 0 &&
                    bannerimg.packages[0].priceDetails.length > 0 ? (
                      <span className="rupee_symbol">
                        ₹ {bannerimg.packages[0].priceDetails[0].price}
                      </span>
                    ) : (
                      ""
                    )}
                  </h1>
                )}
              {!bannerimg.generalInfo.isSellable &&
                bannerimg.generalInfo.contentRights &&
                bannerimg.generalInfo.contentRights[0] === "avod" &&
                this.props.texture.find((obj) => obj.metadata === "price") !==
                  null &&
                this.props.texture.find((obj) => obj.metadata === "price") !==
                  undefined && (
                  <h1 className="payment_amount rupee">
                    <span className="rupee_symbol">Free</span>
                  </h1>
                )}
            </div>
          )}
          {logo}
        </div>
        // </Link>
      );
    });
    getBannerAutoPlaySpeed = () => {
      var frequency = sessionStorage.getItem("properties") ? (JSON.parse(sessionStorage.getItem("properties")).pwabannerScrollFrequency ? (JSON.parse(sessionStorage.getItem("properties"))).pwabannerScrollFrequency : 3000) : 3000;
      return this.state.bannerimages[0] &&
      this.state.bannerimages[0].previews &&
      this.state.bannerimages[0].previews.values &&
      this.state.bannerimages[0].previews.values.length > 0
        ? 0
        : parseInt(frequency)
    }
  render() {
    var items, margin;
    if (isMobile) {
      items = 1;
      // center=false;
      margin = 0;
    } else {
      items = 2;
      // center = true;
      margin = 20;
    }
    const options = {
      items: items,
      center: true,
      margin: margin,
      dots: true,
      nav: true,
      navText: [
        "<div class='nav-btn prev-slide'></div>",
        "<div class='nav-btn next-slide'></div>",
      ],
      autoplay: true,
      loop: true,
      autoplayTimeout: this.getBannerAutoPlaySpeed(),
      rewind: false,
    };
    const events = {
      onDragged: function (event) {},
      onChanged: function (event) {},
      // onChanged:  (event) => { this.test(event) }
    };
    //var that = this;

    return this.state.bannerimages.length ? (
      <section className="banner_slider">
        {/* {this.state.showLoader && <Loader />} */}
        {this.state.bannerimages.length > 0 && (
          <OwlCarousel ref="car" options={options} events={events}>
            {this.bannerimages()}
          </OwlCarousel>
        )}
        {this.state.showLoader && <Loader />}
      </section>
    ) : null;
  }
}
export default withRouter(Banner);
