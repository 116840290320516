import React, { Component } from "react";
import {FormControl } from "react-bootstrap";
import myplexAPI from "../../api/myplexAPI";
import { withRouter } from "react-router";
import {queryStringParse} from "../../utils/portalhelper";
import back_arrow from "../../assets/images/back_arrow.png";




class Device extends Component {
	goBack = () => {
		this.props.history.goBack();
	  };

	constructor(props){
		super(props);

		this.state = {

			error_msg:'',
			device_api_msg:''

		};

		this.authcodevalue = React.createRef();


	}

	componentDidMount(){

		let params = queryStringParse(this.props.location.search);

		if(localStorage.getItem("Logged-in")){

			// console.log('in device page')
			if(params && params.auth_code){

				var code = params.auth_code ;
				this.authcodevalue.value = code;
				this.authCodeFn();				
			}
		}else{
			localStorage.setItem('redirectUrl','/device'+this.props.location.search)
			this.props.history.push('/Login')
		}
	}

	verifyAuthCodeValue = () =>{
		if(this.authcodevalue.value){

			// console.log('auth code ----->', this.authcodevalue.value)
			this.setState({
				error_msg:''
			})
			this.authCodeFn();
		}else{
			this.setState({
				error_msg:'Please enter device code'
			})
		}
	}

	authCodeFn = () =>{
		
		let params = queryStringParse(this.props.location.search);

		myplexAPI.authCodeSubmit(this.authcodevalue.value).then(res =>{
			// console.log(res.data.code)
			if(params && params.auth_code){
				if(res && res.data && res.data.code == 200 && res.data.status=="SUCCESS"){ 
					document.getElementsByClassName("device_activebtn")[0].remove();

				}
			}
			if(res && res.data && res.data.code !== null ) 


			this.setState({
				device_api_msg: res.data.message
			})
		}).catch(error => {
			console.error('error', error);
			this.setState({
				device_api_msg: 'Please try after some time !'
			})
		  });
	}

  render() {

    return (
      <section className="device_section">
		 <div className="inner_header">
        <img src={back_arrow} alt='' className="back_arrow" onClick={this.goBack} />
      </div>

    <div className="col-md-6 col-lg-offset-3">
        <h1 className="text-center">Connect a device</h1>
        <p className="text-center">Enter the code displayed on your device:</p>
        <div className="form-group">

		<FormControl
                type="text"
                // value=""
                placeholder="Enter code"
                inputRef={ref => {
                  this.authcodevalue = ref;
                }} className="form-control" />
		  {/* <input type="text" tabindex="-1" id="deviceCode" name="auth_code" autocomplete="off" placeholder="Enter code" className="form-control"
		  inputRef={ref => {
			this.authcodevalue = ref;
		  }} /> */}
          <p className="error_msg">{this.state.error_msg}</p>
          {/* <p className="text-center msg_success">Connected Succesfully!<br /> You are now connected Apex ott application</p> */}
		<p className="text-center msg_success">{this.state.device_api_msg}</p>

        </div>
       
        <div className="text-center">	
		  <button value="" className="btn device_activebtn" onClick ={() => this.verifyAuthCodeValue()}>Activate</button>
		</div>
    </div>


    {/* <div className="col-md-6 col-lg-offset-3">
	<h1 className="text-center text-uppercase">Registered Devices</h1>
	<table border="1" className="table">
		<tbody>
			<tr>
				<th>OS</th>
				<th>Model</th>
				<th></th>
			</tr>
			<tr>
				<td>iOS</td>
				<td>iPhone</td>
				<td>
					<button value="" className="btn pull-right">Deactivate</button>
				</td>
			</tr>
			<tr>
				<td>Android</td>
				<td>vivo 1819</td>
				<td>
					<button value="" className="btn pull-right">Deactivate</button>
				</td>
			</tr>
			<tr>
				<td>android</td>
				<td>samsung-A307FN</td>
				<td>
					<button value="" className="btn pull-right">Deactivate</button>
				</td>
			</tr>
			<tr>
				<td>windows nt 10.0;</td>
				<td>Browser</td>
				<td>
					<button value="" className="btn pull-right">Deactivate</button>
				</td>
			</tr>
			<tr>
				<td>win64; x64</td>
				<td>Browser</td>
				<td>
					<button value="" className="btn pull-right">Deactivate</button>
				</td>
			</tr>
			<tr>
				<td>windows nt 10.0</td>
				<td>Browser</td>
				<td>
					<button value="" className="btn pull-right">Deactivate</button>
				</td>
			</tr>
			<tr>
				<td>win64; x64</td>
				<td>Browser</td>
				<td>
					<button value="" className="btn pull-right disabled">Deactivate</button>
				</td>
			</tr>
		</tbody>
	</table>
</div> */}
    
      </section>
    );
  }
}

export default withRouter(Device);
