import React, {Component} from 'react';
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import "../Slider/Carousel.css";
import Img from "../Uicomponents/Img";
import myplexAPI from "../../api/myplexAPI";
import {queryStringParse} from "../../utils/portalhelper";
import {Link} from "react-router-dom";
// import {getURLName} from "../../utils/urlMenu";
// import {slugify} from "../../utils/stringSlugify";
import {getURLName} from "../../../commonfiles/urlMenu";
import {slugify} from "../../../commonfiles/stringSlugify";
import {carouselPatnerLogos} from "../../utils/partnerLogos";

class FilterData extends Component{
    constructor( props ){
        super(props);
        this.state = {
            carouselData: [],
            startIndex: 1,
            type: null,
            language: null,
            genres: '',
            pageName: "home",
            url: null
        };

    }
    componentDidMount() {
        const _self = this;
        const params = this._getParams();
        const fliterData =  myplexAPI
            .getFilterhResults(
                this.state.startIndex,
                this.props.type,
                params.language,
                params.genres,
                10
            );
        // const fliterData = axios.get('http://preprodapi.vodafoneplay.in/content/v5/contentList?type=movie&fields=generalInfoCimagesCrelatedCastCpublishingHouseCcontentsCrelatedMediaCglobalServiceIdCpreviews&startIndex=1&count=10&language=Bengali&orderBy=releaseDate&publishingHouseId=55C59C69C71C82C83C100')
        fliterData.then(function (response) {
            // console.log(response);
            _self.setState({
                carouselData: response.data.results
            })
        });
    }
    _getParams = () => {
        let params = queryStringParse(this.props.search);
        let type = "movie";
        // eslint-disable-next-line
        {
            params.type === "live" ? (type = "Live TV") : params.type === "tvSeries"
                ? (type = "Tv Shows") : (type = "movie");
        }
        let language = params.filtervalue;
        let genres = params.genres;
        let search_value;
        if(language === undefined){
            language = '';
        }else {
            search_value = `&filtervalue=${language}`
        }
        if(genres === undefined){
            genres = '';
        }else {
            search_value = `&genres=${genres}`
        }
        const url = `type=${this.props.type}${search_value}`;
        // console.log(url)
        this.setState({
            type: type,
            language:  language,
            genres: genres,
            url: url
        });
        return {
            type: type,
            language: language,
            genres: genres
        };
        // console.log(this.state)
    };
    _filterCarousels = () => {
        return this.state.carouselData.map((content, i) => {
            let logo = null;
            // console.log(content)
            if (content.publishingHouse !== undefined) {
                let partnerLogos = carouselPatnerLogos(
                    content.publishingHouse.publishingHouseName
                );
                // console.log(partnerLogos)
                if (
                    partnerLogos.link !== undefined &&
                    this.props.layoutType !== "horizontalListSmallItem" &&
                    this.props.layoutType !== "horizontalListMediumItem"
                ) {
                    logo = (
                            <img
                                src={partnerLogos.link}
                                alt={partnerLogos.name}
                                className="carousel_logo_patner"
                            />
                    );
                }
            // console.log(logo)
            }
            // console.log(content.generalInfo.title)
            return (
                <Link
                    to={{pathname: `/${getURLName(content, this.state.pageName)}/detail/${content.generalInfo._id}/${slugify(content.generalInfo.title)}`
                    }}
                    key={i}
                >
                    <div className="carousel_img">
                        <Img
                            caroselimg={content}
                            key={i}
                            banner={false}
                            layouttype="horizontalListSmallItem"
                            caroselname={content.generalInfo.title}
                            observer={false}
                        />
                    </div>
                    {logo}
                </Link>
            );
        });
    };
    render() {
        let perpage = 0;

        if (this.props.layoutType === "horizontalListBigItem") {
            perpage = 10;
        } else if (this.props.layoutType === "nestedCarousel") {
            perpage = 8;
        } else {
            perpage = 7;
        }
        // console.log(this.state)
        const options = {
            items: perpage,

            responsive: {
                320: {
                    items: perpage === 10 ? 3 : perpage === 8 ? 4 : 2,
                    nav: true
                },
                481: {
                    items: perpage === 10 ? 4 : perpage === 8 ? 4 : 3,
                    nav: false
                },
                768: {
                    items: perpage === 10 ? 5 : perpage === 8 ? 5 : 4,
                    nav: false
                },
                1200: {
                    items: perpage === 10 ? 6 : perpage === 8 ? 5 : 5,
                    nav: true
                },
                1300: {
                    items: perpage === 10 ? 7 : perpage === 8 ? 6 : 6,
                    nav: true
                },
                1400: {
                    items: perpage === 10 ? 8 : perpage === 8 ? 6 : 6,
                    nav: true
                },
                1500: {
                    items: perpage === 10 ? 8 : perpage === 8 ? 6 : 6,
                    nav: true
                },
                1600: {
                    items: perpage === 10 ? 9 : perpage === 8 ? 7 : 7,
                    nav: true
                },
                1700: {
                    items: perpage === 10 ? 10 : perpage === 8 ? 7 : 8,
                    nav: true
                }
            },
            nav: true,
            navText: [
                "<i class='carousel_leftarrow'/>",
                "<i class='carousel_rightarrow'/>"
            ],
            rewind: false,
            margin: 10,
            dots: false,
            autoplay: false,
            loop: false
        };
        const events = {

        };
        return(
            <div style={{ marginTop: "121px"}}>
            {this.state.carouselData.length > 0 &&
                <div className="carousel_body">
                    <div className="carosel_slider">
                        <div className="home_carosel_title">
                            <Link to={{
                                pathname: `/${this.state.pageName}/filter-view-all`,
                                search: this.state.url
                            }}>
                            <h1>{this.props.type}</h1></Link>
                            <span className="home_viewall">
                                <Link to={{
                                pathname: `/${this.state.pageName}/filter-view-all`,
                                search: this.state.url
                            }}>
                         <span className="home_viewall">More</span></Link></span>
                        </div>
                            <OwlCarousel ref="car" options={options} events={events}>
                                {this._filterCarousels()}
                            </OwlCarousel>
                    </div>
                </div>}
            </div>
        )
    }
}

export default FilterData;