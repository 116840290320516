import { useRef, useState, useEffect } from "react";

import useIsInViewport from "../useIsInViewport";

const Videodata = ({
  index,
  author,
  videoURL,
  authorLink,
  lastVideoIndex,
  getVideos,
}) => {
  const video = useRef();
  const isInViewport = useIsInViewport(video);
  const [loadNewVidsAt, setloadNewVidsAt] = useState(lastVideoIndex);
  if (isInViewport) {
    setTimeout(() => {
      video.current.play();
    }, 1000);

	if (loadNewVidsAt === Number(video.current.id)) {
        setloadNewVidsAt((prev) => prev + 10);
        getVideos();
    }
  }

  const togglePlay = () => {
    let currentVideo = video.current;
    if (currentVideo.paused) {
      currentVideo.play();
    } else {
      currentVideo.pause();
    }
  };

  useEffect(() => {
    if (!isInViewport) {
      video.current.pause();
    }
  }, [isInViewport]);

  return (
    <div className="slider-children_reels">
      <video
        muted
        className="video_reels"
        ref={video}
        onClick={togglePlay}
        id={index}
        autoPlay={index === 1}
        playsInline
      >
        <source src={videoURL} type="video/mp4" />
      </video>
      
    </div>
  );
};

export default Videodata;