import React from "react";
import { slugify } from "../../../commonfiles/stringSlugify";
import { NavLink } from "react-router-dom";

// const activeLinkFun=(e)=>{
//     var elements = e.currentTarget.parentElement.parentElement.children;
//     console.log(elements[1].children[0],'eeee')
// 	if(elements){
// 		for (var i = 0; i < elements.length; i++) {
//             elements[i].children[0].classList.remove('active');

//         }
//         e.currentTarget.classList.add('active');
// 	}

// }

const setPage = (tab) => {
  //var page = props.match.params.Page;
  var page = tab;
  // eslint-disable-next-line
  if (page === "tv") {
    page = "TV";
  }
  // {
  page && (page = page.replace("-", " "));
  //}
  return ucFirst(page);
};
const ucFirst = (string) => {
  if (string !== undefined) {
    return string
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  }
};

const desktopTopMenuData = (data) => {
  var segmentArray = document.location.pathname.split("/");
  var tab;
  if (segmentArray.length > 0) {
    tab = segmentArray[1];
  } else {
    tab = "home";
  }
  var filterData = data.filter((value, i) => {
    return value.title === setPage(tab);
  });
  var titleEXist = false;
  if (filterData.length > 0) {
    titleEXist = true;
  }
  return data.map((m, index) => {
    let title = m.title;
    if (m.altTitle) {
      title = m.altTitle;
    }
    // console.log(title,m)
    const nav_link = slugify(m.title);
    if (m.layoutType === "menu") {
      // let linkActive='';
      if (index === 0) {
        //linkActive='active'
      } else {
        // linkActive=''
      }
      return (
        <React.Fragment key={index}>
          {m.appAction === "launchWebPage" ||  m.appAction === "launchWebPageInChrome" ? (
            <NavLink to={{ pathname: m.actionUrl }} target={"_blank"}>
              <li
                className={
                  titleEXist
                    ? setPage(tab) === m.title
                      ? "menu_list_item active"
                      : `menu_list_item`
                    : index === 0
                    ? "menu_list_item active"
                    : `menu_list_item`
                }
              >
                {title}
              </li>
            </NavLink>
          ) : (
            <NavLink to={`/${nav_link}`}>
              <li
                className={
                  titleEXist
                    ? setPage(tab) === m.title
                      ? "menu_list_item active"
                      : `menu_list_item`
                    : index === 0
                    ? "menu_list_item active"
                    : `menu_list_item`
                }
              >
                {title}
              </li>
            </NavLink>
          )}
        </React.Fragment>
      );
    } else {
      return null;
    }
  });
};

const desktopTopMenu = React.memo((props) => {
  return (
    <div className="header-menu">
      <ul>{desktopTopMenuData(props.menuData)}</ul>
    </div>
  );
});
export default desktopTopMenu;
